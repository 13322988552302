import React from "react"
import {
  Container,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Input,
} from "reactstrap"

import { useNavigate } from "react-router-dom"

import classnames from "classnames"

import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import { CKEditor } from "@ckeditor/ckeditor5-react"
import { Editor } from "ckeditor5-custom-build/build/ckeditor"

import axios from "lib/axios"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// Import Components

const MainPage = () => {
  const Navigate = useNavigate()
  // all variables
  const [file, setFile] = React.useState()
  const [active, setActive] = React.useState(true)

  const [form, setForm] = React.useState()

  const [content, setContent] = React.useState()
  const [startingDate, setStartingDate] = React.useState(new Date())
  const [endingDate, setEndingDate] = React.useState(new Date())

  //meta title
  document.title = "Safkar Panel - Popup Ekle"

  const [customActiveTab, setcustomActiveTab] = React.useState("0")

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  const [lang, setLang] = React.useState()

  React.useEffect(() => {
    axios
      .get("/language")
      .then(responseLang => {
        if (responseLang.data.languages) {
          setLang(responseLang.data.languages)

          const newForm = responseLang.data.languages.map(language => {
            return {
              ["title_" + language.code]: "",
              ["content_" + language.code]: "",
              ["link_" + language.code]: "",
              active: "1",
            }
          })

          setForm(newForm)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const isCategoryForm = obj => {
    // Gerekli özelliklerin varlığını kontrol ederek
    // obj'nin CategoryForm tipinde olduğunu doğrulayabilirsiniz
    // Örnek olarak: return 'property1' in obj && 'property2' in obj;
    return true
  }

  const setFormValueByIndex = (index, key, value) => {
    const newForm = [...form]
    if (isCategoryForm(newForm[index])) {
      newForm[index][key] = value.toString()
    }
    setForm(newForm)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Popup" breadcrumbItem="Popup Ekle" />

          {/* Render Form */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form
                    onSubmit={e => {
                      e.preventDefault()
                      var arr = {}
                      form.forEach(item => {
                        Object.keys(item).forEach(key => {
                          arr[key] = item[key]
                        })
                      })
                      arr["active"] = active

                      if (startingDate) arr["startingDate"] = startingDate
                      if (endingDate) arr["endingDate"] = endingDate

                      if (file) {
                        if (
                          file.type != "image/png" &&
                          file.type != "image/jpeg" &&
                          file.type != "image/jpg"
                        ) {
                          toast.error("Resim PNG, JPEG veya JPG olmalidir")
                          return
                        }
                      }

                      axios
                        .post("/popup", arr)
                        .then(response => {
                          toast.success("Popup Eklendi")
                          if (file) {
                            var formData = new FormData()
                            formData.append("images", file)
                            formData.append("popupId", response.data.id)
                            axios
                              .post("/popup/image", formData)
                              .then(response => {})
                          }
                          Navigate("/popups")
                        })
                        .catch(error => {
                          console.log(error)
                          toast("Popup Eklenemedi")
                        })
                    }}
                  >
                    <h4 className="card-title">popup Ekle</h4>
                    <p className="card-title-desc">popup Ekle</p>
                    {/* back button */}
                    <button
                      type="button"
                      className="btn btn-danger w-md mx-3 mb-2"
                      style={{
                        position: "absolute",
                        right: "0",
                        top: "20px",
                      }}
                      onClick={() => {
                        //rout Edit category
                        Navigate("/popups")
                      }}
                    >
                      Geri
                    </button>
                    <div className="row">
                      <div className="col-lg-12">
                        {/* two colum*/}
                        <div className="row">
                          <div
                            className="col-lg-12"
                            style={{ display: "flex" }}
                          >
                            {/*category name*/}
                            <div
                              className="mb-3"
                              style={{ flex: 1, marginRight: "10px" }}
                            >
                              <label
                                htmlFor="categoryname"
                                className="form-label"
                              >
                                Popup Resmi
                              </label>
                              <input
                                id="categoryname"
                                name="categoryname"
                                type="file"
                                accept=".png, .jpeg, .jpg"
                                className="form-control"
                                onChange={e => {
                                  setFile(e.target.files[0])
                                }}
                              />
                            </div>
                            {active != null && (
                              <div>
                                {/*category name*/}
                                <div className="mb-3">
                                  <label
                                    htmlFor="categoryname"
                                    className="form-label"
                                  >
                                    Aktiflik
                                  </label>
                                  <div className="form-check form-switch form-switch-lg mb-3">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="customSwitchsizelg"
                                      defaultChecked={active}
                                      onChange={e => {
                                        setActive(e.target.checked)
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div
                            className="row mb-2"
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div className="col-lg-6">
                              <label
                                htmlFor="example-date-input"
                                className="col-md-2 col-form-label"
                              >
                                Başlangıç Tarihi
                              </label>
                              <div className="col-md-10">
                                <input
                                  className="form-control"
                                  type="date"
                                  defaultValue={startingDate.toString()}
                                  id="example-date-input"
                                  onChange={e => {
                                    setStartingDate(e.target.value)
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <label
                                htmlFor="example-date-input"
                                className="col-md-2 col-form-label"
                              >
                                Bitiş Tarihi
                              </label>
                              <div className="col-md-10">
                                <input
                                  className="form-control"
                                  type="date"
                                  defaultValue={endingDate.toString()}
                                  id="example-date-input"
                                  onChange={e => {
                                    setEndingDate(e.target.value)
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <Nav tabs className="nav-tabs-custom nav-justified">
                            {lang &&
                              lang.map((item, index) => (
                                <NavItem key={index}>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      active:
                                        customActiveTab === index.toString(),
                                    })}
                                    onClick={() => {
                                      toggleCustom(index.toString())
                                    }}
                                  >
                                    <span className="d-block d-sm-none">
                                      {item.displayname}
                                    </span>
                                    <span className="d-none d-sm-block">
                                      {item.displayname}
                                    </span>
                                  </NavLink>
                                </NavItem>
                              ))}
                          </Nav>

                          <TabContent
                            activeTab={customActiveTab}
                            className="p-3 text-muted"
                          >
                            {form &&
                              lang &&
                              lang.map((item, index) => (
                                <TabPane tabId={index.toString()} key={index}>
                                  <Row>
                                    <Col sm="12">
                                      <div className="mt-4">
                                        <label
                                          htmlFor="categoryname"
                                          className="form-label"
                                        >
                                          Popup Başlığı
                                        </label>

                                        <input
                                          id="categoryname"
                                          name="categoryname"
                                          type="text"
                                          className="form-control"
                                          placeholder="Popup Başlığı"
                                          onChange={e =>
                                            setFormValueByIndex(
                                              index,
                                              "title_" + item.code,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                    </Col>
                                    <Col sm="12">
                                      <div className="mt-4">
                                        <label
                                          htmlFor="categoryname"
                                          className="form-label"
                                        >
                                          Popup Icerik
                                        </label>
                                        <CKEditor
                                          editor={Editor}
                                          data={content}
                                          onChange={(e, editor) => {
                                            const data = editor.getData()
                                            setFormValueByIndex(
                                              index,
                                              "content_" + item.code,
                                              data
                                            )
                                          }}
                                        />
                                      </div>
                                    </Col>
                                    <Col sm="12">
                                      <div className="mt-4">
                                        <label
                                          htmlFor="categoryname"
                                          className="form-label"
                                        >
                                          Popup Linki
                                        </label>

                                        <input
                                          id="categoryname"
                                          name="categoryname"
                                          type="text"
                                          className="form-control"
                                          placeholder="Popup Linki"
                                          onChange={e =>
                                            setFormValueByIndex(
                                              index,
                                              "link_" + item.code,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </TabPane>
                              ))}
                          </TabContent>
                        </div>
                        {/*submit button right*/}
                        <div className="col-lg-12">
                          <div className="mt-4 d-flex justify-content-end">
                            <button
                              type="submit"
                              className="btn btn-primary w-md  mb-2"
                            >
                              Ekle
                            </button>
                            <button
                              type="button"
                              className="btn btn-danger w-md mx-3 mb-2"
                              onClick={() => {
                                //rout Edit category
                                Navigate("/popups")
                              }}
                            >
                              Geri
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MainPage
