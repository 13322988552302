import React from "react"
import {
  Container,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import { useParams, useNavigate } from "react-router-dom"

import classnames from "classnames"

import axios, { API_URL } from "lib/axios"

import { useForm } from "@mantine/form"
import {
  TextInput,
  Switch,
  Group,
  ActionIcon,
  Box,
  Text,
  Button,
  Code,
  Center,
  Flex,
  FileInput,
} from "@mantine/core"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { randomId } from "@mantine/hooks"
import { IconTrash, IconGripVertical } from "@tabler/icons-react"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// Import Components

import { toast } from "react-toastify"

const Footers = () => {
  const Navigate = useNavigate()
  const [form, setForm] = React.useState()

  //meta title
  document.title = "Safkar Panel - Footer Düzenle"

  const [customActiveTab, setCustomActiveTab] = React.useState("0")

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab)
    }
  }

  const [lang, setLang] = React.useState()

  const forms = useForm({
    initialValues: {
      info: [],
    },
  })

  const fields = forms.values.info.map((_, index) => (
    <Draggable key={index} index={index} draggableId={index.toString()}>
      {provided => (
        <Flex
          direction={{ base: "column", sm: "row" }}
          gap={{ base: "sm", sm: "lg" }}
          justify={{ sm: "center" }}
          ref={provided.innerRef}
          mt="lg"
          {...provided.draggableProps}
        >
          <Center {...provided.dragHandleProps}>
            <IconGripVertical size="1.2rem" />
          </Center>

          <TextInput
            placeholder="Icon"
            label="Icon"
            sx={{ flex: 2 }}
            {...forms.getInputProps(`info.${index}.icon`)}
          />
          <TextInput
            placeholder="Bilgi"
            label="Bilgi"
            sx={{ flex: 3 }}
            {...forms.getInputProps(`info.${index}.info`)}
          />
          <div className="d-flex">
            {/* <TextInput
              placeholder="Tarih"
              label="Tarih"
              sx={{ flex: 1 }}
              {...forms.getInputProps(`info.${index}.date`)}
            /> */}
            <ActionIcon
              color="red"
              variant="light"
              sx={{ marginTop: "2rem", marginLeft: "1rem" }}
              onClick={() => forms.removeListItem("info", index)}
            >
              <IconTrash size="1.25rem" />
            </ActionIcon>
          </div>
        </Flex>
      )}
    </Draggable>
  ))

  React.useEffect(() => {
    forms.setValues({
      info: [{ icon: "", info: "", key: randomId() }],
    })

    axios
      .get("/language")
      .then(responseLang => {
        if (responseLang.data.languages) {
          setLang(responseLang.data.languages)

          axios
            .get(`/footer`)
            .then(responseKVKK => {
              forms.setValues({
                info: [],
              })

              if (responseKVKK.data) {
                const newForm = responseLang.data.languages.map(language => {
                  var kvkkLangRow = responseKVKK.data.footerlang.find(
                    item => item.languageId == language.id
                  )

                  if (kvkkLangRow != undefined) {
                    return {
                      ["title_" + language.code]: kvkkLangRow.title,
                      ["content_" + language.code]: kvkkLangRow.content,
                      ["button_" + language.code]: kvkkLangRow.button,
                    }
                  } else {
                    return {
                      ["title_" + language.code]: "",
                      ["content_" + language.code]: "",
                      ["button_" + language.code]: "",
                    }
                  }
                })
                setForm(newForm)

                responseKVKK.data.footer.info.forEach(item => {
                  forms.insertListItem("info", {
                    icon: item.icon,
                    info: item.info,
                    key: item.key,
                  })
                })
              }
            })
            .catch(error => {
              console.log(error)
            })
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const isKVKKForm = obj => {
    return true
  }

  const setFormValueByIndex = (index, key, value) => {
    const newForm = [...form]
    if (isKVKKForm(newForm[index])) {
      newForm[index][key] = value.toString()
    }
    setForm(newForm)
  }

  const getFormValueByIndex = (index, key) => {
    const newForm = [...form]
    if (isKVKKForm(newForm[index])) {
      if (newForm[index]) {
        return newForm[index][key]
      } else {
        return ""
      }
    }
    return ""
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Footer" breadcrumbItem="Footer Düzenle" />

          {/* Render Form */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form
                    onSubmit={e => {
                      e.preventDefault()
                      var arr = {}
                      form.forEach(item => {
                        Object.keys(item).forEach(key => {
                          arr[key] = item[key]
                        })
                      })
                      arr["info"] = forms.values["info"]
                      axios
                        .put("/footer", arr)
                        .then(response => {
                          if (response.data.success) {
                            toast.success("Güncelleme Başarılı")
                          }
                        })
                        .catch(error => {
                          console.log(error)
                        })
                    }}
                  >
                    <h4 className="card-title">Footer Düzenle</h4>
                    <p className="card-title-desc">Footer Düzenle</p>
                    {/* back button */}
                    <button
                      type="button"
                      className="btn btn-danger w-md mx-3 mb-2"
                      style={{
                        position: "absolute",
                        right: "0",
                        top: "20px",
                      }}
                      onClick={() => {
                        Navigate("/main-page")
                      }}
                    >
                      Geri
                    </button>
                    <div className="col-lg-12">
                      <div className="mt-2 mb-4">
                        <Box mx="auto">
                          <div className="mb-4">
                            <Group position="left" mt="md">
                              <Button
                                onClick={() =>
                                  forms.insertListItem("info", {
                                    icon: "",
                                    info: "",
                                    key: randomId(),
                                  })
                                }
                                className="btn btn-primary w-md  mb-2"
                              >
                                Bilgi Ekle
                              </Button>
                            </Group>
                          </div>
                          {fields.length > 0 ? null : (
                            <Text color="dimmed" align="center">
                              Bilgi yok
                            </Text>
                          )}

                          <DragDropContext
                            onDragEnd={({ destination, source }) =>
                              forms.reorderListItem("info", {
                                from: source.index,
                                to: destination.index,
                              })
                            }
                          >
                            <Droppable
                              droppableId="dnd-list"
                              direction="vertical"
                            >
                              {provided => (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {fields}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </Box>
                      </div>
                      {/* <pre>
                        <Code>{JSON.stringify(forms.values, null, 2)}</Code>
                        </pre> */}
                    </div>
                    <div className="col-lg-12">
                      <Nav tabs className="nav-tabs-custom nav-justified">
                        {lang &&
                          lang.map((item, index) => (
                            <NavItem key={index}>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: customActiveTab === index.toString(),
                                })}
                                onClick={() => {
                                  toggleCustom(index.toString())
                                }}
                              >
                                <span className="d-block d-sm-none">
                                  {item.displayname}
                                </span>
                                <span className="d-none d-sm-block">
                                  {item.displayname}
                                </span>
                              </NavLink>
                            </NavItem>
                          ))}
                      </Nav>

                      <TabContent
                        activeTab={customActiveTab}
                        className="p-3 text-muted"
                      >
                        {form &&
                          lang &&
                          lang.map((item, index) => (
                            <TabPane tabId={index.toString()} key={index}>
                              <Row>
                                <Col sm="6">
                                  <div className="mt-4">
                                    <label
                                      htmlFor="kvkkTitle"
                                      className="form-label"
                                    >
                                      Footer Başlık
                                    </label>

                                    <input
                                      id="kvkkTitle"
                                      name="kvkkTitle"
                                      type="text"
                                      className="form-control"
                                      placeholder="Haberler Başlık"
                                      defaultValue={getFormValueByIndex(
                                        index,
                                        "title_" + item.code
                                      )}
                                      onChange={e =>
                                        setFormValueByIndex(
                                          index,
                                          "title_" + item.code,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col sm="6">
                                  <div className="mt-4">
                                    <label
                                      htmlFor="kvkkSubtitle"
                                      className="form-label"
                                    >
                                      Button yazi
                                    </label>

                                    <input
                                      id="kvkkSubtitle"
                                      name="kvkkSubtitle"
                                      type="text"
                                      className="form-control"
                                      placeholder="Haberler Alt Başlık"
                                      defaultValue={getFormValueByIndex(
                                        index,
                                        "button_" + item.code
                                      )}
                                      onChange={e =>
                                        setFormValueByIndex(
                                          index,
                                          "button_" + item.code,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col sm="12">
                                  <div className="mt-4">
                                    <label
                                      htmlFor="kvkkSubtitle"
                                      className="form-label"
                                    >
                                      Icerik
                                    </label>

                                    <input
                                      id="kvkkSubtitle"
                                      name="kvkkSubtitle"
                                      type="text"
                                      className="form-control"
                                      placeholder="Haberler Alt Başlık"
                                      defaultValue={getFormValueByIndex(
                                        index,
                                        "content_" + item.code
                                      )}
                                      onChange={e =>
                                        setFormValueByIndex(
                                          index,
                                          "content_" + item.code,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </Col>
                                {/*
                                <Col sm="12">
                                  <div className="mt-4">
                                    <label
                                      htmlFor="kvkkContent"
                                      className="form-label"
                                    >
                                      Footers Aktiflik
                                    </label>
                                      
                                    <input
                                    type="checkbox"
                                    className="form-check-input"
                                    defaultChecked={getFormValueByIndex(
                                      index,
                                      "active_" + item.code
                                      )}
                                    onChange={(e) => {
                                      setFormValueByIndex(
                                        index,
                                        "active_" + item.code,
                                        Boolean(e.target.checked)
                                      );
                                    }}
                                  />
                                  </div>
                                </Col>
                                                                                                      */}
                              </Row>
                            </TabPane>
                          ))}
                      </TabContent>
                    </div>
                    {/*submit button right*/}
                    <div className="col-lg-12">
                      <div className="mt-4 d-flex justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-primary w-md  mb-2"
                        >
                          Güncelle
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger w-md mx-3 mb-2"
                          onClick={() => {
                            Navigate("/main-page")
                          }}
                        >
                          Geri
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Footers
