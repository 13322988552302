import React from "react"
import { Container } from "reactstrap"

import { useParams, useNavigate } from "react-router-dom"

import axios from "lib/axios"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import { toast } from "react-toastify"
import { useState } from "react"
import { Select } from "@mantine/core"

const Index = () => {
  document.title = "Safkar Panel - SMTP Ayarları Düzenle"
  const navigate = useNavigate()
  const params = useParams()

  const { id } = params

  const [userFirstname, setUserFirstname] = useState("")
  const [userLastname, setUserLastname] = useState("")
  const [userMail, setUserMail] = useState("")
  const [username, setUsername] = useState("")
  const [userPhone, setUserPhone] = useState("")
  const [userStatus, setUserStatus] = useState(null)
  const [userRole, setUserRole] = useState("")
  const [roles, setRoles] = useState([])

  React.useEffect(() => {
    axios.get("/users/" + id).then(res => {
      const { data } = res
      setUserFirstname(data.userFirstname)
      setUserLastname(data.userLastname)
      setUserMail(data.userMail)
      setUsername(data.username)
      setUserPhone(data.userPhone)
      setUserStatus(data.userStatus)
      setUserRole(data.roleId)
    })

    axios.get("/getRoles").then(response => {
      setRoles(response.data)
    })
  }, [])

  document.title = "Safkar Panel - Kullanıcı Düzenle"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Kullanıcı" breadcrumbItem="Kullanıcı Düzenle" />

          {/* Render Form */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form
                    onSubmit={e => {
                      e.preventDefault()
                      axios
                        .put("/users/" + id, {
                          userFirstname,
                          userLastname,
                          userMail,
                          username,
                          userPhone,
                          userStatus,
                          userRole,
                        })
                        .then(response => {
                          if (response.status == 200) {
                            toast.success("Güncelleme Başarılı")
                          }
                        })
                        .catch(error => {
                          console.log(error)
                        })
                    }}
                  >
                    <h4 className="card-title">Kullanıcı Düzenle</h4>
                    <p className="card-title-desc">Kullanıcı Düzenle</p>
                    {/* back button */}
                    <button
                      type="button"
                      className="btn btn-danger w-md mx-3 mb-2"
                      style={{
                        position: "absolute",
                        right: "0",
                        top: "20px",
                      }}
                      onClick={() => {
                        navigate("/users")
                      }}
                    >
                      Geri
                    </button>
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label htmlFor="simpleinput">Kişi Adı</label>
                        <input
                          type="text"
                          id="simpleinput"
                          className="form-control"
                          value={userFirstname}
                          onChange={e => {
                            setUserFirstname(e.target.value)
                          }}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label htmlFor="simpleinput">Kişi Soyadı</label>
                        <input
                          type="text"
                          id="simpleinput"
                          className="form-control"
                          value={userLastname}
                          onChange={e => {
                            setUserLastname(e.target.value)
                          }}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label htmlFor="simpleinput">Kişi Maili</label>
                        <input
                          type="text"
                          id="simpleinput"
                          className="form-control"
                          value={userMail}
                          onChange={e => {
                            setUserMail(e.target.value)
                          }}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label htmlFor="simpleinput">
                          Kişi Telefon Numarası
                        </label>
                        <input
                          type="text"
                          id="simpleinput"
                          className="form-control"
                          value={userPhone}
                          onChange={e => {
                            setUserPhone(e.target.value)
                          }}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label htmlFor="simpleinput">Kullanıcı Adı</label>
                        <input
                          type="text"
                          id="simpleinput"
                          className="form-control"
                          value={username}
                          onChange={e => {
                            setUsername(e.target.value)
                          }}
                          required
                        />
                      </div>
                    </div>
                    {userRole && (
                      <div className="col-lg-12">
                        <div className="form-group mb-3">
                          <Select
                            defaultValue={userRole}
                            label="Kullanıcı Rolü"
                            data={roles.map(role => {
                              return {
                                value: role.id,
                                label: role.name,
                              }
                            })}
                            onChange={e => {
                              setUserRole(e)
                            }}
                          />
                        </div>
                      </div>
                    )}
                    {userStatus != null && (
                      <div>
                        {/*category name*/}
                        <div className="mb-3">
                          <label htmlFor="categoryname" className="form-label">
                            Aktiflik
                          </label>
                          <div className="form-check form-switch form-switch-lg mb-3">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customSwitchsizelg"
                              defaultChecked={userStatus}
                              onChange={e => {
                                setUserStatus(e.target.checked)
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {/*submit button right*/}
                    <div className="col-lg-12">
                      <div className="mt-4 d-flex justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-primary w-md  mb-2"
                        >
                          Güncelle
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger w-md mx-3 mb-2"
                          onClick={() => {
                            navigate("/main-page")
                          }}
                        >
                          Geri
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Index
