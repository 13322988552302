import React from "react"
import { Button, Container } from "reactstrap"
import { Text, Group, ActionIcon } from "@mantine/core"
import { IconEdit, IconTrash, IconMenu2 } from "@tabler/icons-react"
import axios from "lib/axios"
import { useNavigate } from "react-router-dom"
import Breadcrumbs from "components/Common/Breadcrumb"
import DataTableMantine from "components/Added/datatable"
import DeleteModal from "components/Common/DeleteModal"
import { toast } from "react-toastify"

// class Expansion extends React.Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//       data: props.data,
//     }
//   }

//   render() {
//     return (
//       <div>
//         <pre>{JSON.stringify(this.props.data)}</pre>
//       </div>
//     )
//   }
// }

const Categories = () => {
  const Navigate = useNavigate()

  const [data, setData] = React.useState()

  React.useEffect(() => {
    axios
      .get("/category")
      .then(response => {
        if (response.data.category) {
          setData({ categories: response.data.category })
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  document.title = "Safkar Panel - Kategoriler"

  const [order, setOrder] = React.useState(null)
  const [deleteModal, setDeleteModal] = React.useState(false)

  const onClickDelete = order => {
    setOrder(order)
    setDeleteModal(true)
  }

  const handleDeleteOrder = () => {
    if (order && order.id) {
      let arr = {}
      arr["id"] = order.id
      axios
        .delete("/category", {
          data: arr,
        })
        .then(res => {
          toast.success("Kategori başarıyla silindi.")
          setDeleteModal(false)
          setData({
            categories: data.categories.filter(item => item.id !== order.id),
          })
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  return (
    <React.Fragment>
      {order && (
        <DeleteModal
          show={deleteModal}
          text={
            order?.categorylang[0]?.title +
            " kategorisini silmek istediğinize emin misiniz?"
          }
          onDeleteClick={handleDeleteOrder}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Panel" breadcrumbItem="Kategoriler" />
          <Group position="right" spacing={4} mt="md">
            <button
              className="btn btn-primary mb-2"
              onClick={() => {
                Navigate("/category/add")
              }}
            >
              Kategori Ekle
            </button>
          </Group>
          {data?.categories && (
            <DataTableMantine
              filterField={"categorylang[0].title"}
              // Expansion={Expansion}
              columns={[
                {
                  name: "ID",
                  selector: "id",
                  sortable: true,
                  width: 10,
                },
                {
                  name: "Kategori Adı",
                  selector: row => row.categorylang[0].title,
                  sortable: true,
                },
                {
                  name: "Alt Kategoriler",
                  selector: row => row.subcategory,
                  width: 120,
                  textAlign: "center",
                  cell: item => (
                    <ActionIcon
                      style={{ width: "100%" }}
                      color="blue"
                      onClick={() => Navigate("/subcategory/" + item.id)}
                    >
                      {item.subcategory.length}
                      <IconMenu2 size={16} style={{ marginLeft: "3px" }} />
                    </ActionIcon>
                  ),
                },
                {
                  name: "Islemler",
                  width: 110,
                  textAlign: "center",
                  cell: item => (
                    <Group spacing={4} position="center" noWrap>
                      <ActionIcon
                        color="blue"
                        onClick={() => Navigate("/category/edit/" + item.id)}
                      >
                        <IconEdit size={16} />
                      </ActionIcon>
                      <ActionIcon
                        color="red"
                        onClick={() => onClickDelete(item)}
                      >
                        <IconTrash size={16} />
                      </ActionIcon>
                    </Group>
                  ),
                  ignoreRowClick: true,
                  allowOverflow: true,
                  button: true,
                },
              ]}
              data={data.categories}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Categories
