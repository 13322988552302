import React from "react"
import {
  Container,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import { useNavigate } from "react-router-dom"

import classnames from "classnames"

import axios from "lib/axios"

// Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import { toast } from "react-toastify"

import { CKEditor } from "@ckeditor/ckeditor5-react"
import { Editor } from "ckeditor5-custom-build/build/ckeditor"

const FileAdd = () => {
  const Navigate = useNavigate()

  const [active, setActive] = React.useState(true)

  const [form, setForm] = React.useState()

  const [name, setName] = React.useState("")
  const [file, setFile] = React.useState(null)
  const [qr, setQr] = React.useState([])

  document.title = "Safkar Panel - Dosya Ekle"

  const [customActiveTab, setcustomActiveTab] = React.useState("0")

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  const isFaqForm = obj => {
    return true
  }

  const setFormValueByIndex = (index, key, value) => {
    const newForm = [...form]
    if (isFaqForm(newForm[index])) {
      newForm[index][key] = value.toString()
    }
    setForm(newForm)
  }

  const getFormValueByIndex = (index, key) => {
    const newForm = [...form]
    if (isFaqForm(newForm[index])) {
      if (newForm[index]) {
        return newForm[index][key]
      } else {
        return ""
      }
    }
    return ""
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Ekip" breadcrumbItem="Dosya Ekle" />

          {/* Render Form */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form
                    onSubmit={e => {
                      e.preventDefault()
                      if (file) {
                        var formData = new FormData()
                        formData.append("files", file)
                        axios.post("/file/pdf", formData).then(response => {
                          if (response.status == 200) {
                            axios
                              .post("/file", {
                                name: name,
                                path: response.data.files[0].path,
                                qr: response.data.files[0].filename,
                              })
                              .then(response2 => {
                                if (response2.status == 200) {
                                  toast.success("Dosya Eklendi")
                                  Navigate("/file")
                                }
                              })
                              .catch(error => {
                                console.log(error)
                                toast("Dosya Eklenemedi")
                              })
                          }
                        })
                      }
                    }}
                  >
                    <h4 className="card-title">Dosya Ekle</h4>
                    <p className="card-title-desc">Dosya Ekle</p>
                    <button
                      type="button"
                      className="btn btn-danger w-md mx-3 mb-2"
                      style={{
                        position: "absolute",
                        right: "0",
                        top: "20px",
                      }}
                      onClick={() => {
                        Navigate("/file")
                      }}
                    >
                      Geri
                    </button>
                    <div className="row">
                      <div className="col-lg-6">
                        {/*category name*/}
                        <div className="mb-3">
                          <label htmlFor="categoryname" className="form-label">
                            Ad
                          </label>
                          <input
                            id="categoryname"
                            name="categoryname"
                            type="text"
                            className="form-control"
                            placeholder="Dosya Adı"
                            onChange={e => {
                              setName(e.target.value)
                            }}
                          />
                        </div>

                        <div className="col-lg-6">
                          {/*category name*/}
                          <div className="mb-3">
                            <label
                              htmlFor="categoryname"
                              className="form-label"
                            >
                              Dosya
                            </label>
                            <input
                              id="categoryname"
                              name="categoryname"
                              type="file"
                              className="form-control"
                              accept="application/pdf"
                              placeholder="Dosya"
                              onChange={e => {
                                setFile(e.target.files[0])
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="mt-4 d-flex justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-primary w-md  mb-2"
                          >
                            Ekle
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger w-md mx-3 mb-2"
                            onClick={() => {
                              Navigate("/file")
                            }}
                          >
                            Geri
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FileAdd
