import React from "react"
import { Button, Container } from "reactstrap"

import { Text, Group, ActionIcon, BackgroundImage } from "@mantine/core"
import { IconEdit, IconTrash, IconMenu2 } from "@tabler/icons-react"

import axios from "lib/axios"

import { useNavigate } from "react-router-dom"

// Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

// Import Components
import DataTableMantine from "components/Added/datatable"
import DeleteModal from "components/Common/DeleteModal"

import { toast } from "react-toastify"

const Slider = () => {
  const Navigate = useNavigate()

  const [data, setData] = React.useState()

  React.useEffect(() => {
    axios
      .get("/slider")
      .then(response => {
        if (response.data.sliders) {
          setData({ sliders: response.data.sliders })
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])
  // meta title
  document.title = "Safkar Panel - Sliderlar"

  // delete slider
  const [slider, setSlider] = React.useState(null)
  const [deleteModal, setDeleteModal] = React.useState(false)

  const onClickDelete = slider => {
    setSlider(slider)
    setDeleteModal(true)
  }

  const handleDeleteSlider = () => {
    if (slider && slider.id) {
      let arr = {}
      arr["id"] = slider.id
      axios
        .delete("/slider?sliderId=" + slider.id)
        .then(res => {
          toast.success("Slider başarıyla silindi.")
          setDeleteModal(false)
          setData({
            sliders: data.sliders.filter(item => item.id !== slider.id),
          })
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  const arkaplan = {
    backgroundColor: "#D3E0FD",
  }

  return (
    <React.Fragment>
      {slider && (
        <DeleteModal
          show={deleteModal}
          text={
            slider.sliderlang[0].title +
            " sliderını silmek istediğinize emin misiniz?"
          }
          onDeleteClick={handleDeleteSlider}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Panel" breadcrumbItem="Sliderlar" />

          {/* Render Add Button */}
          <Group position="right" spacing={4} mt="md">
            <button
              className="btn btn-primary mb-2"
              onClick={() => {
                // rout Add slider
                Navigate("/slider/add")
              }}
            >
              Slider Ekle
            </button>
          </Group>

          {/* Render DataTable */}
          {data && (
            <DataTableMantine
              filterField={"sliderlang[0].title"}
              columns={[
                {
                  name: "ID",
                  selector: row => row.id,
                  sortable: true,
                  width: "50px",
                  textAlign: "center", // Combined "right" and "Center" into one; you may need to adjust
                },
                {
                  name: "Slider Adı",
                  selector: row => row.sliderlang[0].title,
                  sortable: true,
                },
                {
                  name: <Text mr="xs">Islemler</Text>,
                  width: "110px",
                  textAlign: "center",
                  cell: item => (
                    <Group spacing={4} position="center" noWrap>
                      <ActionIcon
                        color="blue"
                        onClick={() => Navigate("/slider/edit/" + item.id)}
                      >
                        <IconEdit size={16} />
                      </ActionIcon>
                      <ActionIcon
                        color="red"
                        onClick={() => onClickDelete(item)}
                      >
                        <IconTrash size={16} />
                      </ActionIcon>
                    </Group>
                  ),
                  ignoreRowClick: true,
                  allowOverflow: true,
                  button: true,
                },
              ]}
              data={data.sliders}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Slider
