import React from "react"
import {
  Container,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import { useParams, useNavigate } from "react-router-dom"

import classnames from "classnames"

import axios, { API_URL } from "lib/axios"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// Import Components

import { CKEditor } from "@ckeditor/ckeditor5-react"
import { Editor } from "ckeditor5-custom-build/build/ckeditor"

import { toast } from "react-toastify"
import { set } from "lodash"

const AboutEdit = () => {
  const Navigate = useNavigate()

  const [file, setFile] = React.useState(null)
  const [file2, setFile2] = React.useState(null)

  const [form, setForm] = React.useState()

  const params = useParams()

  //meta title
  document.title = "Safkar Panel - Hakkımızda Düzenle"

  const [customActiveTab, setCustomActiveTab] = React.useState("0")
  const [data, setData] = React.useState()

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab)
    }
  }

  const [lang, setLang] = React.useState()

  React.useEffect(() => {
    axios
      .get("/language")
      .then(responseLang => {
        if (responseLang.data.languages) {
          setLang(responseLang.data.languages)

          axios
            .get(`/about`)
            .then(responseKVKK => {
              setData(responseKVKK.data)
              if (responseKVKK.data) {
                const newForm = responseLang.data.languages.map(language => {
                  var kvkkLangRow = responseKVKK.data.aboutlang.find(
                    item => item.languageId == language.id
                  )

                  if (kvkkLangRow) {
                    return {
                      ["title_" + language.code]: kvkkLangRow.title,
                      ["subtitle_" + language.code]: kvkkLangRow.subtitle,
                      ["content_" + language.code]: kvkkLangRow.content,
                      ["note_" + language.code]: kvkkLangRow.note,
                      ["noteactive_" + language.code]: kvkkLangRow.noteactive,
                    }
                  } else {
                    return {
                      ["title_" + language.code]: "",
                      ["subtitle_" + language.code]: "",
                      ["content_" + language.code]: "",
                      ["note_" + language.code]: "",
                      ["noteactive_" + language.code]: "true",
                    }
                  }
                })
                setForm(newForm)
                if (responseKVKK.data.about.image != null) {
                  setFile(responseKVKK.data.about.image)
                }
                if (responseKVKK.data.about.breadcrumb != null) {
                  setFile2(responseKVKK.data.about.breadcrumb)
                }
              }
            })
            .catch(error => {
              console.log(error)
            })
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const isKVKKForm = obj => {
    return true
  }

  const setFormValueByIndex = (index, key, value) => {
    const newForm = [...form]
    if (isKVKKForm(newForm[index])) {
      newForm[index][key] = value.toString()
    }
    setForm(newForm)
  }

  const getFormValueByIndex = (index, key) => {
    const newForm = [...form]
    if (isKVKKForm(newForm[index])) {
      if (newForm[index]) {
        return newForm[index][key]
      } else {
        return ""
      }
    }
    return ""
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Hakkımızda" breadcrumbItem="Hakkımızda" />

          {/*Render Form */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form
                    onSubmit={e => {
                      e.preventDefault()
                      var arr = {}
                      form.forEach(item => {
                        Object.keys(item).forEach(key => {
                          arr[key] = item[key]
                        })
                      })

                      if (file && typeof file === "object") {
                        if (
                          file.type != "image/png" &&
                          file.type != "image/jpeg" &&
                          file.type != "image/jpg"
                        ) {
                          toast.error("Resim PNG, JPEG veya JPG olmalidir")
                          return
                        }
                      }

                      if (file2 && typeof file2 === "object") {
                        if (
                          file2.type != "image/png" &&
                          file2.type != "image/jpeg" &&
                          file2.type != "image/jpg"
                        ) {
                          toast.error("Resim PNG, JPEG veya JPG olmalidir")
                          return
                        }
                      }

                      axios
                        .put("/about", arr)
                        .then(response => {
                          if (response.status == 200) {
                            toast.success("Hakkımızda Güncellendi")
                          }
                          if (typeof file === "object") {
                            var formData = new FormData()
                            formData.append("images", file)
                            formData.append("aboutId", response.data.about.id)
                            axios
                              .post("/about/image", formData)
                              .then(response => {})
                          }

                          if (typeof file2 === "object") {
                            var formData = new FormData()
                            formData.append("breadcrumb", file2)
                            formData.append("aboutId", response.data.about.id)
                            axios
                              .post("/about/breadcrumb", formData)
                              .then(response => {})
                          }
                          Navigate("/about")
                        })
                        .catch(error => {
                          console.log(error)
                          toast("Hakkımızda Güncellenemedi")
                        })
                    }}
                  >
                    <h4 className="card-title">Hakkımızda Duzenle</h4>
                    <p className="card-title-desc">Hakkımızda Duzenle</p>
                    <button
                      type="button"
                      className="btn btn-danger w-md mx-3 mb-2"
                      style={{
                        position: "absolute",
                        right: "0",
                        top: "20px",
                      }}
                      onClick={() => {
                        Navigate("/about")
                      }}
                    >
                      Geri
                    </button>
                    <div className="row">
                      <div className="col-lg-6">
                        {/*category name*/}
                        <div className="mb-3">
                          <label htmlFor="aboutImage" className="form-label">
                            Hakkımızda Resmi
                          </label>
                          {file ? (
                            <img
                              src={
                                typeof file === "string"
                                  ? `${API_URL}/api/image?image=${file.replaceAll(
                                      " ",
                                      "%20"
                                    )}&path=about`
                                  : URL.createObjectURL(file)
                              }
                              alt="about image"
                              style={{ width: "100%" }}
                            />
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        {/*category name*/}
                        <div className="mb-3">
                          <label htmlFor="aboutImage" className="form-label">
                            Breadcrumb Resmi
                          </label>
                          {file2 ? (
                            <img
                              src={
                                typeof file2 === "string"
                                  ? `${API_URL}/api/image?image=${file2.replaceAll(
                                      " ",
                                      "%20"
                                    )}&path=breadcrumb`
                                  : URL.createObjectURL(file2)
                              }
                              alt="breadcrumb image"
                              style={{ width: "100%" }}
                            />
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        {/*category name*/}
                        <div className="mb-3">
                          <label
                            htmlFor="aboutImage"
                            className="form-label"
                          ></label>
                          <input
                            id="categoryname"
                            name="categoryname"
                            type="file"
                            accept=".png, .jpeg, .jpg"
                            className="form-control"
                            onChange={e => {
                              setFile(e.target.files[0])
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        {/*category name*/}
                        <div className="mb-3">
                          <label
                            htmlFor="aboutImage"
                            className="form-label"
                          ></label>
                          <input
                            id="categoryname"
                            name="categoryname"
                            type="file"
                            accept=".png, .jpeg, .jpg"
                            className="form-control"
                            onChange={e => {
                              setFile2(e.target.files[0])
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <Nav tabs className="nav-tabs-custom nav-justified">
                          {lang &&
                            lang.map((item, index) => (
                              <NavItem key={index}>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active:
                                      customActiveTab === index.toString(),
                                  })}
                                  onClick={() => {
                                    toggleCustom(index.toString())
                                  }}
                                >
                                  <span className="d-block d-sm-none">
                                    {item.displayname}
                                  </span>
                                  <span className="d-none d-sm-block">
                                    {item.displayname}
                                  </span>
                                </NavLink>
                              </NavItem>
                            ))}
                        </Nav>

                        <TabContent
                          activeTab={customActiveTab}
                          className="p-3 text-muted"
                        >
                          {form &&
                            lang &&
                            lang.map((item, index) => (
                              <TabPane tabId={index.toString()} key={index}>
                                <Row>
                                  <Col sm="12" lg="6">
                                    <div className="mt-4">
                                      <label
                                        htmlFor="aboutTitle"
                                        className="form-label"
                                      >
                                        Hakkımzıda Başlığı
                                      </label>

                                      <input
                                        id="aboutTitle"
                                        name="aboutTitle"
                                        type="text"
                                        className="form-control"
                                        placeholder="Hakkımızda Başlığı"
                                        defaultValue={getFormValueByIndex(
                                          index,
                                          "title_" + item.code
                                        )}
                                        onChange={e =>
                                          setFormValueByIndex(
                                            index,
                                            "title_" + item.code,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </Col>
                                  <Col sm="12" lg="6">
                                    <div className="mt-4">
                                      <label
                                        htmlFor="aboutTitle"
                                        className="form-label"
                                      >
                                        Hakkımızda Alt Başlığı
                                      </label>

                                      <input
                                        id="aboutTitle"
                                        name="aboutTitle"
                                        type="text"
                                        className="form-control"
                                        placeholder="Hakkımzızda Alt Başlığı"
                                        defaultValue={getFormValueByIndex(
                                          index,
                                          "subtitle_" + item.code
                                        )}
                                        onChange={e =>
                                          setFormValueByIndex(
                                            index,
                                            "subtitle_" + item.code,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </Col>

                                  <Col sm="12" lg="12">
                                    <div className="mt-4">
                                      <label
                                        htmlFor="aboutTitle"
                                        className="form-label"
                                      >
                                        İçerik
                                      </label>

                                      {/* <input
                                        id="aboutTitle"
                                        name="aboutTitle"
                                        type="text"
                                        className="form-control"
                                        placeholder="İçerik"
                                        defaultValue={getFormValueByIndex(
                                          index,
                                          "content_" + item.code
                                        )}
                                        onChange={e =>
                                          setFormValueByIndex(
                                            index,
                                            "content_" + item.code,
                                            e.target.value
                                          )
                                        }
                                      /> */}
                                      <CKEditor
                                        editor={Editor}
                                        data={getFormValueByIndex(
                                          index,
                                          "content_" + item.code
                                        )}
                                        onChange={(event, editor) => {
                                          const data = editor.getData()
                                          setFormValueByIndex(
                                            index,
                                            "content_" + item.code,
                                            data
                                          )
                                        }}
                                      />
                                    </div>
                                  </Col>

                                  <Col sm="12" lg="12">
                                    <div className="mt-4">
                                      <label
                                        htmlFor="aboutTitle"
                                        className="form-label"
                                      >
                                        Not
                                      </label>

                                      {/* <input
                                        id="aboutTitle"
                                        name="aboutTitle"
                                        type="text"
                                        className="form-control"
                                        placeholder="Not"
                                        defaultValue={getFormValueByIndex(
                                          index,
                                          "note_" + item.code
                                        )}
                                        onChange={e =>
                                          setFormValueByIndex(
                                            index,
                                            "note_" + item.code,
                                            e.target.value
                                          )
                                        }
                                      /> */}
                                      <CKEditor
                                        editor={Editor}
                                        data={getFormValueByIndex(
                                          index,
                                          "note_" + item.code
                                        )}
                                        onChange={(event, editor) => {
                                          const data = editor.getData()
                                          setFormValueByIndex(
                                            index,
                                            "note_" + item.code,
                                            data
                                          )
                                        }}
                                      />
                                    </div>
                                  </Col>
                                  <Col sm="12" lg="6">
                                    <div className="mt-4">
                                      <label
                                        htmlFor="aboutTitle"
                                        className="form-label"
                                      >
                                        Not Aktifliği
                                      </label>
                                      <div className="form-check form-switch form-switch-lg mb-3">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="customSwitchsizelg"
                                          defaultChecked={getFormValueByIndex(
                                            index,
                                            "noteactive_" + item.code
                                          )}
                                          onChange={e => {
                                            setFormValueByIndex(
                                              index,
                                              "noteactive_" + item.code,
                                              e.target.checked
                                            )
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </TabPane>
                            ))}
                        </TabContent>
                      </div>
                      <div className="col-lg-12">
                        <div className="mt-4 d-flex justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-primary w-md  mb-2"
                          >
                            Duzenle
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger w-md mx-3 mb-2"
                            onClick={() => {
                              Navigate("/about")
                            }}
                          >
                            Geri
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AboutEdit
