import React from "react"
import {
  Container,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import { useForm } from "@mantine/form"
import {
  TextInput,
  Switch,
  Group,
  ActionIcon,
  Box,
  Text,
  Button,
  Code,
  Center,
  Flex,
  FileInput,
} from "@mantine/core"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { randomId } from "@mantine/hooks"
import { IconTrash, IconGripVertical } from "@tabler/icons-react"
import { DatePickerInput } from "@mantine/dates"

import { useNavigate } from "react-router-dom"

import classnames from "classnames"

import axios from "lib/axios"

// Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"

import { toast } from "react-toastify"

import { CKEditor } from "@ckeditor/ckeditor5-react"
import { Editor } from "ckeditor5-custom-build/build/ckeditor"
import { set } from "lodash"
import dayjs from "dayjs"

const FaqAdd = () => {
  const Navigate = useNavigate()

  const [customActiveTab, setcustomActiveTab] = React.useState("PDF")

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  const form = useForm({
    initialValues: {
      links: [{ name: "", link: "", date: dayjs(new Date()), key: randomId() }],
    },
  })

  const pdf = useForm({
    initialValues: {
      pdf: [{ name: "", pdf: "", date: dayjs(new Date()), key: randomId() }],
    },
  })

  const fields = form.values.links.map((_, index) => (
    <Draggable key={index} index={index} draggableId={index.toString()}>
      {provided => (
        <Flex
          direction={{ base: "column", sm: "row" }}
          gap={{ base: "sm", sm: "lg" }}
          justify={{ sm: "center" }}
          ref={provided.innerRef}
          mt="lg"
          {...provided.draggableProps}
        >
          <Center {...provided.dragHandleProps}>
            <IconGripVertical size="1.2rem" />
          </Center>

          <TextInput
            placeholder="Ad"
            label="Ad"
            sx={{ flex: 2 }}
            {...form.getInputProps(`links.${index}.name`)}
          />
          <TextInput
            placeholder="Link"
            label="Link"
            sx={{ flex: 3 }}
            {...form.getInputProps(`links.${index}.link`)}
          />
          <div className="d-flex">
            {/* <TextInput
              placeholder="Tarih"
              label="Tarih"
              sx={{ flex: 1 }}
              {...form.getInputProps(`links.${index}.date`)}
            /> */}
            <DatePickerInput
              placeholder="Tarih"
              label="Tarih"
              sx={{ flex: 1 }}
              {...form.getInputProps(`links.${index}.date`)}
            />
            <ActionIcon
              color="red"
              variant="light"
              sx={{ marginTop: "2rem", marginLeft: "1rem" }}
              onClick={() => form.removeListItem("links", index)}
            >
              <IconTrash size="1.25rem" />
            </ActionIcon>
          </div>
        </Flex>
      )}
    </Draggable>
  ))

  const fieldsPdf = pdf.values.pdf.map((_, index) => (
    <Draggable key={index} index={index} draggableId={index.toString()}>
      {provided => (
        <Flex
          direction={{ base: "column", sm: "row" }}
          gap={{ base: "sm", sm: "lg" }}
          justify={{ sm: "center" }}
          ref={provided.innerRef}
          mt="lg"
          {...provided.draggableProps}
        >
          <Center {...provided.dragHandleProps}>
            <IconGripVertical size="1.2rem" />
          </Center>

          <TextInput
            placeholder="Ad"
            label="Ad"
            sx={{ flex: 2 }}
            {...pdf.getInputProps(`pdf.${index}.name`)}
          />
          <FileInput
            placeholder="Dosya Seciniz"
            label="Dosya"
            sx={{ flex: 3 }}
            onChange={e => {
              var pdfFile = new FormData()
              pdfFile.append("files", e)
              axios.post("/invest/pdf", pdfFile).then(response => {
                pdf
                  .getInputProps(`pdf.${index}.pdf`)
                  .onChange(response.data.files[0].filename)
              })
            }}
            //{...pdf.getInputProps(`pdf.${index}.pdf`)}
          />
          <div className="d-flex">
            {/* <TextInput
              placeholder="Tarih"
              label="Tarih"
              sx={{ flex: 1 }}
              {...form.getInputProps(`links.${index}.date`)}
            /> */}
            <DatePickerInput
              placeholder="Tarih"
              label="Tarih"
              sx={{ flex: 1 }}
              {...pdf.getInputProps(`pdf.${index}.date`)}
            />
            <ActionIcon
              color="red"
              variant="light"
              sx={{ marginTop: "2rem", marginLeft: "1rem" }}
              onClick={() => {
                axios
                  .post("/invest/delete", { pdf: pdf.values.pdf[index].pdf })
                  .then(response => {
                    if (response.status == 200) {
                      pdf.removeListItem("pdf", index)
                    }
                  })
              }}
            >
              <IconTrash size="1.25rem" />
            </ActionIcon>
          </div>
        </Flex>
      )}
    </Draggable>
  ))

  document.title = "Safkar Panel - Yatirimci Iliskisi Ekle"

  const [title, setTitle] = React.useState("")
  const [icon, setIcon] = React.useState("")
  const [type, setType] = React.useState("PDF")

  const [active, setActive] = React.useState(true)
  const [short, setShort] = React.useState(true)
  const [dateshow, setDateshow] = React.useState(true)

  // changeble data
  //const [pdf, setPdf] = React.useState(null)
  //const [link, setLink] = React.useState("")
  const [image, setImage] = React.useState()
  const [text, setText] = React.useState("")

  React.useEffect(() => {}, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Yatirimci iliskisi"
            breadcrumbItem="Yatirimci Iliskisi Ekle"
          />

          {/* Render Form */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form
                    onSubmit={e => {
                      e.preventDefault()

                      var arr = {}
                      arr["name"] = title
                      arr["type"] = type
                      arr["icon"] = icon

                      arr["active"] = active
                      arr["short"] = short
                      arr["dateshow"] = dateshow

                      arr["links"] = form.values
                      arr["files"] = pdf.values
                      arr["text"] = text

                      axios
                        .post("/invest", arr)
                        .then(response => {
                          if (response.status == 200) {
                            toast.success("Sirket Bilgisi Eklendi")
                            if (image) {
                              var formData = new FormData()

                              formData.append("images", image)
                              formData.append("investId", response.data.data.id)
                              axios
                                .post("/invest/image", formData)
                                .then(response => {})
                            }
                          }
                          Navigate("/comps")
                        })
                        .catch(error => {
                          console.log(error)
                          toast("Sirket Bilgisi Eklenemedi")
                        })
                    }}
                  >
                    <h4 className="card-title">Yatirimci Iliskisi Ekle</h4>
                    <p className="card-title-desc">Yatirimci Iliskisi Ekle</p>
                    <button
                      type="button"
                      className="btn btn-danger w-md mx-3 mb-2"
                      style={{
                        position: "absolute",
                        right: "0",
                        top: "20px",
                      }}
                      onClick={() => {
                        Navigate("/comps")
                      }}
                    >
                      Geri
                    </button>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mt-4">
                          <label htmlFor="infoTitle" className="form-label">
                            Isim
                          </label>
                          <input
                            id="infoTitle"
                            name="infoTitle"
                            type="text"
                            className="form-control"
                            placeholder="Sirket Bilgisi Başlık"
                            onChange={e => setTitle(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mt-4">
                          <label htmlFor="infoTitle" className="form-label">
                            Icon
                          </label>
                          <input
                            id="infoTitle"
                            name="infoTitle"
                            type="text"
                            className="form-control"
                            placeholder="Sirket Bilgisi Bilgi"
                            onChange={e => setIcon(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mt-4">
                          <label htmlFor="infoTitle" className="form-label">
                            Tur
                          </label>
                          <select
                            className="form-control"
                            onChange={e => setType(e.target.value)}
                          >
                            <option value="PDF">PDF</option>
                            <option value="LINK">LINK</option>
                            <option value="IMAGE">RESIM</option>
                            <option value="TEXT">YAZI</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-1 col-2">
                        <div className="mt-4">
                          <label htmlFor="infoTitle" className="form-label">
                            Aktiflik
                          </label>
                          <div className="form-check form-switch form-switch-lg mb-3">
                            {active != null && (
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwitchsizelg"
                                defaultChecked={active ? true : false}
                                onChange={e => {
                                  setActive(e.target.checked)
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-1 col-2">
                        <div className="mt-4">
                          <label htmlFor="infoTitle" className="form-label">
                            Siralama
                          </label>
                          <div className="form-check form-switch form-switch-lg mb-3">
                            {short != null && (
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwitchsizelg"
                                defaultChecked={short ? true : false}
                                onChange={e => {
                                  setShort(e.target.checked)
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-4">
                        <div className="mt-4">
                          <label htmlFor="infoTitle" className="form-label">
                            Tarih Gorunum
                          </label>
                          <div className="form-check form-switch form-switch-lg mb-3">
                            {dateshow != null && (
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwitchsizelg"
                                defaultChecked={dateshow ? true : false}
                                onChange={e => {
                                  setDateshow(e.target.checked)
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="mt-4">
                          <Nav tabs className="nav-tabs-custom nav-justified">
                            <NavItem key="1">
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: customActiveTab === "PDF",
                                })}
                                onClick={() => {
                                  toggleCustom("PDF")
                                }}
                              >
                                <span className="d-block d-sm-none">PDF</span>
                                <span className="d-none d-sm-block">PDF</span>
                              </NavLink>
                            </NavItem>
                            <NavItem key="2">
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: customActiveTab === "LINK",
                                })}
                                onClick={() => {
                                  toggleCustom("LINK")
                                }}
                              >
                                <span className="d-block d-sm-none">LINK</span>
                                <span className="d-none d-sm-block">LINK</span>
                              </NavLink>
                            </NavItem>
                            <NavItem key="3">
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: customActiveTab === "IMAGE",
                                })}
                                onClick={() => {
                                  toggleCustom("IMAGE")
                                }}
                              >
                                <span className="d-block d-sm-none">RESIM</span>
                                <span className="d-none d-sm-block">RESIM</span>
                              </NavLink>
                            </NavItem>
                            <NavItem key="4">
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: customActiveTab === "TEXT",
                                })}
                                onClick={() => {
                                  toggleCustom("TEXT")
                                }}
                              >
                                <span className="d-block d-sm-none">YAZI</span>
                                <span className="d-none d-sm-block">YAZI</span>
                              </NavLink>
                            </NavItem>
                          </Nav>
                          <TabContent
                            activeTab={customActiveTab}
                            className="p-3 text-muted"
                          >
                            <TabPane tabId="PDF" key="1">
                              <Row>
                                <Col sm="12" lg="12">
                                  <div className="mt-2">
                                    <Box mx="auto">
                                      <div className="mb-4">
                                        <Group position="left" mt="md">
                                          <Button
                                            onClick={() =>
                                              pdf.insertListItem(
                                                "pdf",
                                                {
                                                  name: "",
                                                  pdf: "",
                                                  date: dayjs(new Date()),
                                                  key: randomId(),
                                                },
                                                0
                                              )
                                            }
                                            className="btn btn-primary w-md  mb-2"
                                          >
                                            PDF Ekle
                                          </Button>
                                        </Group>
                                      </div>
                                      {fieldsPdf.length > 0 ? null : (
                                        <Text color="dimmed" align="center">
                                          PDF yok
                                        </Text>
                                      )}

                                      <DragDropContext
                                        onDragEnd={({ destination, source }) =>
                                          pdf.reorderListItem("pdf", {
                                            from: source.index,
                                            to: destination.index,
                                          })
                                        }
                                      >
                                        <Droppable
                                          droppableId="dnd-list"
                                          direction="vertical"
                                        >
                                          {provided => (
                                            <div
                                              {...provided.droppableProps}
                                              ref={provided.innerRef}
                                            >
                                              {fieldsPdf}
                                              {provided.placeholder}
                                            </div>
                                          )}
                                        </Droppable>
                                      </DragDropContext>
                                    </Box>
                                  </div>
                                  {/* <Code block mt={5}>
                                    {JSON.stringify(pdf.values, null, 2)}
                                  </Code> */}
                                </Col>
                              </Row>
                            </TabPane>
                            <TabPane tabId="LINK" key="2">
                              <Row>
                                <Col sm="12" lg="12">
                                  <div className="mt-2">
                                    <Box mx="auto">
                                      <div className="mb-4">
                                        <Group position="left" mt="md">
                                          <Button
                                            onClick={() =>
                                              form.insertListItem(
                                                "links",
                                                {
                                                  name: "",
                                                  link: "",
                                                  date: dayjs(new Date()),
                                                  key: randomId(),
                                                },
                                                0
                                              )
                                            }
                                            className="btn btn-primary w-md  mb-2"
                                          >
                                            Link Ekle
                                          </Button>
                                        </Group>
                                      </div>
                                      {fields.length > 0 ? null : (
                                        <Text color="dimmed" align="center">
                                          Link yok
                                        </Text>
                                      )}

                                      <DragDropContext
                                        onDragEnd={({ destination, source }) =>
                                          form.reorderListItem("links", {
                                            from: source.index,
                                            to: destination.index,
                                          })
                                        }
                                      >
                                        <Droppable
                                          droppableId="dnd-list"
                                          direction="vertical"
                                        >
                                          {provided => (
                                            <div
                                              {...provided.droppableProps}
                                              ref={provided.innerRef}
                                            >
                                              {fields}
                                              {provided.placeholder}
                                            </div>
                                          )}
                                        </Droppable>
                                      </DragDropContext>
                                    </Box>
                                  </div>
                                </Col>
                              </Row>
                            </TabPane>
                            <TabPane tabId="IMAGE" key="3">
                              <Row>
                                <Col sm="12" lg="12">
                                  <div className="mt-4">
                                    <label
                                      htmlFor="faqTitle"
                                      className="form-label"
                                    >
                                      RESIM
                                    </label>
                                    <input
                                      id="faqTitle"
                                      name="faqTitle"
                                      type="file"
                                      accept=".png, .jpeg, .jpg"
                                      className="form-control"
                                      placeholder="SSS Başlık"
                                      onChange={e => {
                                        setImage(e.target.files[0])
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </TabPane>
                            <TabPane tabId="TEXT" key="4">
                              <Row>
                                <Col sm="12" lg="12">
                                  <div className="mt-4">
                                    <label
                                      htmlFor="faqTitle"
                                      className="form-label"
                                    >
                                      YAZI
                                    </label>
                                    <CKEditor
                                      editor={Editor}
                                      data=""
                                      onChange={(event, editor) => {
                                        const data = editor.getData()
                                        setText(data)
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </TabPane>
                          </TabContent>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="mt-4 d-flex justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-primary w-md  mb-2"
                          >
                            Ekle
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger w-md mx-3 mb-2"
                            onClick={() => {
                              Navigate("/comps")
                            }}
                          >
                            Geri
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FaqAdd
