import React from "react"
import { Container } from "reactstrap"

import { Text, Group, ActionIcon } from "@mantine/core"
import { IconEdit, IconTrash } from "@tabler/icons-react"

import axios from "lib/axios"

import { useNavigate } from "react-router-dom"

// Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

// Import Components
import DataTableMantine from "components/Added/datatable"
import DeleteModal from "components/Common/DeleteModal"

import { toast } from "react-toastify"

const comps = () => {
  const Navigate = useNavigate()

  const [data, setData] = React.useState()

  React.useEffect(() => {
    axios
      .get("/invest")
      .then(response => {
        if (response.data.data) {
          setData({ comps: response.data.data })
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  //meta title
  document.title = "Safkar Panel - Yatirimci Iliskileri"

  //delete info
  const [info, setinfo] = React.useState(null)
  const [deleteModal, setDeleteModal] = React.useState(false)

  const onClickDelete = info => {
    setinfo(info)
    setDeleteModal(true)
  }

  const handleDeleteinfo = () => {
    if (info && info.id) {
      let arr = {}
      arr["id"] = info.id
      axios
        .delete("/invest", {
          data: arr,
        })
        .then(res => {
          toast.success("Yatirimci iliskisi başarıyla silindi.")
          setDeleteModal(false)
          setData({
            comps: data.comps.filter(item => item.id !== info.id),
          })
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
  const handleinfoClicks = () => {
    setinfoList("")
    setIsEdit(false)
    toggle()
  }

  return (
    <React.Fragment>
      {info && (
        <DeleteModal
          show={deleteModal}
          text={
            info.name +
            " baslikli yatirimci iliskisini silmek istediğinize emin misiniz?"
          }
          onDeleteClick={handleDeleteinfo}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Panel" breadcrumbItem="Yatirimci Iliskisi" />

          {/* Render Add Button */}
          <Group position="right" spacing={4} mt="md">
            <button
              className="btn btn-primary mb-2"
              onClick={() => {
                //rout Add info
                Navigate("/comp/add")
              }}
            >
              Yatirimci Iliskisi Ekle
            </button>
          </Group>

          {/* Render DataTable */}
          {data && (
            <DataTableMantine
              filterField={"name"}
              columns={[
                {
                  name: "ID",
                  selector: row => row.id,
                  sortable: true,
                  width: 50,
                  textAlign: "center", // Removed duplicate textAlignment and kept "Center" as the value
                },
                {
                  name: "Baslik",
                  selector: row => row.name,
                  sortable: true,
                },
                {
                  name: <Text mr="xs">İşlemler</Text>,
                  width: 110,
                  textAlign: "center",
                  cell: item => (
                    <Group spacing={4} position="center" noWrap>
                      <ActionIcon
                        color="blue"
                        onClick={() => Navigate("/comp/edit/" + item.id)}
                      >
                        <IconEdit size={16} />
                      </ActionIcon>
                      <ActionIcon
                        color="red"
                        onClick={() => onClickDelete(item)}
                      >
                        <IconTrash size={16} />
                      </ActionIcon>
                    </Group>
                  ),
                  ignoreRowClick: true,
                  allowOverflow: true,
                  button: true,
                },
              ]}
              data={data.comps}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default comps
