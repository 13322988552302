import React from "react"
import {
  Container,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import { useParams, useNavigate } from "react-router-dom"

import classnames from "classnames"

import axios, { API_URL } from "lib/axios"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// Import Components

import { toast } from "react-toastify"

import { CKEditor } from "@ckeditor/ckeditor5-react"
import { Editor } from "ckeditor5-custom-build/build/ckeditor"

import { MultiSelect } from "@mantine/core"

const MainPage = () => {
  const Navigate = useNavigate()
  const params = useParams()

  // all variables
  const [file, setFile] = React.useState()
  const [image, setImage] = React.useState()
  const [sideImage, setSideImage] = React.useState()
  const [bottomImage, setBottomImage] = React.useState()

  const [fileC, setFileC] = React.useState(null)
  const [fileActive, setFileActive] = React.useState(false)

  const [active, setActive] = React.useState(true)
  const [top, setTop] = React.useState(true)

  const [index, setIndex] = React.useState(0)

  const [form, setForm] = React.useState()

  const [content, setContent] = React.useState()

  const [topCategory, setTopCategory] = React.useState(0)
  const [topCategories, setTopCategories] = React.useState()
  const [subCategories, setSubCategories] = React.useState()
  const [subCategoriesData, setSubCategoriesData] = React.useState()
  const [selectedSubCategoriesData, setSelectedSubCategoriesData] =
    React.useState([])

  const [currentTopCategories, setCurrentTopCategories] = React.useState([])
  const [currentsubCategories, setCurrentSubCategories] = React.useState([])
  const [currentDetail, setCurrentDetail] = React.useState([])

  //meta title
  document.title = "Safkar Panel - Urun Duzenle"

  const [customActiveTab, setcustomActiveTab] = React.useState("0")

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  const [lang, setLang] = React.useState()

  const [data, setData] = React.useState()

  React.useEffect(() => {
    axios
      .get("/language")
      .then(responseLang => {
        if (responseLang.data.languages) {
          setLang(responseLang.data.languages)

          axios.get(`/product?id=${params.id}`).then(responseProduct => {
            if (responseProduct.data.products) {
              setData({ product: responseProduct.data.products })
              setActive(responseProduct.data.products[0].active)
              setTop(responseProduct.data.products[0].top)
              setTopCategory(responseProduct.data.products[0].category.id)
              setCurrentTopCategories(
                responseProduct.data.products[0].category.id
              )
              setFile(responseProduct.data.products[0].image)
              setFileC(responseProduct.data.products[0].file)
              setFileActive(responseProduct.data.products[0].showFile)
              setSideImage(responseProduct.data.products[0].sideimage)
              setBottomImage(responseProduct.data.products[0].bottomimage)
              setContent(responseProduct.data.products[0].content)
              setIndex(responseProduct.data.products[0].index)

              let sub = []

              responseProduct.data.products[0].subcategorys.map(item => {
                sub.push(item.id)
              })

              setCurrentSubCategories(sub)

              var detail = responseProduct.data.products[0].productlang.map(
                item => {
                  return {
                    ["id"]: item.languageId,
                    ["code"]: item.language.code,
                    ["detail"]: item.detail,
                  }
                }
              )

              setCurrentDetail(detail)

              const newForm = responseLang.data.languages.map(language => {
                var categoryLangRow =
                  responseProduct.data.products[0].productlang.find(
                    item => item.languageId == language.id
                  )

                if (categoryLangRow) {
                  return {
                    ["title_" + language.code]: categoryLangRow.title,
                    ["content_" + language.code]: categoryLangRow.content,
                    ["detail_" + language.code]: categoryLangRow.detail,
                    active: "1",
                  }
                } else {
                  return {
                    ["title_" + language.code]: "",
                    ["content_" + language.code]: "",
                    ["detail_" + language.code]: "",
                    active: "1",
                  }
                }
              })
              setForm(newForm)
            }
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
    axios
      .get("/category")
      .then(response => {
        if (response.data.category) {
          setTopCategories(response.data.category)
        }
      })
      .catch(error => {
        console.log(error)
      })

    axios.get("/subcategory").then(response => {
      if (response.data.subcategory) {
        setSubCategories(response.data.subcategory)

        setTopCategoriesData(response.data.subcategory, topCategory)
      }
    })
  }, [])

  const isproductForm = obj => {
    // Gerekli özelliklerin varlığını kontrol ederek
    // obj'nin productForm tipinde olduğunu doğrulayabilirsiniz
    // Örnek olarak: return 'property1' in obj && 'property2' in obj;
    return true
  }

  const setFormValueByIndex = (index, key, value) => {
    const newForm = [...form]
    if (isproductForm(newForm[index])) {
      if (typeof value == "undefined" || value == null) {
        newForm[index][key] = ""
      } else {
        newForm[index][key] = value.toString()
      }
    }
    setForm(newForm)
  }

  const getFormValueByIndex = (index, key) => {
    const newForm = [...form]
    if (isproductForm(newForm[index])) {
      //if newform has key
      if (newForm[index]) {
        return newForm[index][key]
      } else {
        return ""
      }
    }
    return ""
  }

  const setTopCategoriesData = (subCat, topCat) => {
    const data = []

    if (subCat && lang) {
      var langu = lang.filter(item => item.code == "tr")[0]
      var cat = {}

      subCat.forEach(item => {
        if (langu != undefined) {
          cat = item.subcategorylang[0]
        } else {
          cat = item.subcategorylang.filter(
            item => item.languageId == langu.id
          )[0]
        }

        if (item.categoryId == topCat) {
          data.push({
            label: cat.title,
            value: item.id,
          })
        }
      })

      setSubCategoriesData(data)
    }
  }

  const parseTableContent = data => {
    const tempDiv = document.createElement("div")
    tempDiv.innerHTML = data

    const tableElements = tempDiv.querySelectorAll("table")
    const tables = []

    tableElements.forEach(tableElement => {
      const rows = []

      const rowElements = tableElement.querySelectorAll("tr")
      rowElements.forEach(rowElement => {
        const cells = []

        const cellElements = rowElement.querySelectorAll("td")
        cellElements.forEach(cellElement => {
          cells.push(cellElement.innerHTML)
        })

        rows.push(cells)
      })

      tables.push(rows)
    })

    return tables
  }

  React.useEffect(() => {
    if (topCategory && subCategories && lang) {
      setTopCategoriesData(subCategories, topCategory)
      //setSelectedSubCategoriesData([])

      if (topCategory == currentTopCategories) {
        if (currentsubCategories && currentsubCategories.length > 0) {
          setSelectedSubCategoriesData(currentsubCategories)
        } else {
          setSelectedSubCategoriesData([])
        }
        try {
          lang.forEach((item, index) => {
            const value = currentDetail.filter(i => i.id == item.id)[0]

            setFormValueByIndex(index, "detail_" + item.code, value["detail"])
          })

          //   currentDetail.forEach((item,index) => {
          //     console.log("item", item)
          //     const lancode = lang.filter(i => i.id == item.languageId)[0]
          //     console.log(lancode,"lan")

          //     setFormValueByIndex(index, "detail_" + lancode.code, item["detail"])
          //   })
        } catch (error) {
          console.log(error)
        }
      } else {
        setSelectedSubCategoriesData([])

        let data = topCategories.filter(t => t.id == topCategory)[0]

        data.categorylang.map((item, index) => {
          const lancode = lang.filter(i => i.id == item.languageId)[0]

          setFormValueByIndex(index, "detail_" + lancode.code, item.table)
        })
      }

      //   console.log(
      //   selectedSubCategoriesData)
      //   selectedSubCategoriesData.forEach(item => {
      //     sub.push({ id: item })
      //   })
      //   if (data.categoryId == topCategory) {
      //     setSelectedSubCategoriesData(data.subcategory)
      //   } else {
      //     setSelectedSubCategoriesData([])
      //   }

      //   let data = topCategories.filter(t => t.id == topCategory)[0]

      //   data.categorylang.map((item, index) => {
      //     const lancode = lang.filter(i => i.id == item.languageId)[0]

      //     setFormValueByIndex(index, "detail_" + lancode.code, item.table)
      //   })
    } else {
      setSubCategoriesData([])
    }
  }, [topCategory])

  return (
    <React.Fragment>
      {data && (
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Urunler" breadcrumbItem="Urun Duzenle" />

            {/* Render Form */}
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <form
                      onSubmit={e => {
                        e.preventDefault()
                        var arr = {}
                        form.forEach(item => {
                          Object.keys(item).forEach(key => {
                            arr[key] = item[key]
                          })
                        })

                        arr["active"] = active
                        arr["top"] = top
                        arr["categoryId"] = topCategory
                        arr["file"] = fileC
                        arr["showFile"] = fileActive
                        arr["index"] = index
                        var sub = []

                        selectedSubCategoriesData.forEach(item => {
                          sub.push({ id: item })
                        })

                        arr["subcategorys"] = sub

                        arr["id"] = params.id

                        if (fileC && typeof fileC === "object") {
                          var formData1 = new FormData()
                          formData1.append("files", fileC)
                          axios
                            .post("/product/pdf", formData1)
                            .then(responseF => {
                              if (responseF.status == 200) {
                                arr["file"] = responseF.data.files[0].filename

                                axios
                                  .put("/product", arr)
                                  .then(response => {
                                    if (response.status == 200) {
                                      toast.success("Urun Duzenlendi")
                                    }
                                    if (typeof file === "object") {
                                      var formData = new FormData()
                                      formData.append("images", file)
                                      formData.append(
                                        "productId",
                                        response.data.product.id
                                      )
                                      axios
                                        .post("/product/image", formData)
                                        .then(response => {})
                                    }
                                    if (typeof sideImage === "object") {
                                      var formData = new FormData()
                                      formData.append("images", sideImage)
                                      formData.append(
                                        "productId",
                                        response.data.product.id
                                      )
                                      axios
                                        .post("/product/sideimage", formData)
                                        .then(response => {})
                                    }
                                    if (typeof bottomImage === "object") {
                                      var formData = new FormData()
                                      formData.append("images", bottomImage)
                                      formData.append(
                                        "productId",
                                        response.data.product.id
                                      )
                                      axios
                                        .post("/product/bottomimage", formData)
                                        .then(response => {})
                                    }
                                  })
                                  .catch(error => {
                                    console.log(error)
                                    toast("Urun Duzenlenemedi")
                                  })
                              }
                            })
                        } else {
                          axios
                            .put("/product", arr)
                            .then(response => {
                              if (response.status == 200) {
                                toast.success("Urun Duzenlendi")
                              }
                              if (typeof file === "object") {
                                var formData = new FormData()
                                formData.append("images", file)
                                formData.append(
                                  "productId",
                                  response.data.product.id
                                )
                                axios
                                  .post("/product/image", formData)
                                  .then(response => {})
                              }
                              if (typeof sideImage === "object") {
                                var formData = new FormData()
                                formData.append("images", sideImage)
                                formData.append(
                                  "productId",
                                  response.data.product.id
                                )
                                axios
                                  .post("/product/sideimage", formData)
                                  .then(response => {})
                              }
                              if (typeof bottomImage === "object") {
                                var formData = new FormData()
                                formData.append("images", bottomImage)
                                formData.append(
                                  "productId",
                                  response.data.product.id
                                )
                                axios
                                  .post("/product/bottomimage", formData)
                                  .then(response => {})
                              }
                            })
                            .catch(error => {
                              console.log(error)
                              toast("Urun Duzenlenemedi")
                            })
                        }
                      }}
                    >
                      <h4 className="card-title">Urun Duzenle</h4>
                      <p className="card-title-desc">Urun Duzenle</p>
                      {/* back button */}
                      <button
                        type="button"
                        className="btn btn-danger w-md mx-3 mb-2"
                        style={{
                          position: "absolute",
                          right: "0",
                          top: "20px",
                        }}
                        onClick={() => {
                          //rout Edit product
                          Navigate("/products")
                        }}
                      >
                        Geri
                      </button>
                      <div className="row">
                        <div className="col-lg-4">
                          {/* two colum*/}
                          <div className="row">
                            <div className="col-12">
                              {/*product name*/}
                              <div className="mb-3">
                                <label
                                  htmlFor="productname"
                                  className="form-label"
                                >
                                  Urun Resmi
                                </label>
                                <input
                                  id="productname"
                                  name="productname"
                                  type="file"
                                  accept=".png, .jpeg, .jpg"
                                  className="form-control"
                                  onChange={e => {
                                    setFile(e.target.files[0])
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              {/*product side image*/}
                              <div className="mb-3">
                                {file && (
                                  <img
                                    src={
                                      typeof file === "string"
                                        ? `${API_URL}/api/image?image=${file.replaceAll(
                                            " ",
                                            "%20"
                                          )}&path=product`
                                        : URL.createObjectURL(file)
                                    }
                                    alt=""
                                    width={"100%"}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          {/* two colum*/}
                          <div className="row">
                            <div className="col-12">
                              {/*product name*/}
                              <div className="mb-3">
                                <label
                                  htmlFor="productname"
                                  className="form-label"
                                >
                                  Urun Yan Resmi
                                </label>
                                <input
                                  id="productname"
                                  name="productname"
                                  type="file"
                                  accept=".png, .jpeg, .jpg"
                                  className="form-control"
                                  onChange={e => {
                                    setSideImage(e.target.files[0])
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              {/*product side image*/}
                              <div className="mb-3">
                                {sideImage && (
                                  <img
                                    src={
                                      typeof sideImage === "string"
                                        ? `${API_URL}/api/image?image=${sideImage.replaceAll(
                                            " ",
                                            "%20"
                                          )}&path=product/sideImage`
                                        : URL.createObjectURL(sideImage)
                                    }
                                    alt=""
                                    width={"100%"}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          {/* two colum*/}
                          <div className="row">
                            <div className="col-12">
                              {/*product name*/}
                              <div className="mb-3">
                                <label
                                  htmlFor="productname"
                                  className="form-label"
                                >
                                  Urun Alt Resmi
                                </label>
                                <input
                                  id="productname"
                                  name="productname"
                                  type="file"
                                  accept=".png, .jpeg, .jpg"
                                  className="form-control"
                                  onChange={e => {
                                    setBottomImage(e.target.files[0])
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              {/*product side image*/}
                              <div className="mb-3">
                                {bottomImage && (
                                  <img
                                    src={
                                      typeof bottomImage === "string"
                                        ? `${API_URL}/api/image?image=${bottomImage.replaceAll(
                                            " ",
                                            "%20"
                                          )}&path=product/bottomImage`
                                        : URL.createObjectURL(bottomImage)
                                    }
                                    alt=""
                                    width={"100%"}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-5">
                          {/*category select*/}
                          <div className="mb-3">
                            <label htmlFor="productname" className="form-label">
                              Urun Kategorisi
                            </label>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              onChange={e => {
                                setTopCategory(e.target.value)
                              }}
                              defaultValue={topCategory}
                            >
                              <option selected disabled>
                                Urun Kategorisi
                              </option>
                              {topCategories &&
                                topCategories.map(category => {
                                  var languageId = lang.filter(
                                    item => item.code == "tr"
                                  )[0].id
                                  var data = category.categorylang.filter(
                                    item => item.languageId == languageId
                                  )[0]
                                  if (data && data.title) {
                                    return (
                                      <option
                                        key={category.id}
                                        value={category.id}
                                      >
                                        {data.title}
                                      </option>
                                    )
                                  } else {
                                    return (
                                      <option
                                        key={category.id}
                                        value={category.id}
                                      >
                                        {category.categorylang[0].title}
                                      </option>
                                    )
                                  }
                                })}
                            </select>
                          </div>
                        </div>
                        <div className="col-5">
                          {/*product index*/}
                          <div className="mb-3">
                            <label htmlFor="productname" className="form-label">
                              Ürün Sırası
                            </label>
                            <input
                              id="productname"
                              name="productname"
                              type="number"
                              className="form-control"
                              placeholder="Ürün Sırası"
                              defaultValue={index}
                              onChange={e => {
                                setIndex(e.target.value)
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-5">
                          {/*subcategory select*/}

                          <div className="mb-3">
                            <label htmlFor="productname" className="form-label">
                              Alt Kategoriler
                            </label>
                            <MultiSelect
                              data={subCategoriesData ? subCategoriesData : []}
                              value={selectedSubCategoriesData}
                              placeholder="Alt Kategorileri Seciniz"
                              onChange={e => {
                                setSelectedSubCategoriesData(e)
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-1">
                          {/*product name*/}
                          <div className="mb-3">
                            <label htmlFor="productname" className="form-label">
                              Aktiflik
                            </label>
                            <div className="form-check form-switch form-switch-lg mb-3">
                              {active != null && (
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customSwitchsizelg"
                                  defaultChecked={active ? true : false}
                                  onChange={e => {
                                    setActive(e.target.checked)
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-1">
                          {/*product name*/}
                          <div className="mb-3">
                            <label htmlFor="productname" className="form-label">
                              One Cikar
                            </label>
                            <div className="form-check form-switch form-switch-lg mb-3">
                              {top != null && (
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customSwitchsizelg"
                                  defaultChecked={top ? true : false}
                                  onChange={e => {
                                    setTop(e.target.checked)
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-8">
                          {/*category name*/}
                          <div className="mb-3">
                            <label
                              htmlFor="categoryname"
                              className="form-label"
                            >
                              Dosya
                            </label>
                            <input
                              id="categoryname"
                              name="categoryname"
                              type="file"
                              className="form-control"
                              placeholder="Dosya"
                              accept="application/pdf"
                              onChange={e => {
                                setFileC(e.target.files[0])
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          {/*category name*/}
                          <div className="mb-3">
                            <label
                              htmlFor="categoryname"
                              className="form-label"
                            >
                              Dosya Görünürlüğü
                            </label>
                            <div className="form-check form-switch form-switch-lg mb-3">
                              {fileActive != null && (
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customSwitchsizelg"
                                  defaultChecked={fileActive ? true : false}
                                  onChange={e => {
                                    setFileActive(e.target.checked)
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <Nav tabs className="nav-tabs-custom nav-justified">
                            {lang &&
                              lang.map((item, index) => (
                                <NavItem key={index}>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      active:
                                        customActiveTab === index.toString(),
                                    })}
                                    onClick={() => {
                                      toggleCustom(index.toString())
                                    }}
                                  >
                                    <span className="d-block d-sm-none">
                                      {item.displayname}
                                    </span>
                                    <span className="d-none d-sm-block">
                                      {item.displayname}
                                    </span>
                                  </NavLink>
                                </NavItem>
                              ))}
                          </Nav>

                          <TabContent
                            activeTab={customActiveTab}
                            className="p-3 text-muted"
                          >
                            {form &&
                              lang &&
                              lang.map((item, index) => (
                                <TabPane tabId={index.toString()} key={index}>
                                  <Row>
                                    <Col sm="12" lg="12">
                                      <div className="mt-4">
                                        <label
                                          htmlFor="productname"
                                          className="form-label"
                                        >
                                          Urun Adi
                                        </label>

                                        <input
                                          id="productname"
                                          name="productname"
                                          type="text"
                                          className="form-control"
                                          placeholder="Urun Adi"
                                          defaultValue={
                                            form[index]["title_" + item.code]
                                          }
                                          onChange={e =>
                                            setFormValueByIndex(
                                              index,
                                              "title_" + item.code,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                    </Col>
                                    <Col sm="12" lg="12">
                                      <div className="mt-4">
                                        <label
                                          htmlFor="productTable"
                                          className="form-label"
                                        >
                                          Urun Icerigi
                                        </label>
                                        <CKEditor
                                          editor={Editor}
                                          data={
                                            form[index]["content_" + item.code]
                                          }
                                          onChange={(event, editor) => {
                                            const data = editor.getData()
                                            setFormValueByIndex(
                                              index,
                                              "content_" + item.code,
                                              data
                                            )
                                          }}
                                        />
                                      </div>
                                    </Col>
                                    <Col sm="12">
                                      <div className="mt-4">
                                        <label
                                          htmlFor="productTable"
                                          className="form-label"
                                        >
                                          Urun Tablo
                                        </label>
                                        <CKEditor
                                          editor={Editor}
                                          data={
                                            form[index]["detail_" + item.code]
                                          }
                                          onChange={(event, editor) => {
                                            const data = editor.getData()
                                            console.log(data)
                                            setFormValueByIndex(
                                              index,
                                              "detail_" + item.code,
                                              data
                                            )
                                          }}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </TabPane>
                              ))}
                          </TabContent>
                        </div>
                        {/*submit button right*/}
                        <div className="col-lg-12">
                          <div className="mt-4 d-flex justify-content-end">
                            <button
                              type="submit"
                              className="btn btn-primary w-md  mb-2"
                            >
                              Duzenle
                            </button>
                            <button
                              type="button"
                              className="btn btn-danger w-md mx-3 mb-2"
                              onClick={() => {
                                //rout Edit product
                                Navigate("/products")
                              }}
                            >
                              Geri
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      )}
    </React.Fragment>
  )
}

export default MainPage
