import React, { useEffect } from "react"
import {
  Container,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import { useNavigate, useParams } from "react-router-dom"

import QRCode from "react-qr-code"

import classnames from "classnames"

import axios from "lib/axios"

// Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import { toast } from "react-toastify"

import { CKEditor } from "@ckeditor/ckeditor5-react"
import { Editor } from "ckeditor5-custom-build/build/ckeditor"

const QRAdd = () => {
  const Navigate = useNavigate()

  const params = useParams()

  const [active, setActive] = React.useState(true)

  const [form, setForm] = React.useState()

  const [name, setName] = React.useState("")

  const [qr, setQr] = React.useState([])

  const [title, setTitle] = React.useState("")
  const [value, setValue] = React.useState("")

  document.title = "Safkar Panel - QR Ekle"

  const [customActiveTab, setcustomActiveTab] = React.useState("0")

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  useEffect(() => {
    axios
      .get("/qr?id=" + params.id)
      .then(response => {
        setQr(response.data)
        setTitle(response.data.title)
        setValue(response.data.value)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const isFaqForm = obj => {
    return true
  }

  const setFormValueByIndex = (index, key, value) => {
    const newForm = [...form]
    if (isFaqForm(newForm[index])) {
      newForm[index][key] = value.toString()
    }
    setForm(newForm)
  }

  const getFormValueByIndex = (index, key) => {
    const newForm = [...form]
    if (isFaqForm(newForm[index])) {
      if (newForm[index]) {
        return newForm[index][key]
      } else {
        return ""
      }
    }
    return ""
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="QR" breadcrumbItem="QR Ekle" />

          {/* Render Form */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form
                    onSubmit={e => {
                      e.preventDefault()
                      axios
                        .put("/qr", {
                          id: params.id,
                          title: title,
                          value: value,
                          level: "L",
                          size: 256,
                        })
                        .then(response => {
                          if (response.status == 200) {
                            toast.success("QR Eklendi")
                          }
                        })
                        .catch(error => {
                          console.log(error)
                          toast("QR Eklenemedi")
                        })
                    }}
                  >
                    <h4 className="card-title">QR Ekle</h4>
                    <p className="card-title-desc">QR Ekle</p>
                    <button
                      type="button"
                      className="btn btn-danger w-md mx-3 mb-2"
                      style={{
                        position: "absolute",
                        right: "0",
                        top: "20px",
                      }}
                      onClick={() => {
                        Navigate("/qr")
                      }}
                    >
                      Geri
                    </button>
                    <div className="row">
                      <div className="col-lg-6">
                        {/*category name*/}
                        <div className="mb-3">
                          <label htmlFor="categoryname" className="form-label">
                            Adi
                          </label>
                          <input
                            id="categoryname"
                            name="categoryname"
                            type="text"
                            className="form-control"
                            defaultValue={title}
                            placeholder="QR Adı"
                            onChange={e => {
                              setTitle(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        {/*category name*/}
                        <div className="mb-3">
                          <label htmlFor="categoryname" className="form-label">
                            Link
                          </label>
                          <input
                            id="categoryname"
                            name="categoryname"
                            type="text"
                            className="form-control"
                            defaultValue={value}
                            placeholder="QR Link"
                            onChange={e => {
                              setValue(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="mt-4 d-flex justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-primary w-md  mb-2"
                          >
                            Ekle
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger w-md mx-3 mb-2"
                            onClick={() => {
                              Navigate("/qr")
                            }}
                          >
                            Geri
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default QRAdd
