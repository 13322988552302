import React from "react"
import {
  Container,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import { useNavigate } from "react-router-dom"

import classnames from "classnames"

import axios from "lib/axios"

// Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import { toast } from "react-toastify"

import { CKEditor } from "@ckeditor/ckeditor5-react"
import { Editor } from "ckeditor5-custom-build/build/ckeditor"

const TeamAdd = () => {
  const Navigate = useNavigate()

  const [active, setActive] = React.useState(true)

  const [form, setForm] = React.useState()

  const [name, setName] = React.useState("")

  document.title = "Safkar Panel - Ekip Üyesi Ekle"

  const [customActiveTab, setcustomActiveTab] = React.useState("0")

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  const [lang, setLang] = React.useState()

  React.useEffect(() => {
    axios
      .get("/language")
      .then(responseLang => {
        if (responseLang.data.languages) {
          setLang(responseLang.data.languages)

          const newForm = responseLang.data.languages.map(language => {
            return {
              ["tag_" + language.code]: "",
            }
          })

          setForm(newForm)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const isFaqForm = obj => {
    return true
  }

  const setFormValueByIndex = (index, key, value) => {
    const newForm = [...form]
    if (isFaqForm(newForm[index])) {
      newForm[index][key] = value.toString()
    }
    setForm(newForm)
  }

  const getFormValueByIndex = (index, key) => {
    const newForm = [...form]
    if (isFaqForm(newForm[index])) {
      if (newForm[index]) {
        return newForm[index][key]
      } else {
        return ""
      }
    }
    return ""
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Ekip" breadcrumbItem="Ekip Üyesi Ekle" />

          {/* Render Form */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form
                    onSubmit={e => {
                      e.preventDefault()
                      var arr = {}
                      form.forEach(item => {
                        Object.keys(item).forEach(key => {
                          arr[key] = item[key]
                        })
                      })
                      arr["name"] = name
                      arr["active"] = active
                      axios
                        .post("/team", arr)
                        .then(response => {
                          if (response.status == 200) {
                            toast.success("Ekip Üyesi Eklendi")
                          }
                          Navigate("/team")
                        })
                        .catch(error => {
                          console.log(error)
                          toast("Ekip Üyesi Eklenemedi")
                        })
                    }}
                  >
                    <h4 className="card-title">Ekip Üyesi Ekle</h4>
                    <p className="card-title-desc">Ekip Üyesi Ekle</p>
                    <button
                      type="button"
                      className="btn btn-danger w-md mx-3 mb-2"
                      style={{
                        position: "absolute",
                        right: "0",
                        top: "20px",
                      }}
                      onClick={() => {
                        Navigate("/team")
                      }}
                    >
                      Geri
                    </button>
                    <div className="row">
                      <div className="col-lg-6">
                        {/*category name*/}
                        <div className="mb-3">
                          <label htmlFor="categoryname" className="form-label">
                            Ekip Üyesi Adı
                          </label>
                          <input
                            id="categoryname"
                            name="categoryname"
                            type="text"
                            className="form-control"
                            placeholder="Ekip Üyesi Adı"
                            onChange={e => {
                              setName(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        {/*category name*/}
                        <div className="mb-3">
                          <label htmlFor="categoryname" className="form-label">
                            Aktiflik
                          </label>
                          <div className="form-check form-switch form-switch-lg mb-3">
                            {active != null && (
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwitchsizelg"
                                defaultChecked={active ? true : false}
                                onChange={e => {
                                  setActive(e.target.checked)
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <Nav tabs className="nav-tabs-custom nav-justified">
                          {lang &&
                            lang.map((item, index) => (
                              <NavItem key={index}>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active:
                                      customActiveTab === index.toString(),
                                  })}
                                  onClick={() => {
                                    toggleCustom(index.toString())
                                  }}
                                >
                                  <span className="d-block d-sm-none">
                                    {item.displayname}
                                  </span>
                                  <span className="d-none d-sm-block">
                                    {item.displayname}
                                  </span>
                                </NavLink>
                              </NavItem>
                            ))}
                        </Nav>

                        <TabContent
                          activeTab={customActiveTab}
                          className="p-3 text-muted"
                        >
                          {form &&
                            lang &&
                            lang.map((item, index) => (
                              <TabPane tabId={index.toString()} key={index}>
                                <Row>
                                  <Col sm="12" lg="12">
                                    <div className="mt-4">
                                      <label
                                        htmlFor="faqContent"
                                        className="form-label"
                                      >
                                        Ekip Üyesi Ünvanı
                                      </label>

                                      <input
                                        id="faqContent"
                                        name="faqContent"
                                        type="text"
                                        className="form-control"
                                        placeholder="Ekip Üyesi Ünvanı"
                                        onChange={e =>
                                          setFormValueByIndex(
                                            index,
                                            "tag_" + item.code,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </TabPane>
                            ))}
                        </TabContent>
                      </div>
                      <div className="col-lg-12">
                        <div className="mt-4 d-flex justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-primary w-md  mb-2"
                          >
                            Ekle
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger w-md mx-3 mb-2"
                            onClick={() => {
                              Navigate("/team")
                            }}
                          >
                            Geri
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TeamAdd
