import { ActionIcon, Group, Modal, Select, Text } from "@mantine/core"
import { IconEdit, IconTrash } from "@tabler/icons-react"
import classNames from "classnames"
import DataTableMantine from "components/Added/datatable"
import DeleteModal from "components/Common/DeleteModal"
import axios from "lib/axios"
import React from "react"
import { useState } from "react"
import { useEffect } from "react"
import { toast } from "react-toastify"
import {
  Nav,
  NavItem,
  TabContent,
  TabPane,
  NavLink,
  Container,
} from "reactstrap"
import { useDisclosure } from "@mantine/hooks"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useNavigate } from "react-router-dom"

const Index = () => {
  const [country, setCountry] = useState([])
  const [order, setOrder] = React.useState(null)
  const [deleteModal, setDeleteModal] = React.useState(false)
  const [opened, { open, close }] = useDisclosure(false)
  const [roles, setRoles] = useState([])

  const Navigate = useNavigate()
  document.title = "Safkar Panel - Kullanıcılar"

  const [userFirstname, setUserFirstname] = useState("")
  const [userLastname, setUserLastname] = useState("")
  const [userMail, setUserMail] = useState("")
  const [Password, setPassword] = useState("")
  const [username, setUsername] = useState("")
  const [userPhone, setUserPhone] = useState("")
  const [userStatus, setUserStatus] = useState(false)
  const [userRole, setUserRole] = useState("")

  useEffect(() => {
    axios.get("/users").then(response => {
      setCountry(response.data.data)
    })

    axios.get("/getRoles").then(response => {
      setRoles(response.data)
    })
  }, [])

  const onClickDelete = order => {
    setOrder(order)
    setDeleteModal(true)
  }

  const handleDeleteOrder = () => {
    if (order && order.id) {
      axios
        .delete("/users/" + order.id)
        .then(async res => {
          toast.success("Kullanıcı başarıyla silindi.")
          setDeleteModal(false)
          await axios.get("/users").then(response => {
            setCountry(response.data.data)
          })
        })
        .catch(err => {
          setDeleteModal(false)
          toast(`Bir hata oluştu.`, { type: "error" })
        })
    }
  }

  const handleSubmit = () => {
    if (
      userFirstname &&
      userLastname &&
      userMail &&
      Password &&
      username &&
      userPhone &&
      userRole
    ) {
      axios
        .post("/users", {
          userFirstname,
          userLastname,
          userMail,
          Password,
          username,
          userPhone,
          userStatus,
          userRole,
        })
        .then(res => {
          toast.success("Kullanıcı başarıyla eklendi.")
          close()
          setUserFirstname("")
          setUserLastname("")
          setUserMail("")
          setPassword("")
          setUsername("")
          setUserPhone("")
          setUserStatus(false)
          axios.get("/users").then(response => {
            setCountry(response.data.data)
          })
        })
        .catch(err => {
          toast("Bir hata oluştu.", { type: "error" })
        })
    } else {
      toast("Lütfen tüm alanları doldurunuz.", { type: "error" })
    }
  }

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => {
          close()
        }}
        size="55rem"
        title={"Kullanıcı Ekle"}
        centered
      >
        <div className="mt-2">
          <div>
            <label htmlFor="categoryname" className="form-label">
              Kişi Adı
            </label>
            <input
              id="categoryname"
              name="categoryname"
              type="text"
              className="form-control"
              value={userFirstname}
              onChange={e => setUserFirstname(e.target.value)}
            />
          </div>
          <div className="mt-2">
            <label htmlFor="categoryname" className="form-label">
              Kişi Soyadı
            </label>
            <input
              id="categoryname"
              name="categoryname"
              type="text"
              className="form-control"
              value={userLastname}
              onChange={e => setUserLastname(e.target.value)}
            />
          </div>
          <div className="mt-2">
            <label htmlFor="categoryname" className="form-label">
              Kişi Telefon Numarası
            </label>
            <input
              id="categoryname"
              name="categoryname"
              type="text"
              className="form-control"
              value={userPhone}
              onChange={e => setUserPhone(e.target.value)}
            />
          </div>
          <div className="mt-2">
            <label htmlFor="categoryname" className="form-label">
              Kişi Email Adresi
            </label>
            <input
              id="categoryname"
              name="categoryname"
              type="text"
              className="form-control"
              value={userMail}
              onChange={e => setUserMail(e.target.value)}
            />
          </div>
          <div className="mt-2">
            <label htmlFor="categoryname" className="form-label">
              Kullanıcı Adı
            </label>
            <input
              id="categoryname"
              name="categoryname"
              type="text"
              className="form-control"
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
          </div>
          <div className="mt-2">
            <label htmlFor="categoryname" className="form-label">
              Kullanıcı Şifresi
            </label>
            <input
              id="categoryname"
              name="categoryname"
              type="text"
              className="form-control"
              value={Password}
              onChange={e => setPassword(e.target.value)}
            />
          </div>
          {roles && (
            <div className="mt-2">
              <Select
                label="Kullanıcı Rolü"
                data={roles.map(item => {
                  return { value: item.id, label: item.name }
                })}
                onChange={e => {
                  setUserRole(e)
                }}
              />
            </div>
          )}
          <div>
            <div className="mt-3 mb-3">
              <label htmlFor="categoryname" className="form-label">
                Aktiflik
              </label>
              <div className="form-check form-switch form-switch-lg mb-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizelg"
                  defaultChecked={userStatus}
                  onChange={e => {
                    setUserStatus(e.target.checked)
                  }}
                />
              </div>
            </div>
          </div>
          <button onClick={handleSubmit} className="btn btn-primary w-md mt-4">
            Ekle
          </button>
        </div>
      </Modal>
      {country.length > 0 && (
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Kullanıcı" breadcrumbItem="Kullanıcılar" />
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <button
                type="button"
                className="btn btn-info w-md mx-3 mb-2"
                onClick={() => {
                  open()
                }}
              >
                Ekle
              </button>
            </div>
            {order && (
              <DeleteModal
                show={deleteModal}
                text={
                  order.userFirstname +
                  " Kullanıcısını silmek istediğinize emin misiniz?"
                }
                onDeleteClick={handleDeleteOrder}
                onCloseClick={() => setDeleteModal(false)}
              />
            )}
            <DataTableMantine
              data={country}
              filterField={"userFirstname"}
              columns={[
                {
                  name: "Kişi Adı",
                  selector: row => row.userFirstname,
                  cell: item => (
                    <Text>{item.userFirstname + " " + item.userLastname}</Text>
                  ), // Cell property for rendering
                },
                {
                  name: <Text mr="xs">İşlemler</Text>,
                  width: 110,
                  textAlign: "center",
                  cell: item => (
                    <Group spacing={4} position="center" noWrap>
                      <ActionIcon
                        color="blue"
                        onClick={() => {
                          Navigate("/users/edit/" + item.id)
                        }}
                      >
                        <IconEdit size={16} />
                      </ActionIcon>
                      <ActionIcon
                        color="red"
                        onClick={() => onClickDelete(item)}
                      >
                        <IconTrash size={16} />
                      </ActionIcon>
                    </Group>
                  ),
                  ignoreRowClick: true,
                  allowOverflow: true,
                  button: true,
                },
              ]}
            />
          </Container>
        </div>
      )}
    </>
  )
}

export default Index
