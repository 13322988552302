import React from "react"
import { Container } from "reactstrap"

import { useParams, useNavigate } from "react-router-dom"

import axios, { API_URL } from "lib/axios"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import { toast } from "react-toastify"
import DataTableMantine from "components/Added/datatable"
import { ActionIcon, Group, Text } from "@mantine/core"
import { IconEdit, IconTrash } from "@tabler/icons-react"
import DeleteModal from "components/Common/DeleteModal"

const Index = () => {
  const Navigate = useNavigate()
  const [data, setData] = React.useState()
  const [deleteModal, setdeleteModal] = React.useState(false)
  const [order, setOrder] = React.useState()
  //meta title
  document.title = "Safkar Panel - İletişim Bilgileri"

  React.useEffect(() => {
    axios.get("/contact").then(res => {
      setData(res.data)
    })
  }, [])

  /*

    name       String?
  email      String?
  phone      String?
  province   String?
  select     String?
  button     String?
  language   language @relation(fields: [languageId], references: [id], onDelete: Cascade)

*/

  const handleDeleteOrder = () => {
    axios
      .delete("/contact", { data: { id: order.id } })
      .then(res => {
        toast.success("İletişim Bilgisi Silindi")
        setdeleteModal(false)
        axios.get("/contact").then(res => {
          setData(res.data)
        })
      })
      .catch(err => {
        setdeleteModal(false)
        toast.error("İletişim Bilgisi Silinemedi")
      })
  }

  const onClickDelete = order => {
    setOrder(order)
    setdeleteModal(true)
  }

  return (
    <React.Fragment>
      {order && (
        <DeleteModal
          show={deleteModal}
          text={
            order.name + " iletişim bilgisini silmek istediğinize emin misiniz?"
          }
          onDeleteClick={handleDeleteOrder}
          onCloseClick={() => setdeleteModal(false)}
        />
      )}
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="İletişim Bilgisi"
            breadcrumbItem="İletişim Bilgileri"
          />
          <Group position="right" spacing={4} mt="md">
            <button
              className="btn btn-primary mb-2"
              onClick={() => {
                //rout Add category
                Navigate("/contact/add")
              }}
            >
              Kontak Ekle
            </button>
          </Group>
          {/* Render Form */}
          <div className="row">
            <DataTableMantine
              filterField={"name"}
              columns={[
                {
                  name: "ID",
                  selector: row => row.id,
                  sortable: true,
                  width: 50,
                  textAlign: "center", // Removed duplicate textAlignment and kept "Center" as the value
                },
                {
                  name: "İletişim Adı",
                  selector: row => row.name,
                  sortable: true,
                },
                {
                  name: <Text mr="xs">Islemler</Text>,
                  width: 110,
                  textAlign: "center",
                  cell: item => (
                    <Group spacing={4} position="center" noWrap>
                      <ActionIcon
                        color="blue"
                        onClick={() => Navigate("/contact/edit/" + item.id)}
                      >
                        <IconEdit size={16} />
                      </ActionIcon>
                      <ActionIcon
                        color="red"
                        onClick={() => onClickDelete(item)}
                      >
                        <IconTrash size={16} />
                      </ActionIcon>
                    </Group>
                  ),
                  ignoreRowClick: true,
                  allowOverflow: true,
                  button: true,
                },
              ]}
              data={data}
              // Expansion={({ record }) => {
              //   console.log(record.subcategory)
              //   if (record.subcategory.length > 0) {
              //     return (
              //       <div className="p-2" style={arkaplan}>
              //         <DataTableMantine
              //           columns={[
              //             {
              //               accessor: "id",
              //               // this column has a custom title
              //               title: "ID",
              //               // right-align column
              //               textAlignment: "right",
              //               width: 50,
              //               sortable: true,
              //               textAlignment: "Center",
              //             },
              //             {
              //               accessor: "subcategorylang[0].title",
              //               title: "Alt Kategori Adı",
              //               sortable: true,
              //             },
              //             {
              //               accessor: "actions",
              //               title: <Text mr="xs">Islemler</Text>,
              //               width: 100,
              //               textAlignment: "Center",
              //               render: subitem => (
              //                 <Group spacing={4} position="center" noWrap>
              //                   <ActionIcon
              //                     color="blue"
              //                     onClick={() => {
              //                       //rout Edit category
              //                       alert("alt kategori")
              //                       Navigate("/category/edit/" + subitem.id)
              //                     }}
              //                   >
              //                     <IconEdit size={16} />
              //                   </ActionIcon>
              //                   <ActionIcon
              //                     color="red"
              //                     onClick={() => onClickDelete(subitem)}
              //                   >
              //                     <IconTrash size={16} />
              //                   </ActionIcon>
              //                 </Group>
              //               ),
              //             },
              //           ]}
              //           data={record.subcategory}
              //         />
              //       </div>
              //     )
              //   } else {
              //     return (
              //       <>
              //         <Text className="p-2" onClick={() => alert("dne")}>
              //           {" "}
              //           {record.categorylang[0].title} Kategorisini alt
              //           kategorisi bulunmamaktadır.
              //         </Text>
              //         <Button
              //           className="p-2"
              //           onClick={() => {
              //             //rout Add category
              //             alert("alt kategori ekle")
              //           }}
              //         >
              //           Alt Kategori Ekle
              //         </Button>
              //       </>
              //     )
              //   }
              // }}
            />
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Index
