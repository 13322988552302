import React, { useEffect } from "react"
import {
  Container,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import { useNavigate, useParams } from "react-router-dom"

import QRCode from "react-qr-code"

import classnames from "classnames"

import axios, { API_URL } from "lib/axios"

// Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import { toast } from "react-toastify"

import { CKEditor } from "@ckeditor/ckeditor5-react"
import { Editor } from "ckeditor5-custom-build/build/ckeditor"

const QRCardEdit = () => {
  const Navigate = useNavigate()

  const params = useParams()

  const [active, setActive] = React.useState(true)

  const [form, setForm] = React.useState()

  const [qr, setQr] = React.useState([])

  const [title, setTitle] = React.useState("")
  const [name, setName] = React.useState("")
  const [surname, setSurname] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [phone, setPhone] = React.useState("")
  const [photo, setPhoto] = React.useState("")

  const photoRef = React.useRef();

  document.title = "Safkar Panel - QR Kartı Düzenle"

  const [customActiveTab, setcustomActiveTab] = React.useState("0")

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  useEffect(() => {
    axios
      .get("/qrcard?id=" + params.id)
      .then(response => {
        setQr(response.data)
        setTitle(response.data.title)
        setName(response.data.name)
        setSurname(response.data.surname)
        setEmail(response.data.mail)
        setPhone(response.data.phone)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const isFaqForm = obj => {
    return true
  }

  const setFormValueByIndex = (index, key, value) => {
    const newForm = [...form]
    if (isFaqForm(newForm[index])) {
      newForm[index][key] = value.toString()
    }
    setForm(newForm)
  }

  const getFormValueByIndex = (index, key) => {
    const newForm = [...form]
    if (isFaqForm(newForm[index])) {
      if (newForm[index]) {
        return newForm[index][key]
      } else {
        return ""
      }
    }
    return ""
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="QR" breadcrumbItem="QR Ekle" />

          {/* Render Form */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form
                    onSubmit={async e => {
                      e.preventDefault()
                      
                      if (photo) {
                              const formData = new FormData()
                              formData.append("images", photo)
                              console.log(formData)
                              console.log(photo)
                              await axios
                                .post("/qrcard/image", formData)
                                .then(response => {
                                  if (response.data) {
                                    axios
                        .put("/qrcard", {
                          id: params.id,
                          title: title,
                          name: name,
                          surname: surname,
                          mail: email,
                          phone: phone,
                          photo: response.data.qrimage[0].image,
                        })
                        .then(response => {
                          if (response.status == 200) {
                            toast.success("QR Düzenlendi")
                            window.location.reload()
                          }
                        })
                        .catch(error => {
                          console.log(error)
                          toast("QR Düzenlenemedi")
                        })
                        }
                      }) 
                      }
                      else {
                      axios
                        .put("/qrcard", {
                          id: params.id,
                          title: title,
                          name: name,
                          surname: surname,
                          mail: email,
                          phone: phone,
                          photo: qr.photo,
                        })
                        .then(response => {
                          if (response.status == 200) {
                            toast.success("QR Düzenlendi")
                          }
                        })
                        .catch(error => {
                          console.log(error)
                          toast("QR Düzenlenemedi")
                        })
                    }
                    }
                  }
                  >
                    <h4 className="card-title">QR Kart Düzenle</h4>
                    <p className="card-title-desc">QR Kart Düzenle</p>
                    <button
                      type="button"
                      className="btn btn-danger w-md mx-3 mb-2"
                      style={{
                        position: "absolute",
                        right: "0",
                        top: "20px",
                      }}
                      onClick={() => {
                        Navigate("/qrcard")
                      }}
                    >
                      Geri
                    </button>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-3">
                          {qr.photo && (
                            <img
                              src={API_URL + "/api/image?image=" + qr.photo + "&path=qrcard"}
                              style={{ width: "100px" }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        {/*category name*/}
                        <div className="mb-3">
                          <label htmlFor="categoryname" className="form-label">
                            Ad
                          </label>
                          <input
                            id="categoryname"
                            name="categoryname"
                            type="text"
                            className="form-control"
                            defaultValue={qr.name}
                            placeholder="Ad"
                            onChange={e => {
                              setName(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="categoryname" className="form-label">
                            Soyad
                          </label>
                          <input
                            id="categoryname"
                            name="categoryname"
                            type="text"
                            className="form-control"
                            defaultValue={qr.surname}
                            placeholder="Soyad"
                            onChange={e => {
                              setSurname(e.target.value)
                            }}
                          />
                        </div>
                    </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="categoryname" className="form-label">
                            Email
                          </label>
                          <input
                            id="categoryname"
                            name="categoryname"
                            type="text"
                            className="form-control"
                            defaultValue={qr.mail}
                            placeholder="Email"
                            onChange={e => {
                              setEmail(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="categoryname" className="form-label">
                            Telefon
                          </label>
                          <input
                            id="categoryname"
                            name="categoryname"
                            type="text"
                            className="form-control"
                            defaultValue={qr.phone}
                            placeholder="Telefon"
                            onChange={e => {
                              setPhone(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="categoryname" className="form-label">
                            Mevki
                          </label>
                          <input
                            id="categoryname"
                            name="categoryname"
                            type="text"
                            className="form-control"
                            defaultValue={qr.title}
                            placeholder="Mevki"
                            onChange={e => {
                              setTitle(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="categoryname" className="form-label">
                            Fotoğraf
                          </label>
                          <input
                            id="categoryname"
                            name="categoryname"
                            type="file"
                            className="form-control"
                            ref={photoRef}
                            onChange={e => {
                              setPhoto(e.target.files[0])
                            }}
                          />
                        </div>
                        <div className="mb-3">
                        {
                          photo && (
                            <img
                              src={URL.createObjectURL(photo)}
                              style={{ width: "100px" }}
                            />
                          )
                        }
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="mt-4 d-flex justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-primary w-md  mb-2"
                          >
                            Düzenle
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger w-md mx-3 mb-2"
                            onClick={() => {
                              Navigate("/qr")
                            }}
                          >
                            Geri
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default QRCardEdit
