import React from "react"
import {
  Container,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import { useNavigate } from "react-router-dom"

import classnames from "classnames"

import axios from "lib/axios"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// Import Components

import { toast } from "react-toastify"

import MultiImageInput from "react-multiple-image-input"

import { CKEditor } from "@ckeditor/ckeditor5-react"
import { Editor } from "ckeditor5-custom-build/build/ckeditor"
import { MultiSelect } from "@mantine/core"

const BlogAdd = () => {
  const Navigate = useNavigate()
  // all variables
  const [file, setFile] = React.useState()
  const [active, setActive] = React.useState(true)
  const [images, setImages] = React.useState({})
  const [startingDate, setStartingDate] = React.useState(
    new Date().toISOString().split("T")[0]
  )

  const [form, setForm] = React.useState()

  //meta title
  document.title = "Safkar Panel - Blog Ekle"

  const [customActiveTab, setcustomActiveTab] = React.useState("0")

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  const [lang, setLang] = React.useState()

  function base64ToFile(base64, fileName) {
    const arr = base64.split(",")
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], fileName, { type: mime })
  }

  React.useEffect(() => {
    axios
      .get("/language")
      .then(responseLang => {
        if (responseLang.data.languages) {
          setLang(responseLang.data.languages)

          const newForm = responseLang.data.languages.map(language => {
            return {
              ["title_" + language.code]: "",
              ["content_" + language.code]: "",
              ["subtitle_" + language.code]: "",
              ["active_" + language.code]: "1",
              ["tags_" + language.code]: [],
              active: "1",
            }
          })

          setForm(newForm)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const isSliderForm = obj => {
    // Gerekli özelliklerin varlığını kontrol ederek
    // obj'nin SliderForm tipinde olduğunu doğrulayabilirsiniz
    // Örnek olarak: return 'property1' in obj && 'property2' in obj;
    return true
  }

  const setFormValueByIndex = (index, key, value) => {
    const newForm = [...form]
    if (isSliderForm(newForm[index])) {
      newForm[index][key] = value.toString()
    }
    setForm(newForm)
  }

  const getFormValueByIndex = (index, key) => {
    const newForm = [...form]
    if (isSliderForm(newForm[index])) {
      //if newform has key
      if (newForm[index]) {
        return newForm[index][key]
      } else {
        return ""
      }
    }
    return ""
  }

  const setArrayValueByIndex = (index, key, value) => {
    const newForm = [...form]
    if (isSliderForm(newForm[index])) {
      newForm[index][key] = value
    }
    setForm(newForm)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Blog" breadcrumbItem="Blog Ekle" />

          {/* Render Form */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form
                    onSubmit={e => {
                      e.preventDefault()
                      var arr = {}
                      form.forEach(item => {
                        Object.keys(item).forEach(key => {
                          arr[key] = item[key]
                        })
                      })
                      arr["active"] = active
                      arr["createdAt"] = startingDate

                      if (file) {
                        if (
                          file.type != "image/png" &&
                          file.type != "image/jpeg" &&
                          file.type != "image/jpg"
                        ) {
                          toast.error("Resim PNG, JPEG veya JPG olmalidir")
                          return
                        }
                      }

                      axios
                        .post("/blog", arr)
                        .then(response => {
                          if (response.status == 200) {
                            toast.success("Blog Eklendi")
                          }
                          if (file) {
                            var formData = new FormData()
                            formData.append("images", file)
                            formData.append("blogId", response.data.blog.id)
                            axios
                              .post("/blog/image", formData)
                              .then(response => {})
                          }

                          if (images) {
                            var galleryData = new FormData()

                            Object.keys(images).forEach(key => {
                              var image = images[key]
                              const blob = base64ToFile(image, "image.jpg")
                              galleryData.append("images", blob)
                            })
                            galleryData.append("blogId", response.data.blog.id)

                            axios
                              .post("/blog/gallery/images", galleryData)
                              .then(response => {})
                          }
                          Navigate("/blog")
                        })
                        .catch(error => {
                          console.log(error)
                          toast("Blog Eklenemedi")
                        })
                    }}
                  >
                    <h4 className="card-title">Blog Ekle</h4>
                    <p className="card-title-desc">Blog Ekle</p>
                    {/* back button */}
                    <button
                      type="button"
                      className="btn btn-danger w-md mx-3 mb-2"
                      style={{
                        position: "absolute",
                        right: "0",
                        top: "20px",
                      }}
                      onClick={() => {
                        //rout Edit slider
                        Navigate("/blog")
                      }}
                    >
                      Geri
                    </button>
                    <div className="row">
                      <div className="col-lg-4">
                        {/* two colum*/}
                        {/*slider name*/}
                        <div className="mb-3">
                          <label htmlFor="slidername" className="form-label">
                            Blog Resmi
                          </label>
                          <input
                            id="slidername"
                            name="slidername"
                            type="file"
                            accept=".png, .jpeg, .jpg"
                            className="form-control"
                            onChange={e => {
                              setFile(e.target.files[0])
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        {/* two colum*/}
                        {/*slider name*/}
                        <div className="mb-3">
                          <label htmlFor="slidername" className="form-label">
                            Blog Tarihi
                          </label>
                          <input
                            className="form-control"
                            type="date"
                            defaultValue={startingDate.toString()}
                            id="example-date-input"
                            onChange={e => {
                              setStartingDate(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        {/*slider name*/}
                        <div className="mb-3">
                          <label htmlFor="slidername" className="form-label">
                            Aktiflik
                          </label>
                          <div className="form-check form-switch form-switch-lg mb-3">
                            {active != null && (
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwitchsizelg"
                                defaultChecked={active ? true : false}
                                onChange={e => {
                                  setActive(e.target.checked)
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        {/* two colum*/}
                        {/*slider name*/}
                        <div className="mb-3">
                          <label htmlFor="slidername" className="form-label">
                            Blog Galerisi
                          </label>
                          <MultiImageInput
                            images={images}
                            setImages={setImages}
                            allowCrop={false}
                            theme={"light"}
                            max={10}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <Nav tabs className="nav-tabs-custom nav-justified">
                          {lang &&
                            lang.map((item, index) => (
                              <NavItem key={index}>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active:
                                      customActiveTab === index.toString(),
                                  })}
                                  onClick={() => {
                                    toggleCustom(index.toString())
                                  }}
                                >
                                  <span className="d-block d-sm-none">
                                    {item.displayname}
                                  </span>
                                  <span className="d-none d-sm-block">
                                    {item.displayname}
                                  </span>
                                </NavLink>
                              </NavItem>
                            ))}
                        </Nav>

                        <TabContent
                          activeTab={customActiveTab}
                          className="p-3 text-muted"
                        >
                          {form &&
                            lang &&
                            lang.map((item, index) => (
                              <TabPane tabId={index.toString()} key={index}>
                                <Row>
                                  <Col sm="12" lg="5">
                                    <div className="mt-4">
                                      <label
                                        htmlFor="sliderTitle"
                                        className="form-label"
                                      >
                                        Blog Başlığı
                                      </label>

                                      <input
                                        id="sliderTitle"
                                        name="sliderTitle"
                                        type="text"
                                        className="form-control"
                                        placeholder="Blog Başlığı"
                                        onChange={e =>
                                          setFormValueByIndex(
                                            index,
                                            "title_" + item.code,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </Col>
                                  <Col sm="12" lg="5">
                                    <div className="mt-4">
                                      <label
                                        htmlFor="sliderContent"
                                        className="form-label"
                                      >
                                        Blog Etiketleri
                                      </label>
                                      {getFormValueByIndex(
                                        index,
                                        "tags_" + item.code
                                      ) && (
                                        <MultiSelect
                                          data={getFormValueByIndex(
                                            index,
                                            "tags_" + item.code
                                          )}
                                          defaultValue={getFormValueByIndex(
                                            index,
                                            "tags_" + item.code
                                          )}
                                          placeholder="Etiket Öğelerini Seçiniz"
                                          searchable
                                          creatable
                                          getCreateLabel={query =>
                                            `Oluştur: ${query}`
                                          }
                                          onCreate={query => {
                                            const elem = query
                                            var arr = getFormValueByIndex(
                                              index,
                                              "tags_" + item.code
                                            )
                                            arr.push(elem)
                                            setArrayValueByIndex(
                                              index,
                                              "tags_" + item.code,
                                              arr
                                            )
                                            return elem
                                          }}
                                        />
                                      )}
                                    </div>
                                  </Col>
                                  <Col sm="12" lg="2">
                                    <div className="mt-4">
                                      <label
                                        htmlFor="sliderLink"
                                        className="form-label"
                                      >
                                        Blog Aktifliği
                                      </label>
                                      <div className="form-check form-switch form-switch-lg mb-3">
                                        {getFormValueByIndex(
                                          index,
                                          "active_" + item.code
                                        ) != null && (
                                          <input
                                            defaultChecked={getFormValueByIndex(
                                              index,
                                              "active_" + item.code
                                            )}
                                            type="checkbox"
                                            className="form-check-input"
                                            onChange={e => {
                                              setFormValueByIndex(
                                                index,
                                                "active_" + item.code,
                                                Boolean(e.target.checked)
                                              )
                                            }}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                  <Col sm="12" lg="12">
                                    <div className="mt-4">
                                      <label
                                        htmlFor="sliderSubtitle"
                                        className="form-label"
                                      >
                                        Blog İçeriği
                                      </label>

                                      <CKEditor
                                        editor={Editor}
                                        onChange={(event, editor) => {
                                          const data = editor.getData()
                                          setFormValueByIndex(
                                            index,
                                            "content_" + item.code,
                                            data
                                          )
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </TabPane>
                            ))}
                        </TabContent>
                      </div>
                      {/*submit button right*/}
                      <div className="col-lg-12">
                        <div className="mt-4 d-flex justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-primary w-md  mb-2"
                          >
                            Ekle
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger w-md mx-3 mb-2"
                            onClick={() => {
                              //rout Edit slider
                              Navigate("/blog")
                            }}
                          >
                            Geri
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BlogAdd
