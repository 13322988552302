import React from "react"
import {
  Container,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import { useParams, useNavigate } from "react-router-dom"

import classnames from "classnames"

import axios, { API_URL } from "lib/axios"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// Import Components

import { CKEditor } from "@ckeditor/ckeditor5-react"
import { Editor } from "ckeditor5-custom-build/build/ckeditor"

import { toast } from "react-toastify"

const Index = () => {
  document.title = "Safkar Panel - Sosyal Paylaşım Düzenle"
  const navigate = useNavigate()
  const params = useParams()

  const [name, setName] = React.useState("")
  const [icon, setIcon] = React.useState("")
  const [url, setUrl] = React.useState("")
  const [active, setActive] = React.useState(true)

  React.useEffect(() => {
    axios.get("/social/" + params.id).then(response => {
      setName(response.data.name)
      setIcon(response.data.icon)
      setUrl(response.data.url)
      setActive(response.data.active)
    })
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Sosyal"
            breadcrumbItem="Sosyal Paylaşım Düzenle"
          />

          {/* Render Form */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form
                    onSubmit={e => {
                      e.preventDefault()
                      axios
                        .put("/social", {
                          name,
                          icon,
                          url,
                          active,
                          id: params.id,
                        })
                        .then(response => {
                          if (response.status == 200) {
                            toast.success("Güncelleme Başarılı")
                          }
                        })
                        .catch(error => {
                          console.log(error)
                        })
                    }}
                  >
                    <h4 className="card-title">Sosyal Duzenle</h4>
                    <p className="card-title-desc">Sosyal Duzenle</p>
                    {/* back button */}
                    <button
                      type="button"
                      className="btn btn-danger w-md mx-3 mb-2"
                      style={{
                        position: "absolute",
                        right: "0",
                        top: "20px",
                      }}
                      onClick={() => {
                        navigate("/social")
                      }}
                    >
                      Geri
                    </button>
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label htmlFor="simpleinput">Adı</label>
                        <input
                          type="text"
                          id="simpleinput"
                          className="form-control"
                          value={name && name}
                          onChange={e => {
                            setName(e.target.value)
                          }}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label htmlFor="simpleinput">İkonu</label>
                        <input
                          type="text"
                          id="simpleinput"
                          className="form-control"
                          value={icon && icon}
                          onChange={e => {
                            setIcon(e.target.value)
                          }}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label htmlFor="simpleinput">URL</label>
                        <input
                          type="text"
                          id="simpleinput"
                          className="form-control"
                          value={url && url}
                          onChange={e => {
                            setUrl(e.target.value)
                          }}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label htmlFor="simpleinput">Aktiflik</label>
                        <div className="form-check form-switch form-switch-lg">
                          {active != null && (
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customSwitchsizelg"
                              defaultChecked={active ? true : false}
                              onChange={e => {
                                setActive(e.target.checked)
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    {/*submit button right*/}
                    <div className="col-lg-12">
                      <div className="mt-4 d-flex justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-primary w-md  mb-2"
                        >
                          Duzenle
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger w-md mx-3 mb-2"
                          onClick={() => {
                            navigate("/social")
                          }}
                        >
                          Geri
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Index
