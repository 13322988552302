import React from "react"
import { Container } from "reactstrap"

import { Text, Group, ActionIcon } from "@mantine/core"
import { IconEdit, IconTrash } from "@tabler/icons-react"

import axios from "lib/axios"

import { useNavigate } from "react-router-dom"

// Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

// Import Components
import DataTableMantine from "components/Added/datatable"
import DeleteModal from "components/Common/DeleteModal"

import { toast } from "react-toastify"

const Certificates = () => {
  const Navigate = useNavigate()

  const [data, setData] = React.useState()

  React.useEffect(() => {
    axios
      .get("/certificate")
      .then(response => {
        if (response.data.certificate) {
          setData({ certificates: response.data.certificate })
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])
  //meta title
  document.title = "Safkar Panel - Sertifikalar"

  //delete certificate
  const [certificate, setCertificate] = React.useState(null)
  const [deleteModal, setDeleteModal] = React.useState(false)

  const onClickDelete = certificate => {
    setCertificate(certificate)
    setDeleteModal(true)
  }

  const handleDeleteCertificate = () => {
    if (certificate && certificate.id) {
      let arr = {}
      arr["id"] = certificate.id
      axios
        .delete("/certificate", {
          data: arr,
        })
        .then(res => {
          toast.success("Sertifika başarıyla silindi.")
          setDeleteModal(false)
          setData({
            certificates: data.certificates.filter(
              item => item.id !== certificate.id
            ),
          })
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  return (
    <React.Fragment>
      {certificate && (
        <DeleteModal
          show={deleteModal}
          text={
            certificate.certificatelang[0].title +
            " Adlı Sertifikayı silmek istediğinize emin misiniz?"
          }
          onDeleteClick={handleDeleteCertificate}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Panel" breadcrumbItem="Sertifikalar" />

          {/* Render Add Button */}
          <Group position="right" spacing={4} mt="md">
            <button
              className="btn btn-primary mb-2"
              onClick={() => {
                //rout Add Certificate
                Navigate("/certificate/add")
              }}
            >
              Sertifika Ekle
            </button>
          </Group>

          {/* Render DataTable */}
          {data && (
            <DataTableMantine
              filterField={"certificatelang[0].title"}
              columns={[
                {
                  name: "ID",
                  selector: row => row.id,
                  sortable: true,
                  width: "50px",
                  textAlign: "center", // Combined "right" and "Center" into one; you may need to adjust
                },
                {
                  name: "Sertifika Adı",
                  selector: row => row.certificatelang[0].title,
                  sortable: true,
                },
                {
                  name: <Text mr="xs">İşlemler</Text>,
                  width: "110px",
                  textAlign: "center",
                  cell: item => (
                    <Group spacing={4} position="center" noWrap>
                      <ActionIcon
                        color="blue"
                        onClick={() => Navigate("/certificate/edit/" + item.id)}
                      >
                        <IconEdit size={16} />
                      </ActionIcon>
                      <ActionIcon
                        color="red"
                        onClick={() => onClickDelete(item)}
                      >
                        <IconTrash size={16} />
                      </ActionIcon>
                    </Group>
                  ),
                  ignoreRowClick: true,
                  allowOverflow: true,
                  button: true,
                },
              ]}
              data={data.certificates}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Certificates
