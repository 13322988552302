import React from "react"
import {
  Container,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import { useParams, useNavigate } from "react-router-dom"

import classnames from "classnames"

import axios, { API_URL } from "lib/axios"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// Import Components

import { CKEditor } from "@ckeditor/ckeditor5-react"
import { Editor } from "ckeditor5-custom-build/build/ckeditor"

import { toast } from "react-toastify"

const Index = () => {
  document.title = "Safkar Panel - SMTP Ayarları Düzenle"
  const navigate = useNavigate()
  const [arr, setArr] = React.useState([])

  const [host, setHost] = React.useState("")
  const [port, setPort] = React.useState("")
  const [user, setUser] = React.useState("")
  const [pass, setPass] = React.useState("")

  React.useEffect(() => {
    axios.get("/smtp").then(res => {
      if (res.data && res.data != null) {
        setHost(res.data.host)
        setPort(res.data.port)
        setUser(res.data.user)
        setPass(res.data.password)
      }
    })
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="SMTP" breadcrumbItem="Mail Ayarları Düzenle" />

          {/* Render Form */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form
                    onSubmit={e => {
                      e.preventDefault()
                      axios
                        .put("/smtp", {
                          host: host,
                          port: port,
                          user: user,
                          password: pass,
                        })
                        .then(response => {
                          if (response.status == 200) {
                            toast.success("Güncelleme Başarılı")
                          }
                        })
                        .catch(error => {
                          console.log(error)
                        })
                    }}
                  >
                    <h4 className="card-title">SMTP Ayarlarını Düzenle</h4>
                    <p className="card-title-desc">SMTP Ayarlarını Düzenle</p>
                    {/* back button */}
                    <button
                      type="button"
                      className="btn btn-danger w-md mx-3 mb-2"
                      style={{
                        position: "absolute",
                        right: "0",
                        top: "20px",
                      }}
                      onClick={() => {
                        navigate("/main-page")
                      }}
                    >
                      Geri
                    </button>
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label htmlFor="simpleinput">Host</label>
                        <input
                          type="text"
                          id="simpleinput"
                          className="form-control"
                          value={host}
                          onChange={e => {
                            setHost(e.target.value)
                          }}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label htmlFor="simpleinput">Port</label>
                        <input
                          type="text"
                          id="simpleinput"
                          className="form-control"
                          value={port}
                          onChange={e => {
                            setPort(e.target.value)
                          }}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label htmlFor="simpleinput">Kullanıcı Adı</label>
                        <input
                          type="text"
                          id="simpleinput"
                          className="form-control"
                          value={user}
                          onChange={e => {
                            setUser(e.target.value)
                          }}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label htmlFor="simpleinput">Şifre</label>
                        <input
                          type="text"
                          id="simpleinput"
                          className="form-control"
                          value={pass}
                          onChange={e => {
                            setPass(e.target.value)
                          }}
                          required
                        />
                      </div>
                    </div>
                    {/*submit button right*/}
                    <div className="col-lg-12">
                      <div className="mt-4 d-flex justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-primary w-md  mb-2"
                        >
                          Güncelle
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger w-md mx-3 mb-2"
                          onClick={() => {
                            navigate("/main-page")
                          }}
                        >
                          Geri
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Index
