import React from "react"
import {
  Container,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Input,
} from "reactstrap"

import { useNavigate } from "react-router-dom"

import classnames from "classnames"

import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import { CKEditor } from "@ckeditor/ckeditor5-react"
import { Editor } from "ckeditor5-custom-build/build/ckeditor"

import axios, { API_URL } from "lib/axios"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { MultiSelect } from "@mantine/core"
import { get, isArray } from "lodash"
import { useEffect } from "react"

// Import Components

const MainPage = () => {
  const Navigate = useNavigate()
  // all variables
  const [file, setFile] = React.useState()
  const [form, setForm] = React.useState()
  const [result, setResult] = React.useState([])

  //meta title
  document.title = "Safkar Panel - Servis & Bakım Sayfasını Düzenle"

  const [customActiveTab, setcustomActiveTab] = React.useState("0")

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  const [lang, setLang] = React.useState()

  React.useEffect(() => {
    axios
      .get("/language")
      .then(responseLang => {
        if (responseLang.data.languages) {
          setLang(responseLang.data.languages)

          axios.get("/servicesales/page").then(response => {
            const { data } = response

            const newForm = responseLang.data.languages.map(
              (language, index) => {
                var row = data.find(item => item.languageId == language.id)
                if (row) {
                  setFile(row.image)
                  return {
                    ["sales_" + language.code]: row.sales,
                    ["salestext_" + language.code]: row.salestext,
                    ["salesicon_" + language.code]: row.salesicon,
                    ["service_" + language.code]: row.service,
                    ["servicetext_" + language.code]: row.servicetext,
                    ["serviceicon_" + language.code]: row.serviceicon,
                    ["area_" + language.code]: row.area,
                    ["areadefault_" + language.code]: row.areadefault,
                    ["country_" + language.code]: row.country,
                    ["countrydefault_" + language.code]: row.countrydefault,
                    ["name_" + language.code]: row.name,
                    ["manager_" + language.code]: row.manager,
                    ["email_" + language.code]: row.email,
                    ["phone_" + language.code]: row.phone,
                    ["languageId_" + language.code]: language.id,
                    ["id_" + language.code]: row.id,
                    ["title_" + language.code]: row.title,
                  }
                } else {
                  setFile(null)
                  return {
                    ["sales_" + language.code]: "",
                    ["salestext_" + language.code]: "",
                    ["salesicon_" + language.code]: "",
                    ["service_" + language.code]: "",
                    ["servicetext_" + language.code]: "",
                    ["serviceicon_" + language.code]: "",
                    ["area_" + language.code]: "Ülke Seçiniz",
                    ["areadefault_" + language.code]: "Ülke Seçiniz",
                    ["country_" + language.code]: "Bölge Seçiniz",
                    ["countrydefault_" + language.code]: "Bölge Seçiniz",
                    ["name_" + language.code]: "",
                    ["manager_" + language.code]: "",
                    ["email_" + language.code]: "",
                    ["phone_" + language.code]: "",
                    ["languageId_" + language.code]: language.id,
                    ["id_" + language.code]: 0,
                    ["title_" + language.code]: "",
                  }
                }
              }
            )
            setForm(newForm)
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const isCategoryForm = obj => {
    // Gerekli özelliklerin varlığını kontrol ederek
    // obj'nin CategoryForm tipinde olduğunu doğrulayabilirsiniz
    // Örnek olarak: return 'property1' in obj && 'property2' in obj;
    return true
  }

  const setFormValueByIndex = (index, key, value) => {
    const newForm = [...form]
    if (isCategoryForm(newForm[index])) {
      newForm[index][key] = value.toString()
    }
    setForm(newForm)
  }

  const getFormValueByIndex = (index, key) => {
    const newForm = [...form]
    if (isCategoryForm(newForm[index])) {
      //if newform has key
      if (newForm[index]) {
        return newForm[index][key]
      } else {
        return ""
      }
    }
    return ""
  }

  React.useEffect(() => {
    if (result && lang && result.length == lang.length && file) {
      setImage()
    }
  }, [result, lang, file])

  const setImage = async () => {
    if (typeof file === "object") {
      var formData = new FormData()
      formData.append("images", file)
      await axios.post("/servicesales/page/image", formData).then(response => {
        setFile(null)
      })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Servis & Bakım"
            breadcrumbItem=" Servis & Bakım Sayfasını Düzenle"
          />

          {/* Render Form */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <label
                    htmlFor="serviceSales"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>Sayfa Resmi</span>
                    {file && (
                      <img
                        src={
                          typeof file === "string"
                            ? `${API_URL}/api/image?image=${file.replaceAll(
                                " ",
                                "%20"
                              )}&path=servicesalespage`
                            : URL.createObjectURL(file)
                        }
                        alt="Service&Sales"
                        style={{ width: "20%" }}
                      />
                    )}
                  </label>
                  <input
                    id="serviceSalesImage"
                    name="serviceSales"
                    type="file"
                    accept=".png, .jpeg, .jpg"
                    className="form-control"
                    onChange={e => {
                      setFile(e.target.files[0])
                    }}
                  />
                  <form
                    onSubmit={e => {
                      e.preventDefault()
                      var arr = {}
                      var data = {}
                      var idarr
                      form.forEach(item => {
                        Object.keys(item).forEach(key => {
                          arr[key] = item[key]
                        })
                      })

                      lang.forEach(async item => {
                        data = {
                          name: arr["name_" + item.code],
                          sales: arr["sales_" + item.code],
                          salestext: arr["salestext_" + item.code],
                          salesicon: arr["salesicon_" + item.code],
                          service: arr["service_" + item.code],
                          servicetext: arr["servicetext_" + item.code],
                          serviceicon: arr["serviceicon_" + item.code],
                          areadefault: arr["areadefault_" + item.code],
                          area: arr["area_" + item.code],
                          countrydefault: arr["countrydefault_" + item.code],
                          country: arr["country_" + item.code],
                          manager: arr["manager_" + item.code],
                          email: arr["email_" + item.code],
                          phone: arr["phone_" + item.code],
                          languageId: arr["languageId_" + item.code],
                          id: arr["id_" + item.code],
                          title: arr["title_" + item.code],
                        }
                        idarr += data.id
                        await axios
                          .post("/servicesales/page", data)
                          .then(async response => {
                            toast.success("Servis & Satış Sayfası Düzenlendi")
                            setResult(prev => [...prev, response.data])
                          })
                      })
                      if (idarr == 0 && !file) {
                        window.location.reload()
                      }
                    }}
                  >
                    <div className="row">
                      <div className="col-lg-12">
                        {/* two colum*/}
                        <div
                          className="row"
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        ></div>
                      </div>
                      <div className="col-lg-12">
                        <Nav tabs className="nav-tabs-custom nav-justified">
                          {lang &&
                            lang.map((item, index) => (
                              <NavItem key={index}>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active:
                                      customActiveTab === index.toString(),
                                  })}
                                  onClick={() => {
                                    toggleCustom(index.toString())
                                  }}
                                >
                                  <span className="d-block d-sm-none">
                                    {item.displayname}
                                  </span>
                                  <span className="d-none d-sm-block">
                                    {item.displayname}
                                  </span>
                                </NavLink>
                              </NavItem>
                            ))}
                        </Nav>

                        <TabContent
                          activeTab={customActiveTab}
                          className="p-3 text-muted"
                        >
                          {form &&
                            lang &&
                            lang.map((item, index) => (
                              <TabPane tabId={index.toString()} key={index}>
                                <Row>
                                  <div className="col-lg-6">
                                    <div className="form-group mb-3">
                                      <label htmlFor="simpleinput">
                                        Sayfa Basligi
                                      </label>
                                      <input
                                        type="text"
                                        id="simpleinput"
                                        className="form-control"
                                        value={getFormValueByIndex(
                                          index,
                                          "title_" + item.code
                                        )}
                                        onChange={e =>
                                          setFormValueByIndex(
                                            index,
                                            "title_" + item.code,
                                            e.target.value
                                          )
                                        }
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="form-group mb-3">
                                      <label htmlFor="simpleinput">Adı</label>
                                      <input
                                        type="text"
                                        id="simpleinput"
                                        className="form-control"
                                        value={getFormValueByIndex(
                                          index,
                                          "name_" + item.code
                                        )}
                                        onChange={e =>
                                          setFormValueByIndex(
                                            index,
                                            "name_" + item.code,
                                            e.target.value
                                          )
                                        }
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="form-group mb-3">
                                      <label htmlFor="simpleinput">
                                        Bölge Yazısı
                                      </label>
                                      <input
                                        type="text"
                                        id="simpleinput"
                                        className="form-control"
                                        value={getFormValueByIndex(
                                          index,
                                          "country_" + item.code
                                        )}
                                        onChange={e =>
                                          setFormValueByIndex(
                                            index,
                                            "country_" + item.code,
                                            e.target.value
                                          )
                                        }
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="form-group mb-3">
                                      <label htmlFor="simpleinput">
                                        Başta Seçili Bölge Yazısı
                                      </label>
                                      <input
                                        type="text"
                                        id="simpleinput"
                                        className="form-control"
                                        value={getFormValueByIndex(
                                          index,
                                          "countrydefault_" + item.code
                                        )}
                                        onChange={e =>
                                          setFormValueByIndex(
                                            index,
                                            "countrydefault_" + item.code,
                                            e.target.value
                                          )
                                        }
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="form-group mb-3">
                                      <label htmlFor="simpleinput">
                                        İl Yazısı
                                      </label>
                                      <input
                                        type="text"
                                        id="simpleinput"
                                        className="form-control"
                                        value={getFormValueByIndex(
                                          index,
                                          "area_" + item.code
                                        )}
                                        onChange={e =>
                                          setFormValueByIndex(
                                            index,
                                            "area_" + item.code,
                                            e.target.value
                                          )
                                        }
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="form-group mb-3">
                                      <label htmlFor="simpleinput">
                                        Başta Seçili İl Yazısı
                                      </label>
                                      <input
                                        type="text"
                                        id="simpleinput"
                                        className="form-control"
                                        value={getFormValueByIndex(
                                          index,
                                          "areadefault_" + item.code
                                        )}
                                        onChange={e =>
                                          setFormValueByIndex(
                                            index,
                                            "areadefault_" + item.code,
                                            e.target.value
                                          )
                                        }
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="form-group mb-3">
                                      <label htmlFor="simpleinput">
                                        Yönetici Yazısı
                                      </label>
                                      <input
                                        type="text"
                                        id="simpleinput"
                                        className="form-control"
                                        value={getFormValueByIndex(
                                          index,
                                          "manager_" + item.code
                                        )}
                                        onChange={e =>
                                          setFormValueByIndex(
                                            index,
                                            "manager_" + item.code,
                                            e.target.value
                                          )
                                        }
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="form-group mb-3">
                                      <label htmlFor="simpleinput">
                                        Email Yazısı
                                      </label>
                                      <input
                                        type="text"
                                        id="simpleinput"
                                        className="form-control"
                                        value={getFormValueByIndex(
                                          index,
                                          "email_" + item.code
                                        )}
                                        onChange={e =>
                                          setFormValueByIndex(
                                            index,
                                            "email_" + item.code,
                                            e.target.value
                                          )
                                        }
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-12">
                                    <div className="form-group mb-3">
                                      <label htmlFor="simpleinput">
                                        Telefon Yazısı
                                      </label>
                                      <input
                                        type="text"
                                        id="simpleinput"
                                        className="form-control"
                                        value={getFormValueByIndex(
                                          index,
                                          "phone_" + item.code
                                        )}
                                        onChange={e =>
                                          setFormValueByIndex(
                                            index,
                                            "phone_" + item.code,
                                            e.target.value
                                          )
                                        }
                                        required
                                      />
                                    </div>
                                  </div>
                                  <hr
                                    style={{
                                      background: "black",
                                      height: "1px",
                                    }}
                                  />
                                  <div className="col-lg-6">
                                    <div className="form-group mb-3">
                                      <label htmlFor="simpleinput">
                                        Satışlar
                                      </label>
                                      <input
                                        type="text"
                                        id="simpleinput"
                                        className="form-control"
                                        value={getFormValueByIndex(
                                          index,
                                          "sales_" + item.code
                                        )}
                                        onChange={e =>
                                          setFormValueByIndex(
                                            index,
                                            "sales_" + item.code,
                                            e.target.value
                                          )
                                        }
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="form-group mb-3">
                                      <label htmlFor="simpleinput">
                                        Satış İkonu
                                      </label>
                                      <input
                                        type="text"
                                        id="simpleinput"
                                        className="form-control"
                                        value={getFormValueByIndex(
                                          index,
                                          "salesicon_" + item.code
                                        )}
                                        onChange={e =>
                                          setFormValueByIndex(
                                            index,
                                            "salesicon_" + item.code,
                                            e.target.value
                                          )
                                        }
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-12">
                                    <div className="form-group mb-3">
                                      <label htmlFor="simpleinput">
                                        Satış Yazısı
                                      </label>
                                      <input
                                        type="text"
                                        id="simpleinput"
                                        className="form-control"
                                        value={getFormValueByIndex(
                                          index,
                                          "salestext_" + item.code
                                        )}
                                        onChange={e =>
                                          setFormValueByIndex(
                                            index,
                                            "salestext_" + item.code,
                                            e.target.value
                                          )
                                        }
                                        required
                                      />
                                    </div>
                                  </div>
                                  <hr
                                    style={{
                                      background: "black",
                                      height: "1px",
                                    }}
                                  />
                                  <div className="col-lg-6">
                                    <div className="form-group mb-3">
                                      <label htmlFor="simpleinput">
                                        Servisler
                                      </label>
                                      <input
                                        type="text"
                                        id="simpleinput"
                                        className="form-control"
                                        value={getFormValueByIndex(
                                          index,
                                          "service_" + item.code
                                        )}
                                        onChange={e =>
                                          setFormValueByIndex(
                                            index,
                                            "service_" + item.code,
                                            e.target.value
                                          )
                                        }
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="form-group mb-3">
                                      <label htmlFor="simpleinput">
                                        Servis İkonu
                                      </label>
                                      <input
                                        type="text"
                                        id="simpleinput"
                                        className="form-control"
                                        value={getFormValueByIndex(
                                          index,
                                          "serviceicon_" + item.code
                                        )}
                                        onChange={e =>
                                          setFormValueByIndex(
                                            index,
                                            "serviceicon_" + item.code,
                                            e.target.value
                                          )
                                        }
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-12">
                                    <div className="form-group mb-3">
                                      <label htmlFor="simpleinput">
                                        Servis Yazısı
                                      </label>
                                      <input
                                        type="text"
                                        id="simpleinput"
                                        className="form-control"
                                        value={getFormValueByIndex(
                                          index,
                                          "servicetext_" + item.code
                                        )}
                                        onChange={e =>
                                          setFormValueByIndex(
                                            index,
                                            "servicetext_" + item.code,
                                            e.target.value
                                          )
                                        }
                                        required
                                      />
                                    </div>
                                  </div>
                                </Row>
                              </TabPane>
                            ))}
                        </TabContent>
                      </div>
                      {/*submit button right*/}
                      <div className="col-lg-12">
                        <div className="mt-4 d-flex justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-primary w-md  mb-2"
                          >
                            Duzenle
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger w-md mx-3 mb-2"
                            onClick={() => {
                              //rout Edit category
                              Navigate("/main-page")
                            }}
                          >
                            Geri
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MainPage
