import React from "react"
import {
  Container,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import { useNavigate } from "react-router-dom"

import classnames from "classnames"

import axios from "lib/axios"

// Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"

import { toast } from "react-toastify"

import { CKEditor } from "@ckeditor/ckeditor5-react"
import { Editor } from "ckeditor5-custom-build/build/ckeditor"

const FaqAdd = () => {
  const Navigate = useNavigate()

  document.title = "Safkar Panel - Sirket Bilgisi Ekle"

  const [title, setTitle] = React.useState("")
  const [value, setValue] = React.useState("")

  React.useEffect(() => {}, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Sirket Bilgisi"
            breadcrumbItem="Sirket Bilgisi Ekle"
          />

          {/* Render Form */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form
                    onSubmit={e => {
                      e.preventDefault()

                      var arr = {}
                      arr["name"] = title
                      arr["value"] = value
                      axios
                        .post("/invest/info", arr)
                        .then(response => {
                          if (response.status == 200) {
                            toast.success("Sirket Bilgisi Eklendi")
                          }
                          Navigate("/infos")
                        })
                        .catch(error => {
                          console.log(error)
                          toast("Sirket Bilgisi Eklenemedi")
                        })
                    }}
                  >
                    <h4 className="card-title">Sirket Bilgisi Ekle</h4>
                    <p className="card-title-desc">Sirket Bilgisi Ekle</p>
                    <button
                      type="button"
                      className="btn btn-danger w-md mx-3 mb-2"
                      style={{
                        position: "absolute",
                        right: "0",
                        top: "20px",
                      }}
                      onClick={() => {
                        Navigate("/infos")
                      }}
                    >
                      Geri
                    </button>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mt-4">
                          <label htmlFor="infoTitle" className="form-label">
                            Başlık
                          </label>
                          <input
                            id="infoTitle"
                            name="infoTitle"
                            type="text"
                            className="form-control"
                            placeholder="Sirket Bilgisi Başlık"
                            onChange={e => setTitle(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mt-4">
                          <label htmlFor="infoTitle" className="form-label">
                            Bilgi
                          </label>
                          <input
                            id="infoTitle"
                            name="infoTitle"
                            type="text"
                            className="form-control"
                            placeholder="Sirket Bilgisi Bilgi"
                            onChange={e => setValue(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="mt-4 d-flex justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-primary w-md  mb-2"
                          >
                            Ekle
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger w-md mx-3 mb-2"
                            onClick={() => {
                              Navigate("/infos")
                            }}
                          >
                            Geri
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FaqAdd
