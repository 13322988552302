import React from "react"
import {
  Container,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import { useNavigate } from "react-router-dom"

import classnames from "classnames"

import axios, { API_URL } from "lib/axios"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import { toast } from "react-toastify"

const News = () => {
  const Navigate = useNavigate()

  const [form, setForm] = React.useState()
  const [file, setFile] = React.useState()

  //meta title
  document.title = "Safkar Panel - SSS Sayfası Düzenle"

  const [customActiveTab, setCustomActiveTab] = React.useState("0")

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab)
    }
  }

  const [lang, setLang] = React.useState()

  React.useEffect(() => {
    axios
      .get("/language")
      .then(responseLang => {
        if (responseLang.data.languages) {
          setLang(responseLang.data.languages)

          axios
            .get(`/sss/context`)
            .then(responseKVKK => {
              if (responseKVKK.data) {
                const newForm = responseLang.data.languages.map(
                  (language, index) => {
                    var kvkkLangRow = responseKVKK.data.ssslang.find(
                      item => item.languageId == language.id
                    )

                    if (kvkkLangRow) {
                      return {
                        ["title_" + language.code]: kvkkLangRow.title,
                        ["id_" + language.code]: kvkkLangRow.id,
                      }
                    } else {
                      return {
                        ["title_" + language.code]: "",
                        ["id_" + language.code]: index + 1,
                      }
                    }
                  }
                )
                setForm(newForm)

                setFile(responseKVKK.data.ssslang[0].image)
              }
            })
            .catch(error => {
              console.log(error)
            })
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const isKVKKForm = obj => {
    return true
  }

  const setFormValueByIndex = (index, key, value) => {
    const newForm = [...form]
    if (isKVKKForm(newForm[index])) {
      newForm[index][key] = value.toString()
    }
    setForm(newForm)
  }

  const getFormValueByIndex = (index, key) => {
    const newForm = [...form]
    if (isKVKKForm(newForm[index])) {
      if (newForm[index]) {
        return newForm[index][key]
      } else {
        return ""
      }
    }
    return ""
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="SSS" breadcrumbItem="SSS Düzenle" />

          {/* Render Form */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form
                    onSubmit={e => {
                      e.preventDefault()
                      var arr = {}
                      form.forEach(item => {
                        Object.keys(item).forEach(key => {
                          arr[key] = item[key]
                        })
                      })

                      if (file && typeof file === "object") {
                        if (
                          file.type != "image/png" &&
                          file.type != "image/jpeg" &&
                          file.type != "image/jpg"
                        ) {
                          toast.error(
                            "Lütfen geçerli bir resim dosyası seçiniz"
                          )
                          return
                        }
                      }

                      axios
                        .post("/sss/context", arr)
                        .then(response => {
                          if (response.data.success) {
                            toast.success("Güncelleme Başarılı")
                            if (typeof file === "object") {
                              const formData = new FormData()
                              formData.append("images", file)
                              formData.append("id", response.data.id)
                              axios
                                .post("/sss/image", formData)
                                .then(response => {
                                  if (response.data.success) {
                                  }
                                })
                                .catch(error => {
                                  console.log(error)
                                })
                            }
                          }
                        })
                        .catch(error => {
                          console.log(error)
                        })
                    }}
                  >
                    <h4 className="card-title">SSS Düzenle</h4>
                    <p className="card-title-desc">SSS Düzenle</p>
                    {/* back button */}
                    <button
                      type="button"
                      className="btn btn-danger w-md mx-3 mb-2"
                      style={{
                        position: "absolute",
                        right: "0",
                        top: "20px",
                      }}
                      onClick={() => {
                        Navigate("/main-page")
                      }}
                    >
                      Geri
                    </button>
                    <div className="col-lg-12">
                      <div className="mt-4">
                        {file && (
                          <img
                            src={
                              file && typeof file === "string"
                                ? `${API_URL}/api/image?image=${file.replaceAll(
                                    " ",
                                    "%20"
                                  )}&path=sss`
                                : URL.createObjectURL(file)
                            }
                            alt="sss"
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="mt-4 mb-4">
                        <input
                          id="productname"
                          name="productname"
                          type="file"
                          accept=".png, .jpeg, .jpg"
                          className="form-control"
                          onChange={e => {
                            setFile(e.target.files[0])
                          }}
                        />
                      </div>
                      <Nav tabs className="nav-tabs-custom nav-justified">
                        {lang &&
                          lang.map((item, index) => (
                            <NavItem key={index}>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: customActiveTab === index.toString(),
                                })}
                                onClick={() => {
                                  toggleCustom(index.toString())
                                }}
                              >
                                <span className="d-block d-sm-none">
                                  {item.displayname}
                                </span>
                                <span className="d-none d-sm-block">
                                  {item.displayname}
                                </span>
                              </NavLink>
                            </NavItem>
                          ))}
                      </Nav>

                      <TabContent
                        activeTab={customActiveTab}
                        className="p-3 text-muted"
                      >
                        {form &&
                          lang &&
                          lang.map((item, index) => (
                            <TabPane tabId={index.toString()} key={index}>
                              <Row>
                                <Col sm="12">
                                  <div className="mt-4">
                                    <label
                                      htmlFor="kvkkTitle"
                                      className="form-label"
                                    >
                                      SSS Başlık
                                    </label>

                                    <input
                                      id="kvkkTitle"
                                      name="kvkkTitle"
                                      type="text"
                                      className="form-control"
                                      placeholder="SSS Başlık"
                                      defaultValue={getFormValueByIndex(
                                        index,
                                        "title_" + item.code
                                      )}
                                      onChange={e =>
                                        setFormValueByIndex(
                                          index,
                                          "title_" + item.code,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </Col>
                                {/*
                                <Col sm="12">
                                  <div className="mt-4">
                                    <label
                                      htmlFor="kvkkContent"
                                      className="form-label"
                                    >
                                      News Aktiflik
                                    </label>
                                      
                                    <input
                                    type="checkbox"
                                    className="form-check-input"
                                    defaultChecked={getFormValueByIndex(
                                      index,
                                      "active_" + item.code
                                      )}
                                    onChange={(e) => {
                                      setFormValueByIndex(
                                        index,
                                        "active_" + item.code,
                                        Boolean(e.target.checked)
                                      );
                                    }}
                                  />
                                  </div>
                                </Col>
                                                                                                      */}
                              </Row>
                            </TabPane>
                          ))}
                      </TabContent>
                    </div>
                    {/*submit button right*/}
                    <div className="col-lg-12">
                      <div className="mt-4 d-flex justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-primary w-md  mb-2"
                        >
                          Güncelle
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger w-md mx-3 mb-2"
                          onClick={() => {
                            Navigate("/main-page")
                          }}
                        >
                          Geri
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default News
