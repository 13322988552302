import React from "react"
import { Button, Container } from "reactstrap"

import { Text, Group, ActionIcon, BackgroundImage } from "@mantine/core"
import { IconEdit, IconTrash, IconMenu2 } from "@tabler/icons-react"

import axios, { API_URL } from "lib/axios"

import { json, useNavigate } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

// Import Components
import DataTableMantine from "components/Added/datatable"
import DeleteModal from "components/Common/DeleteModal"

import { toast } from "react-toastify"

const Products = () => {
  const Navigate = useNavigate()

  const [data, setData] = React.useState()

  React.useEffect(() => {
    axios
      .get("/product")
      .then(response => {
        if (response.data.products) {
          let products = []
          products = response.data.products.map(item => {
            return {
              id: item.id,
              name: item.productlang[0].title,
              category: item.category,
              image: item.image,
            }
          })

          setData({ products: products })
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])
  //meta title
  document.title = "Safkar Panel - Urunler"

  //delete order
  const [order, setOrder] = React.useState(null)
  const [deleteModal, setDeleteModal] = React.useState(false)

  const onClickDelete = order => {
    setOrder(order)
    setDeleteModal(true)
  }

  const handleDeleteOrder = () => {
    if (order && order.id) {
      let arr = {}
      arr["id"] = order.id
      axios
        .delete("/product", {
          data: arr,
        })
        .then(res => {
          toast.success("Urun başarıyla silindi.")
          setDeleteModal(false)
          setData({
            products: data.products.filter(item => item.id !== order.id),
          })
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
  const handleOrderClicks = () => {
    setOrderList("")
    setIsEdit(false)
    toggle()
  }

  const arkaplan = {
    backgroundColor: "#D3E0FD",
  }

  return (
    <React.Fragment>
      {order && (
        <DeleteModal
          show={deleteModal}
          text={order.name + " urununu silmek istediğinize emin misiniz?"}
          onDeleteClick={handleDeleteOrder}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Panel" breadcrumbItem="Urunler" />

          {/* Render Add Button */}
          <Group position="right" spacing={4} mt="md">
            <button
              className="btn btn-primary mb-2"
              onClick={() => {
                //rout Add product
                Navigate("/product/add")
              }}
            >
              Urun Ekle
            </button>
          </Group>

          {/* Render DataTable */}
          {data && (
            <DataTableMantine
              filterField={"name"}
              columns={[
                {
                  name: "ID",
                  selector: row => row.id,
                  sortable: true,
                  width: "50px",
                  textAlign: "center", // Combined "right" and "Center" into one; you may need to adjust
                },
                {
                  name: "Resim",
                  width: "170px",
                  cell: item => {
                    if (!item.image) return null

                    return (
                      <img
                        src={`${API_URL}/api/image?image=${item.image.replaceAll(
                          " ",
                          "%20"
                        )}&path=product`}
                        alt={item.name}
                        width={150}
                      />
                    )
                  },
                },
                {
                  name: "Urun Adı",
                  selector: row => row.name,
                  sortable: true,
                },
                {
                  name: <Text mr="xs">Islemler</Text>,
                  width: "110px",
                  textAlign: "center",
                  cell: item => (
                    <Group spacing={4} position="center" noWrap>
                      <ActionIcon
                        color="blue"
                        onClick={() => Navigate("/product/edit/" + item.id)}
                      >
                        <IconEdit size={16} />
                      </ActionIcon>
                      <ActionIcon
                        color="red"
                        onClick={() => onClickDelete(item)}
                      >
                        <IconTrash size={16} />
                      </ActionIcon>
                    </Group>
                  ),
                  ignoreRowClick: true,
                  allowOverflow: true,
                  button: true,
                },
              ]}
              data={data.products}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Products
