import React from "react"
import { Container } from "reactstrap"
import axios, { API_URL } from "lib/axios"
import Breadcrumbs from "components/Common/Breadcrumb"
import DataTableMantine from "components/Added/datatable"

const Blog = () => {
  document.title = "Safkar Panel - Loglar"
  const [data, setData] = React.useState()

  React.useEffect(() => {
    axios
      .get("/logs/error")
      .then(response => {
        if (response.data.errorLogs) {
          setData(response.data.errorLogs)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Panel" breadcrumbItem="Hata Logları" />
          {data && (
            <DataTableMantine
            filterField={"API"}
              columns={[
                {
                  name: "Mac Adresi",
                  selector: row => row.IP,
                },
                {
                  name: "İstek Yapılan Adres",
                  cell: row => (
                    <a
                      href={API_URL + row.API}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {API_URL + row.API}
                    </a>
                  ),
                },
                {
                  name: "Metodu",
                  cell: row => {
                    switch (row.Method) {
                      case "CREATE":
                        return (
                          <span className="badge badge-soft-success font-size-12">
                            {row.Method}
                          </span>
                        )
                      case "UPDATE":
                        return (
                          <span className="badge badge-soft-warning font-size-12">
                            {row.Method}
                          </span>
                        )
                      case "DELETE":
                        return (
                          <span className="badge badge-soft-danger font-size-12">
                            {row.Method}
                          </span>
                        )
                      default:
                        return (
                          <span className="badge badge-soft-info font-size-12">
                            {row.Method}
                          </span>
                        )
                    }
                  },
                },
                {
                  name: "Eylemi",
                  cell: row => {
                    switch (row.Action) {
                      case "RESPONSE":
                        return (
                          <span className="badge badge-soft-success font-size-12">
                            {row.Action}
                          </span>
                        )
                      case "REQUEST":
                        return (
                          <span className="badge badge-soft-warning font-size-12">
                            {row.Action}
                          </span>
                        )
                      default:
                        return (
                          <span className="badge badge-soft-info font-size-12">
                            {row.Action}
                          </span>
                        )
                    }
                  },
                },
                {
                  name: "Bilgi",
                  cell: row => {
                    if (row.info) {
                      return (
                        <span
                          title={row.info}
                          style={{
                            overflow: "hidden",
                          }}
                          className="badge badge-soft-info font-size-12"
                        >
                          {row.info}
                        </span>
                      )
                    }
                    return "Bilgi Yok"
                  },
                },
                {
                  name: "Oluşturulma Tarihi",
                  sortable: true,
                  cell: row => (
                    <span className="badge badge-soft-info font-size-12">
                      {new Date(row.createdAt).toLocaleString()}
                    </span>
                  ),
                },
              ]}
              data={data}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Blog
