import React from "react"
import {
  Container,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import { useParams, useNavigate } from "react-router-dom"

import classnames from "classnames"

import axios, { API_URL } from "lib/axios"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// Import Components

import { CKEditor } from "@ckeditor/ckeditor5-react"
import { Editor } from "ckeditor5-custom-build/build/ckeditor"

import { toast } from "react-toastify"
import { Select } from "@mantine/core"
import { useEffect } from "react"

const Index = () => {
  document.title = "Safkar Panel - Servis & Bakım Ekle"
  const navigate = useNavigate()
  const [countryData, setCountryData] = React.useState([])
  const [provinceData, setProvinceData] = React.useState([])

  const [filteredProvinceData, setFilteredProvinceData] = React.useState([])

  const [title, setTitle] = React.useState("")
  const [address, setAddress] = React.useState("")
  const [manager, setManager] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [phone, setPhone] = React.useState("")
  const [fax, setFax] = React.useState("")
  const [country, setCountry] = React.useState("")
  const [province, setProvince] = React.useState("")
  const [sales, setSales] = React.useState(true)
  const [service, setService] = React.useState(true)
  const [active, setActive] = React.useState(true)

  useEffect(() => {
    axios.get("/country").then(res => {
      if (res.data.length > 0) {
        const { data } = res

        data.forEach(item => {
          setCountryData(prev => [
            ...prev,
            { value: item.id.toString(), label: item.name },
          ])
        })
      }
    })

    axios.get("/province").then(res => {
      if (res.data.length > 0) {
        const { data } = res
        setProvinceData(data)
      }
    })
  }, [])

  useEffect(() => {
    if (countryData.length > 0) {
      setFilteredProvinceData([])
      setProvince("")
      const filtered = provinceData.filter(item => item.countryId == country)
      filtered.forEach(item => {
        setFilteredProvinceData(prev => [
          ...prev,
          { value: item.id.toString(), label: item.name },
        ])
      })
    }
  }, [country])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Bakım & Servis"
            breadcrumbItem="Bakım & Servis Ekle"
          />
          {/* Render Form */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form
                    onSubmit={e => {
                      e.preventDefault()

                      var data = {
                        title,
                        address,
                        manager,
                        email,
                        phone,
                        fax,
                        countryId: country,
                        provinceId: province,
                        provinceName: filteredProvinceData.find(
                          item => item.value == province
                        ).label,
                        sales,
                        service,
                        active,
                      }

                      axios
                        .post("/servicesales", data)
                        .then(res => {
                          toast.success("Başarıyla Eklendi")
                          navigate("/servicesales")
                        })
                        .catch(err => {
                          toast.error("Eklenirken Bir Hata Oluştu")
                        })
                    }}
                  >
                    <h4 className="card-title">Bakım & Servis</h4>
                    <p className="card-title-desc">Bakım & Servis Ekle</p>
                    {/* back button */}
                    <button
                      type="button"
                      className="btn btn-danger w-md mx-3 mb-2"
                      style={{
                        position: "absolute",
                        right: "0",
                        top: "20px",
                      }}
                      onClick={() => {
                        navigate("/servicesales")
                      }}
                    >
                      Geri
                    </button>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group mb-3">
                          <label htmlFor="simpleinput">Başlık</label>
                          <input
                            type="text"
                            id="simpleinput"
                            className="form-control"
                            value={title}
                            onChange={e => {
                              setTitle(e.target.value)
                            }}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group mb-3">
                          <label htmlFor="simpleinput">Adres</label>
                          <input
                            type="text"
                            id="simpleinput"
                            className="form-control"
                            value={address}
                            onChange={e => {
                              setAddress(e.target.value)
                            }}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group mb-3">
                          <label htmlFor="simpleinput">Sorumlu Kişi</label>
                          <input
                            type="text"
                            id="simpleinput"
                            className="form-control"
                            value={manager}
                            onChange={e => {
                              setManager(e.target.value)
                            }}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group mb-3">
                          <label htmlFor="simpleinput">Email Adresi</label>
                          <input
                            type="text"
                            id="simpleinput"
                            className="form-control"
                            value={email}
                            onChange={e => {
                              setEmail(e.target.value)
                            }}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group mb-3">
                          <label htmlFor="simpleinput">Telefon Numarası</label>
                          <input
                            type="text"
                            id="simpleinput"
                            className="form-control"
                            value={phone}
                            onChange={e => {
                              setPhone(e.target.value)
                            }}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group mb-3">
                          <label htmlFor="simpleinput">Fax Numarası</label>
                          <input
                            type="text"
                            id="simpleinput"
                            className="form-control"
                            value={fax}
                            onChange={e => {
                              setFax(e.target.value)
                            }}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group mb-3">
                          <label htmlFor="simpleinput">Bölgesi</label>
                          <Select
                            placeholder="Bölge Seçiniz"
                            nothingFound="Herhangi bir bölge bulunamadı"
                            searchable
                            creatable
                            onChange={e => {
                              setCountry(e)
                            }}
                            data={countryData}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group mb-3">
                          <label htmlFor="simpleinput">Şehri</label>
                          <Select
                            placeholder="Şehir Seçiniz Veya Oluşturunuz"
                            nothingFound="Herhangi bir şehir bulunamadı"
                            searchable
                            creatable
                            allowDeselect
                            value={province}
                            getCreateLabel={query => `+ Yeni ${query} Oluştur`}
                            onChange={e => {
                              setProvince(e)
                            }}
                            data={filteredProvinceData}
                            onCreate={query => {
                              const item = {
                                value: (
                                  parseInt(
                                    provinceData[provinceData.length - 1]?.id ||
                                      "0"
                                  ) + 1
                                ).toString(),
                                label: query,
                              }
                              setFilteredProvinceData(current => [
                                ...current,
                                item,
                              ])
                              return item
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-check form-switch form-switch-lg">
                          <div className="form-group mb-3">
                            <label htmlFor="simpleinput">
                              {sales ? "Satış Yapıyor" : "Satış Yapmıyor"}
                            </label>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customSwitchsizelg"
                              defaultChecked={sales ? true : false}
                              onChange={e => {
                                setSales(e.target.checked)
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-check form-switch form-switch-lg">
                          <div className="form-group mb-3">
                            <label htmlFor="simpleinput">
                              {service ? "Servis" : "Servis Değil"}
                            </label>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customSwitchsizelg"
                              defaultChecked={service ? true : false}
                              onChange={e => {
                                setService(e.target.checked)
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-check form-switch form-switch-lg">
                          <div className="form-group mb-3">
                            <label htmlFor="simpleinput">
                              {active ? "Aktif" : "Aktif Değil"}
                            </label>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customSwitchsizelg"
                              defaultChecked={active ? true : false}
                              onChange={e => {
                                setActive(e.target.checked)
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*submit button right*/}
                    <div className="col-lg-12">
                      <div className="mt-4 d-flex justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-primary w-md  mb-2"
                        >
                          Ekle
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger w-md mx-3 mb-2"
                          onClick={() => {
                            navigate("/main-page")
                          }}
                        >
                          Geri
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Index
