import React from "react"
import {
  Container,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import { useParams, useNavigate } from "react-router-dom"

import classnames from "classnames"

import axios, { API_URL } from "lib/axios"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// Import Components

import { CKEditor } from "@ckeditor/ckeditor5-react"
import { Editor } from "ckeditor5-custom-build/build/ckeditor"

import { toast } from "react-toastify"
import DataTableMantine from "components/Added/datatable"
import { ActionIcon, Group, Text } from "@mantine/core"
import { IconEdit, IconTrash } from "@tabler/icons-react"
import DeleteModal from "components/Common/DeleteModal"

const Index = () => {
  const Navigate = useNavigate()
  const [data, setData] = React.useState()
  const [deleteModal, setdeleteModal] = React.useState(false)
  const [order, setOrder] = React.useState()
  //meta title
  document.title = "Safkar Panel - Sosyal Medya"

  React.useEffect(() => {
    axios.get("/social").then(res => {
      setData(res.data)
    })
  }, [])

  /*
    name: req.body.name,
    icon: req.body.icon,
    url: req.body.url,
    active: active,
*/

  const handleDeleteOrder = () => {
    axios
      .delete("/social", { data: { id: order.id } })
      .then(res => {
        toast.success("Sosyal Medya Silindi")
        setdeleteModal(false)
        axios.get("/social").then(res => {
          setData(res.data)
        })
      })
      .catch(err => {
        toast.error("Sosyal Medya Silinemedi")
      })
  }

  const onClickDelete = order => {
    setOrder(order)
    setdeleteModal(true)
  }

  return (
    <React.Fragment>
      {order && (
        <DeleteModal
          show={deleteModal}
          text={order.name + " paylaşımını silmek istediğinize emin misiniz?"}
          onDeleteClick={handleDeleteOrder}
          onCloseClick={() => setdeleteModal(false)}
        />
      )}
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Sosyal" breadcrumbItem="Sosyal Medya" />
          <Group position="right" spacing={4} mt="md">
            <button
              className="btn btn-primary mb-2"
              onClick={() => {
                //rout Add category
                Navigate("/social/add")
              }}
            >
              Sosyal Paylaşım Ekle
            </button>
          </Group>
          {/* Render Form */}
          <div className="row">
            <DataTableMantine
              filterField={"name"}
              columns={[
                {
                  name: "ID",
                  selector: row => row.id,
                  sortable: true,
                  width: "50px",
                  textAlign: "center", // Combined "right" and "Center" into one; you may need to adjust
                },
                {
                  name: "Sosyal Medya Adı",
                  selector: row => row.name,
                  sortable: true,
                },
                {
                  name: <Text mr="xs">Islemler</Text>,
                  width: "110px",
                  textAlign: "center",
                  cell: item => (
                    <Group spacing={4} position="center" noWrap>
                      <ActionIcon
                        color="blue"
                        onClick={() => Navigate("/social/edit/" + item.id)}
                      >
                        <IconEdit size={16} />
                      </ActionIcon>
                      <ActionIcon
                        color="red"
                        onClick={() => onClickDelete(item)}
                      >
                        <IconTrash size={16} />
                      </ActionIcon>
                    </Group>
                  ),
                  ignoreRowClick: true,
                  allowOverflow: true,
                  button: true,
                },
              ]}
              data={data}
              // Expansion={({ record }) => {
              //   if (record.subcategory.length > 0) {
              //     return (
              //       <div className="p-2" style={arkaplan}>
              //         <DataTableMantine
              //           columns={[
              //             {
              //               accessor: "id",
              //               // this column has a custom title
              //               title: "ID",
              //               // right-align column
              //               textAlignment: "right",
              //               width: 50,
              //               sortable: true,
              //               textAlignment: "Center",
              //             },
              //             {
              //               accessor: "subcategorylang[0].title",
              //               title: "Alt Kategori Adı",
              //               sortable: true,
              //             },
              //             {
              //               accessor: "actions",
              //               title: <Text mr="xs">Islemler</Text>,
              //               width: 100,
              //               textAlignment: "Center",
              //               render: subitem => (
              //                 <Group spacing={4} position="center" noWrap>
              //                   <ActionIcon
              //                     color="blue"
              //                     onClick={() => {
              //                       //rout Edit category
              //                       alert("alt kategori")
              //                       Navigate("/category/edit/" + subitem.id)
              //                     }}
              //                   >
              //                     <IconEdit size={16} />
              //                   </ActionIcon>
              //                   <ActionIcon
              //                     color="red"
              //                     onClick={() => onClickDelete(subitem)}
              //                   >
              //                     <IconTrash size={16} />
              //                   </ActionIcon>
              //                 </Group>
              //               ),
              //             },
              //           ]}
              //           data={record.subcategory}
              //         />
              //       </div>
              //     )
              //   } else {
              //     return (
              //       <>
              //         <Text className="p-2" onClick={() => alert("dne")}>
              //           {" "}
              //           {record.categorylang[0].title} Kategorisini alt
              //           kategorisi bulunmamaktadır.
              //         </Text>
              //         <Button
              //           className="p-2"
              //           onClick={() => {
              //             //rout Add category
              //             alert("alt kategori ekle")
              //           }}
              //         >
              //           Alt Kategori Ekle
              //         </Button>
              //       </>
              //     )
              //   }
              // }}
            />
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Index
