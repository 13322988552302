import React from "react"
import { Container } from "reactstrap"

import { Text, Group, ActionIcon } from "@mantine/core"
import { IconEdit, IconTrash } from "@tabler/icons-react"

import axios from "lib/axios"

import { useNavigate } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

// Import Components
import DataTableMantine from "components/Added/datatable"
import DeleteModal from "components/Common/DeleteModal"

import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const Popups = () => {
  const Navigate = useNavigate()

  const [data, setData] = React.useState()

  React.useEffect(() => {
    axios
      .get("/history")
      .then(response => {
        if (response.data.history) {
          setData({ categories: response.data.history })
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])
  //meta title
  document.title = "Safkar Panel - Geçmiş"

  //delete order
  const [order, setOrder] = React.useState(null)
  const [deleteModal, setDeleteModal] = React.useState(false)

  const onClickDelete = order => {
    setOrder(order)
    setDeleteModal(true)
  }

  const handleDeleteOrder = () => {
    if (order && order.id) {
      let arr = {}
      arr["id"] = order.id
      axios
        .delete("/history", {
          data: arr,
        })
        .then(res => {
          toast.success("Geçmiş başarıyla silindi.")
          setDeleteModal(false)
          setData({
            categories: data.categories.filter(item => item.id !== order.id),
          })
        })
        .catch(err => {
          toast.success("Geçmiş silinirken bir hata oluştu.")
        })
    }
  }
  const handleOrderClicks = () => {
    setOrderList("")
    setIsEdit(false)
    toggle()
  }

  return (
    <React.Fragment>
      {order && (
        <DeleteModal
          show={deleteModal}
          text={order.date + " Geçmişini istediğinize emin misiniz?"}
          onDeleteClick={handleDeleteOrder}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Geçmiş" breadcrumbItem="Geçmişler" />

          {/* Render Add Button */}
          <Group position="right" spacing={4} mt="md">
            <button
              className="btn btn-primary mb-2"
              onClick={() => {
                //rout Add category
                Navigate("/history/add")
              }}
            >
              Geçmiş Ekle
            </button>
          </Group>

          {/* Render DataTable */}
          {data && (
            <DataTableMantine
              filterField={"date"}
              columns={[
                {
                  name: "ID",
                  selector: row => row.id,
                  sortable: true,
                  width: 50,
                  textAlign: "center",
                },
                {
                  name: "Geçmiş Tarihi",
                  selector: row => row.date,
                  sortable: true,
                },
                {
                  name: <Text mr="xs">Islemler</Text>,
                  width: 110,
                  textAlign: "center",
                  cell: item => (
                    <Group spacing={4} position="center" noWrap>
                      <ActionIcon
                        color="blue"
                        onClick={() => Navigate("/history/edit/" + item.id)}
                      >
                        <IconEdit size={16} />
                      </ActionIcon>
                      <ActionIcon
                        color="red"
                        onClick={() => onClickDelete(item)}
                      >
                        <IconTrash size={16} />
                      </ActionIcon>
                    </Group>
                  ),
                  ignoreRowClick: true,
                  allowOverflow: true,
                  button: true,
                },
              ]}
              data={data.categories}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Popups
