import React from "react"
import {
  Container,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Input,
} from "reactstrap"

import { useNavigate } from "react-router-dom"

import classnames from "classnames"

import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import axios, { API_URL } from "lib/axios"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { MultiSelect } from "@mantine/core"
import { get, isArray, set } from "lodash"
import { useEffect } from "react"
import { select } from "redux-saga/effects"

// Import Components

const MainPage = () => {
  const Navigate = useNavigate()
  // all variables
  const [file, setFile] = React.useState()

  const [form, setForm] = React.useState()

  //meta title
  document.title = "Safkar Panel - İletişim Formu Sayfası"

  const [customActiveTab, setcustomActiveTab] = React.useState("0")

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  const [lang, setLang] = React.useState()

  React.useEffect(() => {
    axios
      .get("/language")
      .then(responseLang => {
        if (responseLang.data.languages) {
          setLang(responseLang.data.languages)

          axios.get("/contact/form").then(response => {
            const { data } = response

            const newForm = responseLang.data.languages.map(
              (language, index) => {
                var row = data.find(item => item.languageId == language.id)
                if (row) {
                  let select = JSON.parse(row.select)
                  return {
                    ["name_" + language.code]: row.name,
                    ["map_" + language.code]: row.map,
                    ["email_" + language.code]: row.email,
                    ["phone_" + language.code]: row.phone,
                    ["province_" + language.code]: row.province,
                    ["button_" + language.code]: row.button,
                    ["Select_" + language.code]: select["label"]
                      ? select["label"]
                      : [],
                    ["Mail_" + language.code]: select["mail"]
                      ? select["mail"]
                      : [],
                    ["languageId_" + language.code]: language.id,
                    ["defaultValues_" + language.code]: select["label"]
                      ? select["label"]
                      : [],
                    ["id_" + language.code]: row.id,
                    ["title_" + language.code]: row.title,
                  }
                } else {
                  return {
                    ["name_" + language.code]: "",
                    ["map_" + language.code]: "",
                    ["email_" + language.code]: "",
                    ["phone_" + language.code]: "",
                    ["province_" + language.code]: "",
                    ["button_" + language.code]: "",
                    ["Select_" + language.code]: [],
                    ["Mail_" + language.code]: [],
                    ["languageId_" + language.code]: language.id,
                    ["defaultValues_" + language.code]: [],
                    ["id_" + language.code]: 0,
                    ["title_" + language.code]: "",
                  }
                }
              }
            )
            setForm(newForm)

            if (data.length > 0 && data[0].image) {
              setFile(data[0].image)
            }
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const isCategoryForm = obj => {
    // Gerekli özelliklerin varlığını kontrol ederek
    // obj'nin CategoryForm tipinde olduğunu doğrulayabilirsiniz
    // Örnek olarak: return 'property1' in obj && 'property2' in obj;
    return true
  }

  const setFormValueByIndex = (index, key, value, n = null) => {
    const newForm = [...form]
    if (isCategoryForm(newForm[index])) {
      if (isArray(newForm[index][key]) && !isArray(value)) {
        if (n == null) {
          newForm[index][key] = [...newForm[index][key], value]
        } else {
          newForm[index][key][n] = value
        }
      } else {
        newForm[index][key] = value
      }
    }
    setForm(newForm)
  }

  const getFormValueByIndex = (index, key) => {
    const newForm = [...form]
    if (isCategoryForm(newForm[index])) {
      //if newform has key
      if (newForm[index]) {
        return newForm[index][key]
      } else {
        return ""
      }
    }
    return ""
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="İletişim Formu"
            breadcrumbItem="İletişim Formu Sayfasını Düzenle"
          />

          {/* Render Form */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form
                    onSubmit={e => {
                      e.preventDefault()
                      var arr = {}
                      var data = {}
                      form.forEach(item => {
                        Object.keys(item).forEach(key => {
                          arr[key] = item[key]
                        })
                      })

                      var x = 0

                      if (file && typeof file === "object") {
                        if (
                          file.type != "image/png" &&
                          file.type != "image/jpeg" &&
                          file.type != "image/jpg"
                        ) {
                          toast.error("Resim PNG, JPEG veya JPG olmalidir")
                          return
                        }
                      }

                      lang.forEach(async item => {
                        data = {
                          name: arr["name_" + item.code],
                          map: arr["map_" + item.code],
                          email: arr["email_" + item.code],
                          phone: arr["phone_" + item.code],
                          province: arr["province_" + item.code],
                          button: arr["button_" + item.code],
                          // select: JSON.stringify(arr["Select_" + item.code]),
                          select: JSON.stringify({
                            label: arr["Select_" + item.code],
                            mail: arr["Mail_" + item.code],
                          }),
                          languageId: arr["languageId_" + item.code],
                          id: arr["id_" + item.code],
                          title: arr["title_" + item.code],
                        }

                        await axios
                          .post("/contact/form", data)
                          .then(response => {
                            if (x == lang.length - 1) {
                              toast.success("İletişim Formu Güncellendi")
                            }
                          })
                        x++
                      })

                      if (typeof file === "object") {
                        const formData = new FormData()
                        formData.append("images", file)
                        axios
                          .post("/contact/form/image", formData)
                          .then(response => {
                            if (response.data) {
                              toast.success("Resim Yükleme Başarılı")
                              // Navigate("/main-page");
                            } else {
                              toast.error("Resim Yükleme Başarısız")
                            }
                          })
                          .catch(error => {
                            console.log(error)
                          })
                      }

                      //   axios
                      //     .post("/popup", arr)
                      //     .then(response => {
                      //       console.log(response)
                      //       toast.success("Popup Eklendi")
                      //       if (file) {
                      //         var formData = new FormData()
                      //         console.log(file)
                      //         formData.append("images", file)
                      //         formData.append("popupId", response.data.id)
                      //         console.log(formData)
                      //         axios
                      //           .post("/popup/image", formData)
                      //           .then(response => {
                      //             console.log(response)
                      //           })
                      //       }
                      //       Navigate("/popups")
                      //     })
                      //     .catch(error => {
                      //       console.log(error)
                      //       toast("Popup Eklenemedi")
                      //     })
                    }}
                  >
                    <h4 className="card-title">Iletisim Formu Düzenle</h4>
                    <p className="card-title-desc">Iletisim Formu Düzenle</p>
                    {/* back button */}
                    <button
                      type="button"
                      className="btn btn-danger w-md mx-3 mb-2"
                      style={{
                        position: "absolute",
                        right: "0",
                        top: "20px",
                      }}
                      onClick={() => {
                        Navigate("/main-page")
                      }}
                    >
                      Geri
                    </button>
                    <div className="row">
                      <div className="col-lg-12">
                        {/* two colum*/}
                        <div
                          className="row"
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {file && (
                            <img
                              src={
                                typeof file === "string"
                                  ? `${API_URL}/api/image?image=${file.replaceAll(
                                      " ",
                                      "%20"
                                    )}&path=contactform`
                                  : URL.createObjectURL(file)
                              }
                              style={{ width: "30%" }}
                            ></img>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group">
                          <label htmlFor="simpleinput">Resim</label>
                          <input
                            id="productname"
                            name="productname"
                            type="file"
                            accept=".png, .jpeg, .jpg"
                            className="form-control"
                            onChange={e => {
                              setFile(e.target.files[0])
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="mt-4">
                          <Nav tabs className="nav-tabs-custom nav-justified">
                            {lang &&
                              lang.map((item, index) => (
                                <NavItem key={index}>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      active:
                                        customActiveTab === index.toString(),
                                    })}
                                    onClick={() => {
                                      toggleCustom(index.toString())
                                    }}
                                  >
                                    <span className="d-block d-sm-none">
                                      {item.displayname}
                                    </span>
                                    <span className="d-none d-sm-block">
                                      {item.displayname}
                                    </span>
                                  </NavLink>
                                </NavItem>
                              ))}
                          </Nav>

                          <TabContent
                            activeTab={customActiveTab}
                            className="p-3 text-muted"
                          >
                            {form &&
                              lang &&
                              lang.map((item, index) => (
                                <TabPane tabId={index.toString()} key={index}>
                                  <Row>
                                    <div className="col-lg-12">
                                      <div className="form-group mb-3">
                                        <label htmlFor="simpleinput">
                                          Harita yonlendirme butonu
                                        </label>
                                        <input
                                          type="text"
                                          id="simpleinput"
                                          className="form-control"
                                          value={getFormValueByIndex(
                                            index,
                                            "map_" + item.code
                                          )}
                                          onChange={e =>
                                            setFormValueByIndex(
                                              index,
                                              "map_" + item.code,
                                              e.target.value
                                            )
                                          }
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-12">
                                      <div className="form-group mb-3">
                                        <label htmlFor="simpleinput">Adı</label>
                                        <input
                                          type="text"
                                          id="simpleinput"
                                          className="form-control"
                                          value={getFormValueByIndex(
                                            index,
                                            "name_" + item.code
                                          )}
                                          onChange={e =>
                                            setFormValueByIndex(
                                              index,
                                              "name_" + item.code,
                                              e.target.value
                                            )
                                          }
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-12">
                                      <div className="form-group mb-3">
                                        <label htmlFor="simpleinput">
                                          Mail Adresi
                                        </label>
                                        <input
                                          type="text"
                                          id="simpleinput"
                                          className="form-control"
                                          value={getFormValueByIndex(
                                            index,
                                            "email_" + item.code
                                          )}
                                          onChange={e =>
                                            setFormValueByIndex(
                                              index,
                                              "email_" + item.code,
                                              e.target.value
                                            )
                                          }
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-12">
                                      <div className="form-group mb-3">
                                        <label htmlFor="simpleinput">
                                          Telefon Numarası
                                        </label>
                                        <input
                                          type="text"
                                          id="simpleinput"
                                          className="form-control"
                                          value={getFormValueByIndex(
                                            index,
                                            "phone_" + item.code
                                          )}
                                          onChange={e =>
                                            setFormValueByIndex(
                                              index,
                                              "phone_" + item.code,
                                              e.target.value
                                            )
                                          }
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-12">
                                      <div className="form-group mb-3">
                                        <label htmlFor="simpleinput">
                                          Mesaj
                                        </label>
                                        <input
                                          type="text"
                                          id="simpleinput"
                                          className="form-control"
                                          value={getFormValueByIndex(
                                            index,
                                            "province_" + item.code
                                          )}
                                          onChange={e =>
                                            setFormValueByIndex(
                                              index,
                                              "province_" + item.code,
                                              e.target.value
                                            )
                                          }
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-12">
                                      <div className="form-group mb-3">
                                        <label htmlFor="simpleinput">
                                          Buton
                                        </label>
                                        <input
                                          type="text"
                                          id="simpleinput"
                                          className="form-control"
                                          value={getFormValueByIndex(
                                            index,
                                            "button_" + item.code
                                          )}
                                          onChange={e =>
                                            setFormValueByIndex(
                                              index,
                                              "button_" + item.code,
                                              e.target.value
                                            )
                                          }
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-12">
                                      <div className="form-group mb-3">
                                        <label htmlFor="simpleinput">
                                          Sayfa Basligi
                                        </label>
                                        <input
                                          type="text"
                                          id="simpleinput"
                                          className="form-control"
                                          value={getFormValueByIndex(
                                            index,
                                            "title_" + item.code
                                          )}
                                          onChange={e =>
                                            setFormValueByIndex(
                                              index,
                                              "title_" + item.code,
                                              e.target.value
                                            )
                                          }
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-12">
                                      <div className="form-group mb-3">
                                        <label htmlFor="simpleinput">
                                          Select
                                        </label>
                                        <MultiSelect
                                          data={getFormValueByIndex(
                                            index,
                                            "Select_" + item.code
                                          )}
                                          placeholder="Gösterilecek Seçenekleri Seçebilir veya Yeni Seçenek Ekleyebilirsiniz"
                                          searchable
                                          creatable
                                          defaultValue={getFormValueByIndex(
                                            index,
                                            "defaultValues_" + item.code
                                          )}
                                          value={getFormValueByIndex(
                                            index,
                                            "Select_" + item.code
                                          )}
                                          onChange={e => {
                                            if (
                                              getFormValueByIndex(
                                                index,
                                                "Select_" + item.code
                                              ).length > e.length
                                            ) {
                                              //mached data remove
                                              var arr = getFormValueByIndex(
                                                index,
                                                "Select_" + item.code
                                              )
                                              var arr2 = getFormValueByIndex(
                                                index,
                                                "Mail_" + item.code
                                              )
                                              var arr3 = []
                                              var arr4 = []
                                              arr.forEach((item, index) => {
                                                if (
                                                  e.find(item2 => item2 == item)
                                                ) {
                                                  arr3.push(item)
                                                  arr4.push(arr2[index])
                                                }
                                              })
                                              setFormValueByIndex(
                                                index,
                                                "Select_" + item.code,
                                                arr3
                                              )
                                              setFormValueByIndex(
                                                index,
                                                "Mail_" + item.code,
                                                arr4
                                              )
                                            } else {
                                              setFormValueByIndex(
                                                index,
                                                "Select_" + item.code,
                                                e
                                              )
                                            }
                                          }}
                                          getCreateLabel={query =>
                                            `+ Yeni ${query} Oluştur`
                                          }
                                          onCreate={query => {
                                            setFormValueByIndex(
                                              index,
                                              "Select_" + item.code,
                                              query
                                            )
                                            setFormValueByIndex(
                                              index,
                                              "Mail_" + item.code,
                                              ""
                                            )
                                            return query
                                          }}
                                        />
                                      </div>
                                    </div>
                                    {getFormValueByIndex(
                                      index,
                                      "Mail_" + item.code
                                    ) &&
                                      getFormValueByIndex(
                                        index,
                                        "Mail_" + item.code
                                      ).map((item2, index2) => (
                                        <div
                                          className="col-lg-12"
                                          key={"mail" + index2}
                                        >
                                          <div className="form-group mb-3">
                                            <label htmlFor="simpleinput">
                                              {
                                                getFormValueByIndex(
                                                  index,
                                                  "Select_" + item.code
                                                )[index2]
                                              }{" "}
                                              - Mail Adresi
                                            </label>

                                            <input
                                              type="text"
                                              id="simpleinput"
                                              className="form-control"
                                              value={
                                                getFormValueByIndex(
                                                  index,
                                                  "Mail_" + item.code
                                                )[index2]
                                              }
                                              onChange={e =>
                                                setFormValueByIndex(
                                                  index,
                                                  "Mail_" + item.code,
                                                  e.target.value,
                                                  index2
                                                )
                                              }
                                              // onClick={() => {
                                              //   console.log(
                                              //     getFormValueByIndex(
                                              //       index,
                                              //       "Mail_" + item.code
                                              //     )
                                              //   )
                                              // }}
                                              required
                                            />
                                          </div>
                                        </div>
                                      ))}
                                  </Row>
                                </TabPane>
                              ))}
                          </TabContent>
                        </div>
                      </div>
                      {/*submit button right*/}
                      <div className="col-lg-12">
                        <div className="mt-4 d-flex justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-primary w-md  mb-2"
                          >
                            Güncelle
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger w-md mx-3 mb-2"
                            onClick={() => {
                              //rout Edit category
                              Navigate("/main-page")
                            }}
                          >
                            Geri
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MainPage
