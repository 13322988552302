import React from "react"
import { Container } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// Import Components
import CardWelcome from "./card-welcome"
import DataTableMantine from "components/Added/datatable"

const MainPage = () => {
  //meta title
  document.title = "Safkar Panel"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Panel" breadcrumbItem="Anasayfa" />

          <CardWelcome />

          {/* <DataTableMantine /> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MainPage
