import React from "react"
import {
  Container,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import { useParams, useNavigate } from "react-router-dom"

import classnames from "classnames"

import axios, { API_URL } from "lib/axios"

// Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import { toast } from "react-toastify"

const CertificateEdit = () => {
  const Navigate = useNavigate()
  const params = useParams()

  const [active, setActive] = React.useState(true)

  const [image, setImage] = React.useState("")

  const [form, setForm] = React.useState()

  const [file, setFile] = React.useState("")

  document.title = "Safkar Panel - Sertifika Düzenle"

  const [customActiveTab, setcustomActiveTab] = React.useState("0")

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  const [lang, setLang] = React.useState()

  React.useEffect(() => {
    axios
      .get("/language")
      .then(responseLang => {
        if (responseLang.data.languages) {
          setLang(responseLang.data.languages)

          axios
            .get(`/certificate?id=${params.id}`)
            .then(responseCertificate => {
              if (responseCertificate.data.certificate[0]) {
                setImage(responseCertificate.data.certificate[0].image)
                setActive(responseCertificate.data.certificate[0].active)

                const newForm = responseLang.data.languages.map(language => {
                  var certificateLangRow =
                    responseCertificate.data.certificate[0].certificatelang.find(
                      item => item.languageId == language.id
                    )

                  if (certificateLangRow) {
                    return {
                      ["title_" + language.code]: certificateLangRow.title,
                      id: responseCertificate.data.certificate[0].id,
                    }
                  } else {
                    return {
                      ["title_" + language.code]: "",
                      id: responseCertificate.data.certificate[0].id,
                    }
                  }
                })
                setForm(newForm)
                setFile(responseCertificate.data.certificate[0].image)
              }
            })
            .catch(error => {
              console.log(error)
            })
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const isCertificateForm = obj => {
    return true
  }

  const setFormValueByIndex = (index, key, value) => {
    const newForm = [...form]
    if (isCertificateForm(newForm[index])) {
      newForm[index][key] = value.toString()
    }
    setForm(newForm)
  }

  const getFormValueByIndex = (index, key) => {
    if (!form) return "" // form undefined veya null ise boş string döndür
    const newForm = [...form]
    if (isCertificateForm(newForm[index])) {
      if (newForm[index]) {
        return newForm[index][key]
      } else {
        return ""
      }
    }
    return ""
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Sertifikalar"
            breadcrumbItem="Sertifika Düzenle"
          />

          {/*Render Form */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form
                    onSubmit={e => {
                      e.preventDefault()
                      var arr = {}
                      form.forEach(item => {
                        Object.keys(item).forEach(key => {
                          arr[key] = item[key]
                        })
                      })

                      if (file && typeof file === "object") {
                        if (
                          file.type != "image/png" &&
                          file.type != "image/jpeg" &&
                          file.type != "image/jpg"
                        ) {
                          toast.error("Resim PNG, JPEG veya JPG olmalidir")
                          return
                        }
                      }

                      arr["image"] = image
                      arr["active"] = active
                      axios
                        .put("/certificate", arr)
                        .then(response => {
                          if (response.status == 200) {
                            toast.success("Sertifika Güncellendi")
                          }

                          if (typeof file === "object") {
                            var formData = new FormData()
                            formData.append("images", file)
                            formData.append(
                              "certificateId",
                              response.data.certificate.id
                            )
                            axios
                              .post("/certificate/image", formData)
                              .then(response => {
                                setTimeout(() => {
                                  setImage(
                                    response.data.certificateimage[0].image
                                  )
                                }, 500)
                              })
                          }
                        })
                        .catch(error => {
                          console.log(error)
                          toast("Sertifika Güncellenemedi")
                        })
                    }}
                  >
                    <h4 className="card-title">Sertifika Düzenle</h4>
                    <p className="card-title-desc">Sertifika Düzenle</p>
                    <button
                      type="button"
                      className="btn btn-danger w-md mx-3 mb-2"
                      style={{
                        position: "absolute",
                        right: "0",
                        top: "20px",
                      }}
                      onClick={() => {
                        Navigate("/certificate")
                      }}
                    >
                      Geri
                    </button>
                    <div className="row">
                      <div className="col-lg-4">
                        {/*category name*/}
                        <div className="mb-3">
                          <label
                            htmlFor="certificateImage"
                            className="form-label"
                          >
                            Sertifika Resmi
                          </label>
                          <input
                            id="certificateImage"
                            name="certificateImage"
                            type="file"
                            className="form-control"
                            accept=".png, .jpeg, .jpg"
                            placeholder="Sertifika Resmi"
                            onChange={e => {
                              setFile(e.target.files[0])
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        {/*category name*/}
                        <div className="mb-3">
                          <label htmlFor="categoryname" className="form-label">
                            Aktiflik
                          </label>
                          <div className="form-check form-switch form-switch-lg mb-3">
                            {active != null && (
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwitchsizelg"
                                defaultChecked={active ? true : false}
                                onChange={e => {
                                  setActive(e.target.checked)
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        {/*category image*/}
                        <div className="mb-3 border ">
                          {file && (
                            <img
                              src={
                                typeof file === "string"
                                  ? `${API_URL}/api/image?image=${file.replaceAll(
                                      " ",
                                      "%20"
                                    )}&path=certificate`
                                  : URL.createObjectURL(file)
                              }
                              alt="certificate image"
                              style={{ width: "100%" }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <Nav tabs className="nav-tabs-custom nav-justified">
                          {lang &&
                            lang.map((item, index) => (
                              <NavItem key={index}>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active:
                                      customActiveTab === index.toString(),
                                  })}
                                  onClick={() => {
                                    toggleCustom(index.toString())
                                  }}
                                >
                                  <span className="d-block d-sm-none">
                                    {item.displayname}
                                  </span>
                                  <span className="d-none d-sm-block">
                                    {item.displayname}
                                  </span>
                                </NavLink>
                              </NavItem>
                            ))}
                        </Nav>

                        <TabContent
                          activeTab={customActiveTab}
                          className="p-3 text-muted"
                        >
                          {form &&
                            lang &&
                            lang.map((item, index) => (
                              <TabPane tabId={index.toString()} key={index}>
                                <Row>
                                  <Col sm="12" lg="12">
                                    <div className="mt-4">
                                      <label
                                        htmlFor="certificateTitle"
                                        className="form-label"
                                      >
                                        Sertifika Başlığı
                                      </label>

                                      <input
                                        id="certificateTitle"
                                        name="certificateTitle"
                                        type="text"
                                        className="form-control"
                                        placeholder="Sertifika Başlığı"
                                        value={getFormValueByIndex(
                                          index,
                                          "title_" + item.code
                                        )}
                                        onChange={e =>
                                          setFormValueByIndex(
                                            index,
                                            "title_" + item.code,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </TabPane>
                            ))}
                        </TabContent>
                      </div>
                      <div className="col-lg-12">
                        <div className="mt-4 d-flex justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-primary w-md  mb-2"
                          >
                            Düzenle
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger w-md mx-3 mb-2"
                            onClick={() => {
                              Navigate("/certificate")
                            }}
                          >
                            Geri
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CertificateEdit
