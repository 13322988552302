import React, { useEffect } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { logoutUser } from "../../store/actions"

//redux
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import axios from "lib/axios"

const Logout = () => {
  const history = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(logoutUser(history))
    localStorage.removeItem("userToken")
    localStorage.removeItem("authUser")
    async function removeToken() {
      await axios.post("/auth/logout")
    }

    removeToken()
  }, [dispatch, history])

  return <></>
}

Logout.propTypes = {
  history: PropTypes.object,
}

export default withRouter(Logout)
