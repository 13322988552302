import React from "react"
import { Button, Container } from "reactstrap"
import {
  Text,
  Group,
  ActionIcon,
  BackgroundImage,
  TextInput,
} from "@mantine/core"
import { IconEdit, IconTrash, IconMenu2, IconSearch } from "@tabler/icons-react"
import axios from "lib/axios"
import { useNavigate } from "react-router-dom"
import Breadcrumbs from "components/Common/Breadcrumb"
import DataTableMantine from "components/Added/datatable"
import DeleteModal from "components/Common/DeleteModal"
import { toast } from "react-toastify"

const Blog = () => {
  const Navigate = useNavigate()
  const [data, setData] = React.useState()

  React.useEffect(() => {
    axios
      .get("/blog")
      .then(response => {
        if (response.data.blogs) {
          setData({ blogs: response.data.blogs })
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  document.title = "Safkar Panel - Bloglar"

  const [blog, setBlog] = React.useState(null)
  const [deleteModal, setDeleteModal] = React.useState(false)

  const onClickDelete = blog => {
    setBlog(blog)
    setDeleteModal(true)
  }

  const handleDeleteBlog = () => {
    if (blog && blog.id) {
      let arr = {}
      arr["id"] = blog.id
      axios
        .delete("/blog?id=" + blog.id)
        .then(res => {
          toast.success("Blog başarıyla silindi.")
          setDeleteModal(false)
          setData({
            blogs: data.blogs.filter(item => item.id !== blog.id),
          })
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  return (
    <React.Fragment>
      {blog && (
        <DeleteModal
          show={deleteModal}
          text={
            blog.bloglang[0].title +
            " blogunu silmek istediğinize emin misiniz?"
          }
          onDeleteClick={handleDeleteBlog}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Panel" breadcrumbItem="Bloglar" />

          <Group position="right" spacing={4} mt="md">
            <button
              className="btn btn-primary mb-2"
              onClick={() => {
                Navigate("/blog/add")
              }}
            >
              Blog Ekle
            </button>
          </Group>

          {data && (
            <DataTableMantine
              filterField={"bloglang[0].title"}
              columns={[
                {
                  name: "ID",
                  selector: row => row.id,
                  sortable: true,
                  width: "50px",
                  textAlign: "center", // Combined "right" and "Center" into one; you may need to adjust
                },
                {
                  name: "Blog Adı",
                  selector: row => row.bloglang[0].title, // Use function-based selector
                  sortable: true,
                },
                {
                  name: <Text mr="xs">Islemler</Text>,
                  width: "110px",
                  textAlign: "center",
                  cell: item => (
                    <Group spacing={4} position="center" noWrap>
                      <ActionIcon
                        color="blue"
                        onClick={() => Navigate("/blog/edit/" + item.id)}
                      >
                        <IconEdit size={16} />
                      </ActionIcon>
                      <ActionIcon
                        color="red"
                        onClick={() => onClickDelete(item)}
                      >
                        <IconTrash size={16} />
                      </ActionIcon>
                    </Group>
                  ),
                  ignoreRowClick: true,
                  allowOverflow: true,
                  button: true,
                },
              ]}
              data={data.blogs}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Blog
