import React from "react"
import {
  Container,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import { useNavigate } from "react-router-dom"

import classnames from "classnames"

import axios from "lib/axios"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// Import Components

import { toast } from "react-toastify"

import { CKEditor } from "@ckeditor/ckeditor5-react"
import { Editor } from "ckeditor5-custom-build/build/ckeditor"

const MainPage = () => {
  const Navigate = useNavigate()
  // all variables
  const [file, setFile] = React.useState()
  const [active, setActive] = React.useState(true)
  const [display, setDisplay] = React.useState(false)

  const [form, setForm] = React.useState()

  const [fileC, setFileC] = React.useState(null)

  //meta title
  document.title = "Safkar Panel - Kategori Ekle"

  const [customActiveTab, setcustomActiveTab] = React.useState("0")

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  const [lang, setLang] = React.useState()

  React.useEffect(() => {
    axios
      .get("/language")
      .then(responseLang => {
        if (responseLang.data.languages) {
          setLang(responseLang.data.languages)

          const newForm = responseLang.data.languages.map(language => {
            return {
              ["title_" + language.code]: "",
              ["content_" + language.code]: "",
              ["table_" + language.code]: "",
              active: "1",
            }
          })

          setForm(newForm)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const isCategoryForm = obj => {
    // Gerekli özelliklerin varlığını kontrol ederek
    // obj'nin CategoryForm tipinde olduğunu doğrulayabilirsiniz
    // Örnek olarak: return 'property1' in obj && 'property2' in obj;
    return true
  }

  const onSubmit = async e => {
    e.preventDefault()

    let aggregatedFormData = {}

    // Aggregate form data
    form.forEach(item => {
      Object.assign(aggregatedFormData, item)
    })

    aggregatedFormData = {
      ...aggregatedFormData,
      active,
      display,
    }

    try {
      // Validate File Types for fileC if it exists
      if (fileC && fileC.type !== "application/pdf") {
        toast.error("Dosya PDF olmalı")
        return
      }

      // Validate File Types for file if it exists
      if (
        file &&
        !["image/png", "image/jpeg", "image/jpg"].includes(file.type)
      ) {
        toast.error("Resim PNG, JPEG veya JPG olmalidir")
        return
      }

      // Upload PDF File if it exists
      if (fileC) {
        const pdfFormData = new FormData()
        pdfFormData.append("files", fileC)
        const pdfResponse = await axios.post("/category/pdf", pdfFormData)

        if (pdfResponse.status === 200) {
          aggregatedFormData.file = pdfResponse.data.files[0].filename
        }
      }

      // Create Category
      const categoryResponse = await axios.post("/category", aggregatedFormData)

      if (categoryResponse.status === 200) {
        toast.success("Kategori Eklendi")

        // Upload Category Image if it exists
        if (file) {
          const imageFormData = new FormData()
          imageFormData.append("images", file)
          imageFormData.append("categoryId", categoryResponse.data.category.id)

          await axios.post("/category/image", imageFormData)
        }

        Navigate("/category")
      }
    } catch (error) {
      console.log(error)
      toast.error("Kategori Eklenemedi")
    }
  }

  const setFormValueByIndex = (index, key, value) => {
    const newForm = [...form]
    if (isCategoryForm(newForm[index])) {
      newForm[index][key] = value.toString()
    }
    setForm(newForm)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Kategory" breadcrumbItem="Kategori Ekle" />

          {/* Render Form */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={onSubmit}>
                    <h4 className="card-title">Kategori Ekle</h4>
                    <p className="card-title-desc">Kategori Ekle</p>
                    {/* back button */}
                    <button
                      type="button"
                      className="btn btn-danger w-md mx-3 mb-2"
                      style={{
                        position: "absolute",
                        right: "0",
                        top: "20px",
                      }}
                      onClick={() => {
                        //rout Edit category
                        Navigate("/category")
                      }}
                    >
                      Geri
                    </button>
                    <div className="row">
                      <div className="col-lg-8">
                        {/* two colum*/}
                        <div className="row">
                          <div className="col-lg-8">
                            {/*category name*/}
                            <div className="mb-3">
                              <label
                                htmlFor="categoryname"
                                className="form-label"
                              >
                                Kategori Resmi
                              </label>
                              <input
                                id="categoryname"
                                name="categoryname"
                                type="file"
                                accept=".png, .jpeg, .jpg"
                                className="form-control"
                                onChange={e => {
                                  setFile(e.target.files[0])
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-lg-2">
                            {/*category name*/}
                            <div className="mb-3">
                              <label
                                htmlFor="categoryname"
                                className="form-label"
                              >
                                Aktiflik
                              </label>
                              <div className="form-check form-switch form-switch-lg mb-3">
                                {active != null && (
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="customSwitchsizelg"
                                    defaultChecked={active ? true : false}
                                    onChange={e => {
                                      setActive(e.target.checked)
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2">
                            {/*category name*/}
                            <div className="mb-3">
                              <label
                                htmlFor="categoryname"
                                className="form-label"
                              >
                                {display ? "Resim" : "Isim"}
                              </label>
                              <div className="form-check form-switch form-switch-lg mb-3">
                                {display != null && (
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="customSwitchsizelg"
                                    defaultChecked={display ? true : false}
                                    onChange={e => {
                                      setDisplay(e.target.checked)
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-8">
                            {/*category name*/}
                            <div className="mb-3">
                              <label
                                htmlFor="categoryname"
                                className="form-label"
                              >
                                Dosya
                              </label>
                              <input
                                id="categoryname"
                                name="categoryname"
                                type="file"
                                accept="application/pdf"
                                className="form-control"
                                placeholder="Dosya"
                                onChange={e => {
                                  setFileC(e.target.files[0])
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        {/*category image*/}
                        <div className="mb-3  "></div>
                      </div>
                      <div className="col-lg-12">
                        <Nav tabs className="nav-tabs-custom nav-justified">
                          {lang &&
                            lang.map((item, index) => (
                              <NavItem key={index}>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active:
                                      customActiveTab === index.toString(),
                                  })}
                                  onClick={() => {
                                    toggleCustom(index.toString())
                                  }}
                                >
                                  <span className="d-block d-sm-none">
                                    {item.displayname}
                                  </span>
                                  <span className="d-none d-sm-block">
                                    {item.displayname}
                                  </span>
                                </NavLink>
                              </NavItem>
                            ))}
                        </Nav>

                        <TabContent
                          activeTab={customActiveTab}
                          className="p-3 text-muted"
                        >
                          {form &&
                            lang &&
                            lang.map((item, index) => (
                              <TabPane tabId={index.toString()} key={index}>
                                <Row>
                                  <Col sm="12" lg="6">
                                    <div className="mt-4">
                                      <label
                                        htmlFor="categoryname"
                                        className="form-label"
                                      >
                                        Kategori Adi
                                      </label>

                                      <input
                                        id="categoryname"
                                        name="categoryname"
                                        type="text"
                                        className="form-control"
                                        placeholder="Kategori Adi"
                                        onChange={e =>
                                          setFormValueByIndex(
                                            index,
                                            "title_" + item.code,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </Col>
                                  <Col sm="12" lg="6">
                                    <div className="mt-4">
                                      <label
                                        htmlFor="categoryname"
                                        className="form-label"
                                      >
                                        Kategori Icerik
                                      </label>

                                      <input
                                        id="categoryname"
                                        name="categoryname"
                                        type="text"
                                        className="form-control"
                                        placeholder="Kategori Adi"
                                        onChange={e =>
                                          setFormValueByIndex(
                                            index,
                                            "content_" + item.code,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </Col>
                                  <Col sm="12">
                                    <div className="mt-4">
                                      <label
                                        htmlFor="categoryTable"
                                        className="form-label"
                                      >
                                        Kategori Tablo
                                      </label>
                                      <CKEditor
                                        editor={Editor}
                                        data={form[index]["table_" + item.code]}
                                        onChange={(event, editor) => {
                                          const data = editor.getData()
                                          setFormValueByIndex(
                                            index,
                                            "table_" + item.code,
                                            data
                                          )
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </TabPane>
                            ))}
                        </TabContent>
                      </div>
                      {/*submit button right*/}
                      <div className="col-lg-12">
                        <div className="mt-4 d-flex justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-primary w-md  mb-2"
                          >
                            Ekle
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger w-md mx-3 mb-2"
                            onClick={() => {
                              //rout Edit category
                              Navigate("/category")
                            }}
                          >
                            Geri
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MainPage
