import { ActionIcon, Group, Modal, Select, Text } from "@mantine/core"
import { IconEdit, IconTrash } from "@tabler/icons-react"
import classNames from "classnames"
import DataTableMantine from "components/Added/datatable"
import DeleteModal from "components/Common/DeleteModal"
import axios from "lib/axios"
import React from "react"
import { useState } from "react"
import { useEffect } from "react"
import { toast } from "react-toastify"
import {
  Nav,
  NavItem,
  TabContent,
  TabPane,
  NavLink,
  Container,
} from "reactstrap"
import { useDisclosure } from "@mantine/hooks"
import Breadcrumbs from "../../../components/Common/Breadcrumb"

const Index = () => {
  const [country, setCountry] = useState([])
  const [order, setOrder] = React.useState(null)
  const [deleteModal, setDeleteModal] = React.useState(false)
  const [opened, { open, close }] = useDisclosure(false)
  const [countryData, setCountryData] = React.useState([])

  const [name, setName] = useState("")
  const [selectedCountry, setSelectedCountry] = useState("")

  const [updateData, setUpdateData] = React.useState(null)

  const [customActiveTab, setcustomActiveTab] = React.useState("0")

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  useEffect(() => {
    axios.get("/country/countryProvince").then(response => {
      setCountry(response.data)
      setCountryData(
        response.data.map(item => {
          return { value: item.id, label: item.name }
        })
      )
    })
  }, [])

  const onClickDelete = order => {
    setOrder(order)
    setDeleteModal(true)
  }

  const handleDeleteOrder = () => {
    if (order && order.id) {
      axios
        .delete("/province", {
          data: { id: order.id },
        })
        .then(async res => {
          toast.success("Şehir başarıyla silindi.")
          setDeleteModal(false)
          await axios.get("/country/countryProvince").then(response => {
            setCountry(response.data)
          })
        })
        .catch(err => {
          setDeleteModal(false)
          toast(
            `Bir hata oluştu. Lütfen ${order.name} Şehrine bağlı herhangi bir Bakım & Servis noktası olup olmadığını kontrol ediniz`,
            { type: "error" }
          )
        })
    }
  }

  const handleUpdate = () => {
    if (updateData && updateData.id) {
      axios
        .put("/province", updateData)
        .then(res => {
          toast.success("Şehir başarıyla güncellendi.")
          setUpdateData(null)
          close()
          axios.get("/country/countryProvince").then(response => {
            setCountry(response.data)
          })
        })
        .catch(err => {
          toast("Bir hata oluştu.", { type: "error" })
        })
    }
  }

  const handleSubmit = () => {
    if (name && selectedCountry) {
      axios

        .post("/province", {
          name: name,
          countryId: selectedCountry,
        })
        .then(res => {
          toast.success("Şehir başarıyla eklendi.")
          close()
          setName("")
          setSelectedCountry("")
          axios.get("/country/countryProvince").then(response => {
            setCountry(response.data)
          })
        })
        .catch(err => {
          toast("Bir hata oluştu.", { type: "error" })
        })
    }
  }

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => {
          close()
          setTimeout(() => {
            setUpdateData(null)
          }, 150)
        }}
        size="55rem"
        title={updateData ? "Şehir Güncelle" : "Şehir Ekle"}
        centered
      >
        {updateData ? (
          <div className="mt-2">
            <div>
              <label htmlFor="categoryname" className="form-label">
                Şehir Adı
              </label>
              <input
                id="categoryname"
                name="categoryname"
                type="text"
                className="form-control"
                placeholder="Şehir Adı"
                value={updateData.name}
                onChange={e =>
                  setUpdateData({ ...updateData, name: e.target.value })
                }
              />
            </div>
            <button
              onClick={handleUpdate}
              className="btn btn-primary w-md mt-4"
            >
              Güncelle
            </button>
          </div>
        ) : (
          <div className="mt-2 p-4">
            <div>
              <label htmlFor="categoryname" className="form-label">
                Şehir Adı
              </label>
              <input
                id="categoryname"
                name="categoryname"
                type="text"
                className="form-control"
                placeholder="Şehir Adı"
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </div>
            <div className="mt-2">
              <label htmlFor="categoryname" className="form-label">
                Ülke Adı
              </label>
              <Select
                placeholder="Bölge Seçiniz"
                nothingFound="Herhangi bir bölge bulunamadı"
                searchable
                creatable
                dropdownPosition="bottom"
                onChange={e => {
                  setSelectedCountry(e)
                }}
                data={countryData}
              />
            </div>
            <button
              onClick={handleSubmit}
              className="btn btn-primary w-md mt-4"
            >
              Ekle
            </button>
          </div>
        )}
      </Modal>
      {country.length > 0 && (
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Şehir" breadcrumbItem="Şehirler" />
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <button
                type="button"
                className="btn btn-info w-md mx-3 mb-2"
                onClick={() => {
                  open()
                }}
              >
                Ekle
              </button>
            </div>
            {order && (
              <DeleteModal
                show={deleteModal}
                text={order.name + " Şehrini silmek istediğinize emin misiniz?"}
                onDeleteClick={handleDeleteOrder}
                onCloseClick={() => setDeleteModal(false)}
              />
            )}

            <Nav tabs className="nav-tabs-custom nav-justified">
              {country.map((item, index) => (
                <NavItem key={index}>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classNames({
                      active: customActiveTab === index.toString(),
                    })}
                    onClick={() => {
                      toggleCustom(index.toString())
                    }}
                  >
                    <span className="d-block d-sm-none">{item.name}</span>
                    <span className="d-none d-sm-block">{item.name}</span>
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <TabContent activeTab={customActiveTab} className="p-3 text-muted">
              {country.map((item, index) => (
                <TabPane tabId={index.toString()} key={index}>
                  <DataTableMantine
                    data={item.provinces}
                    filterField={"name"}
                    columns={[
                      {
                        name: "id",
                        selector: row => row.id,
                        sortable: true,
                        width: 50,
                        textAlign: "center", // Removed duplicate textAlignment and kept "Center" as the value
                      },
                      {
                        name: "Şehir Adı",
                        selector: row => row.name,
                      },
                      {
                        name: <Text mr="xs">Islemler</Text>,
                        width: 110,
                        textAlign: "center",
                        cell: item => (
                          <Group spacing={4} position="center" noWrap>
                            <ActionIcon
                              color="blue"
                              onClick={() => {
                                open()
                                setUpdateData(item)
                              }}
                            >
                              <IconEdit size={16} />
                            </ActionIcon>
                            <ActionIcon
                              color="red"
                              onClick={() => onClickDelete(item)}
                            >
                              <IconTrash size={16} />
                            </ActionIcon>
                          </Group>
                        ),
                        ignoreRowClick: true,
                        allowOverflow: true,
                        button: true,
                      },
                    ]}
                  />
                </TabPane>
              ))}
            </TabContent>
          </Container>
        </div>
      )}
    </>
  )
}

export default Index
