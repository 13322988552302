import React from "react"
import {
  Container,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import { useParams, useNavigate } from "react-router-dom"

import classnames from "classnames"

import axios, { API_URL } from "lib/axios"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import moment from "moment"
import { useEffect } from "react"

import { CKEditor } from "@ckeditor/ckeditor5-react"
import { Editor } from "ckeditor5-custom-build/build/ckeditor"

import { toast } from "react-toastify"
import { set } from "lodash"

// Import Components

const MainPage = () => {
  const Navigate = useNavigate()
  // all variables
  const [file, setFile] = React.useState()
  const [active, setActive] = React.useState(null)

  const [form, setForm] = React.useState()
  const [startingDate, setStartingDate] = React.useState()
  const [endingDate, setEndingDate] = React.useState()

  const params = useParams()

  //meta title
  document.title = "Safkar Panel - Popup Duzenle"

  const [customActiveTab, setcustomActiveTab] = React.useState("0")

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  const [lang, setLang] = React.useState()

  React.useEffect(() => {
    axios
      .get("/language")
      .then(responseLang => {
        if (responseLang.data.languages) {
          setLang(responseLang.data.languages)
          axios
            .get(`/popup/${params.id}`)
            .then(res => {
              if (res.data.popup) {
                setData({ categories: res.data.popup })
                setFile(res.data.popup.image)
                if (res.data.popup.endingDate) {
                  setEndingDate(
                    moment(new Date(res.data.popup.endingDate)).format(
                      "YYYY-MM-DD"
                    )
                  )
                }
                setStartingDate(
                  moment(new Date(res.data.popup.startingDate)).format(
                    "YYYY-MM-DD"
                  )
                )
                setActive(res.data.popup.active)
              }

              const newForm = responseLang.data.languages.map(language => {
                var popupLangRow = res.data.popup.popuplang.find(
                  item => item.languageId == language.id
                )

                if (popupLangRow) {
                  return {
                    ["title_" + language.code]: popupLangRow.title,
                    ["content_" + language.code]: popupLangRow.content,
                    ["link_" + language.code]: popupLangRow.link,
                    id: res.data.popup.id,
                  }
                } else {
                  return {
                    ["title_" + language.code]: "",
                    ["content_" + language.code]: "",
                    ["link_" + language.code]: "",
                    id: res.data.popup.id,
                  }
                }
              })
              setForm(newForm)
            })
            .catch(error => {
              console.log(error)
            })
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const [data, setData] = React.useState()

  const isCategoryForm = obj => {
    // Gerekli özelliklerin varlığını kontrol ederek
    // obj'nin CategoryForm tipinde olduğunu doğrulayabilirsiniz
    // Örnek olarak: return 'property1' in obj && 'property2' in obj;
    return true
  }

  const setFormValueByIndex = (index, key, value) => {
    const newForm = [...form]
    if (isCategoryForm(newForm[index])) {
      newForm[index][key] = value.toString()
    }
    setForm(newForm)
  }

  const getFormValueByIndex = (index, key) => {
    const newForm = [...form]
    if (isCategoryForm(newForm[index])) {
      //if newform has key
      if (newForm[index]) {
        return newForm[index][key]
      } else {
        return ""
      }
    }
    return ""
  }

  return (
    <React.Fragment>
      {data && (
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Popup" breadcrumbItem="Popup Duzenle" />

            {/* Render Form */}
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <form
                      onSubmit={e => {
                        e.preventDefault()
                        var arr = {}
                        form.forEach(item => {
                          Object.keys(item).forEach(key => {
                            arr[key] = item[key]
                          })
                        })
                        arr["active"] = active
                        if (startingDate) {
                          arr["startingDate"] = startingDate
                        }
                        if (endingDate) {
                          arr["endingDate"] = endingDate
                        }

                        if (file && typeof file === "object") {
                          if (
                            file.type != "image/png" &&
                            file.type != "image/jpeg" &&
                            file.type != "image/jpg"
                          ) {
                            toast.error("Resim PNG, JPEG veya JPG olmalidir")
                            return
                          }
                        }

                        axios
                          .put("/popup", arr)
                          .then(response => {
                            if (typeof file === "object") {
                              const formData = new FormData()
                              formData.append("images", file)
                              formData.append("popupId", response.data.id)
                              axios
                                .post("/popup/image", formData)
                                .then(response => {})
                            }
                            toast.success("Popup basariyla duzenlendi")
                          })
                          .catch(error => {
                            console.log(error)
                          })
                      }}
                    >
                      <h4 className="card-title">Popup</h4>
                      <p className="card-title-desc">Popup Duzenle</p>
                      {/* back button */}
                      <button
                        type="button"
                        className="btn btn-danger w-md mx-3 mb-2"
                        style={{
                          position: "absolute",
                          right: "0",
                          top: "20px",
                        }}
                        onClick={() => {
                          //rout Edit popup
                          Navigate("/popups")
                        }}
                      >
                        Geri
                      </button>
                      <div className="row">
                        <div className="col-lg-8">
                          {/* two colum*/}
                          <div className="row">
                            <div
                              className="col-lg-12"
                              style={{ display: "flex" }}
                            >
                              {/*category name*/}
                              <div
                                className="mb-3"
                                style={{ flex: 1, marginRight: "10px" }}
                              >
                                <label
                                  htmlFor="categoryname"
                                  className="form-label"
                                >
                                  Popup Resmi
                                </label>
                                <input
                                  id="categoryname"
                                  name="categoryname"
                                  type="file"
                                  accept=".png,.jpg,.jpeg"
                                  className="form-control"
                                  onChange={e => {
                                    setFile(e.target.files[0])
                                  }}
                                />
                              </div>
                              {active != null && (
                                <div>
                                  {/*category name*/}
                                  <div className="mb-3">
                                    <label
                                      htmlFor="categoryname"
                                      className="form-label"
                                    >
                                      Aktiflik
                                    </label>
                                    <div className="form-check form-switch form-switch-lg mb-3">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="customSwitchsizelg"
                                        defaultChecked={active}
                                        onChange={e => {
                                          setActive(e.target.checked)
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="row">
                              <div className="col-lg-6">
                                {startingDate && (
                                  <div>
                                    <label
                                      htmlFor="example-date-input"
                                      className="col-form-label"
                                    >
                                      Başlangıç Tarihi
                                    </label>
                                    <div className="">
                                      <input
                                        className="form-control"
                                        type="date"
                                        defaultValue={startingDate}
                                        id="example-date-input"
                                        onChange={e => {
                                          setStartingDate(e.target.value)
                                        }}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="col-lg-6 mb-2">
                                <div>
                                  <label
                                    htmlFor="example-date-input"
                                    className=" col-form-label"
                                  >
                                    Bitiş Tarihi
                                  </label>
                                  <div className="">
                                    <input
                                      className="form-control"
                                      type="date"
                                      defaultValue={
                                        endingDate
                                          ? endingDate.toString()
                                          : null
                                      }
                                      id="example-date-input"
                                      onChange={e => {
                                        setEndingDate(e.target.value)
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          {/*category image*/}
                          <div className="mb-3 border ">
                            <img
                              src={
                                typeof file == "string"
                                  ? `${API_URL}/api/image?image=${file}&path=popup`
                                  : URL.createObjectURL(file)
                              }
                              alt="category image"
                              style={{ width: "100%" }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <Nav tabs className="nav-tabs-custom nav-justified">
                            {lang &&
                              lang.map((item, index) => (
                                <NavItem key={index}>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      active:
                                        customActiveTab === index.toString(),
                                    })}
                                    onClick={() => {
                                      toggleCustom(index.toString())
                                    }}
                                  >
                                    <span className="d-block d-sm-none">
                                      {item.displayname}
                                    </span>
                                    <span className="d-none d-sm-block">
                                      {item.displayname}
                                    </span>
                                  </NavLink>
                                </NavItem>
                              ))}
                          </Nav>

                          <TabContent
                            activeTab={customActiveTab}
                            className="p-3 text-muted"
                          >
                            {form &&
                              lang &&
                              lang.map((item, index) => (
                                <TabPane tabId={index.toString()} key={index}>
                                  <Row>
                                    <Col sm="12">
                                      <div className="mt-4">
                                        <label
                                          htmlFor="categoryname"
                                          className="form-label"
                                        >
                                          Popup Adi
                                        </label>

                                        <input
                                          id="categoryname"
                                          name="categoryname"
                                          type="text"
                                          className="form-control"
                                          placeholder="Popup Adi"
                                          defaultValue={getFormValueByIndex(
                                            index,
                                            "title_" + item.code
                                          )}
                                          onChange={e =>
                                            setFormValueByIndex(
                                              index,
                                              "title_" + item.code,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                    </Col>
                                    <Col sm="12">
                                      <div className="mt-4">
                                        <label
                                          htmlFor="categoryname"
                                          className="form-label"
                                        >
                                          Popup Icerik
                                        </label>
                                        <CKEditor
                                          editor={Editor}
                                          data={getFormValueByIndex(
                                            index,
                                            "content_" + item.code
                                          )}
                                          onChange={(e, editor) => {
                                            const data = editor.getData()
                                            setFormValueByIndex(
                                              index,
                                              "content_" + item.code,
                                              data
                                            )
                                          }}
                                        />
                                      </div>
                                      <div className="mt-4">
                                        <label
                                          htmlFor="categoryname"
                                          className="form-label"
                                        >
                                          Popup Linki
                                        </label>

                                        <input
                                          id="categoryname"
                                          name="categoryname"
                                          type="text"
                                          className="form-control"
                                          placeholder="Popup Linki"
                                          defaultValue={getFormValueByIndex(
                                            index,
                                            "link_" + item.code
                                          )}
                                          onChange={e =>
                                            setFormValueByIndex(
                                              index,
                                              "link_" + item.code,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </TabPane>
                              ))}
                          </TabContent>
                        </div>
                        {/*submit button right*/}
                        <div className="col-lg-12">
                          <div className="mt-4 d-flex justify-content-end">
                            <button
                              type="submit"
                              className="btn btn-primary w-md  mb-2"
                            >
                              Guncelle
                            </button>
                            <button
                              type="button"
                              className="btn btn-danger w-md mx-3 mb-2"
                              onClick={() => {
                                //rout Edit category
                                Navigate("/popups")
                              }}
                            >
                              Geri
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      )}
    </React.Fragment>
  )
}

export default MainPage
