import React, { useEffect, useRef, useCallback } from "react"
import { Navigate, useLocation } from "react-router-dom"
import PropTypes from "prop-types"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import withRouter from "components/Common/withRouter"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { useState } from "react"

const SidebarContent = props => {
  const ref = useRef()
  const [userRole, setUserRole] = useState(
    JSON.parse(localStorage.getItem("authUser")) || null
  )

  useEffect(() => {
    if (!userRole) {
      setUserRole(JSON.parse(localStorage.getItem("authUser")))
    }
  }, [userRole])

  if (userRole.role === "Admin") {
    const activateParentDropdown = useCallback(item => {
      item.classList.add("active")
      const parent = item.parentElement
      const parent2El = parent.childNodes[1]

      if (parent2El && parent2El.id !== "side-menu") {
        parent2El.classList.add("mm-show")
      }

      if (parent) {
        parent.classList.add("mm-active")
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.add("mm-show") // ul tag

          const parent3 = parent2.parentElement // li tag

          if (parent3) {
            parent3.classList.add("mm-active") // li
            parent3.childNodes[0].classList.add("mm-active") //a
            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.add("mm-show") // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.add("mm-show") // li
                parent5.childNodes[0].classList.add("mm-active") // a tag
              }
            }
          }
        }
        // scrollElement(item)
        return false
      }
      // scrollElement(item)
      return false
    }, [])

    const removeActivation = items => {
      for (var i = 0; i < items.length; ++i) {
        var item = items[i]
        const parent = items[i].parentElement

        if (item && item.classList.contains("active")) {
          item.classList.remove("active")
        }
        if (parent) {
          const parent2El =
            parent.childNodes &&
            parent.childNodes.lenght &&
            parent.childNodes[1]
              ? parent.childNodes[1]
              : null
          if (parent2El && parent2El.id !== "side-menu") {
            parent2El.classList.remove("mm-show")
          }

          parent.classList.remove("mm-active")
          const parent2 = parent.parentElement

          if (parent2) {
            parent2.classList.remove("mm-show")

            const parent3 = parent2.parentElement
            if (parent3) {
              parent3.classList.remove("mm-active") // li
              parent3.childNodes[0].classList.remove("mm-active")

              const parent4 = parent3.parentElement // ul
              if (parent4) {
                parent4.classList.remove("mm-show") // ul
                const parent5 = parent4.parentElement
                if (parent5) {
                  parent5.classList.remove("mm-show") // li
                  parent5.childNodes[0].classList.remove("mm-active") // a tag
                }
              }
            }
          }
        }
      }
    }

    const path = useLocation()
    const activeMenu = useCallback(() => {
      const pathName = path.pathname
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      removeActivation(items)

      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }, [path.pathname, activateParentDropdown])

    useEffect(() => {
      ref.current.recalculate()
    }, [])

    useEffect(() => {
      new MetisMenu("#side-menu")
      activeMenu()
    }, [])

    // useEffect(() => {
    //   window.scrollTo({ top: 0, behavior: "smooth" })
    //   activeMenu()
    // }, [activeMenu])

    // function scrollElement(item) {
    //   if (item) {
    //     const currentPosition = item.offsetTop
    //     if (currentPosition > window.innerHeight) {
    //       ref.current.getScrollElement().scrollTop = currentPosition - 300
    //     }
    //   }
    // }
    return (
      <React.Fragment>
        <SimpleBar className="h-100" ref={ref}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{props.t("Safkar")}</li>

              <li>
                <Link to="/category" className="waves-effect">
                  <i className="bx bx-customize"></i>
                  <span>{props.t("Kategoriler")}</span>
                </Link>
              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-home-circle"></i>
                  <span>{props.t("Urunler")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/topproductcomponent">
                      {props.t("Urun Dil Ayari")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/products">{props.t("Urunler")}</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/popups" className="waves-effect">
                  <i className="bx bx-notification"></i>
                  <span>{props.t("Popuplar")}</span>
                </Link>
              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-question-mark"></i>
                  <span>{props.t("SSS")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/sss">{props.t("SSS Sayfası Ayarları")}</Link>
                  </li>
                  <li>
                    <Link to="/faq">{props.t("SSS")}</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-cog"></i>
                  <span>{props.t("Genel Site Ayarlari")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/page-settings">{props.t("Site Ayarlari")}</Link>
                  </li>
                  <li>
                    <Link to="/kvkk">{props.t("KVKK")}</Link>
                  </li>
                  <li>
                    <Link to="/text">{props.t("Aydinlatma Metni")}</Link>
                  </li>
                  <li>
                    <Link to="/arge">{props.t("Arge Yazisi")}</Link>
                  </li>
                  <li>
                    <Link to="/about">{props.t("Hakkimizda")}</Link>
                  </li>
                  <li>
                    <Link to="/whyus">{props.t("Neden Biz")}</Link>
                  </li>
                  <li>
                    <Link to="/smtp">{props.t("SMTP")}</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/social" className="waves-effect">
                  <i className="bx bx-user-voice"></i>
                  <span>{props.t("Sosyal")}</span>
                </Link>
              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bxs-user-detail"></i>
                  <span>{props.t("Ekip")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/teamlang">{props.t("Ekip Dil Ayari")}</Link>
                  </li>
                  <li>
                    <Link to="/team">{props.t("Ekip")}</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/certificate" className="waves-effect">
                  <i className="bx bx-certification"></i>
                  <span>{props.t("Sertifikalar")}</span>
                </Link>
              </li>

              <li>
                <Link to="/slider" className="waves-effect">
                  <i className="bx bx-code-block"></i>
                  <span>{props.t("Slider")}</span>
                </Link>
              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-detail"></i>
                  <span>{props.t("Blog")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/news">{props.t("Haber Dil Ayari")}</Link>
                  </li>
                  <li>
                    <Link to="/blog">{props.t("Blog")}</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/history" className="waves-effect">
                  <i className="bx bx-history"></i>
                  <span>{props.t("Gecmis")}</span>
                </Link>
              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-directions"></i>
                  <span>{props.t("Satis Servis")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/service-sales/page">
                      {props.t("Sayfa Dil Ayari")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/servicesales">{props.t("Noktalar")}</Link>
                  </li>
                  <li>
                    <Link to="/country">{props.t("Bolgeler")}</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bxs-contact"></i>
                  <span>{props.t("Iletisim")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/contact-form">
                      {props.t("Iletisim Dil Ayari")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact">{props.t("Iletisim")}</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bxs-file-plus"></i>
                  <span>{props.t("Yatirimci Iliskileri")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/infos">{props.t("Sirket Bilgileri")}</Link>
                  </li>
                  <li>
                    <Link to="/comps">{props.t("Icerikler")}</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/file" className="waves-effect">
                  <i className="bx bxs-file-pdf"></i>
                  <span>{props.t("Dosya")}</span>
                </Link>
              </li>

              <li>
                <Link to="/qr" className="waves-effect">
                  <i className="mdi mdi-qrcode"></i>
                  <span>{props.t("QR")}</span>
                </Link>
              </li>

              <li>
                <Link to="/qrcard" className="waves-effect">
                  <i className="mdi mdi-qrcode"></i>
                  <span>{props.t("QR Kartvizit")}</span>
                </Link>
              </li>

              <li>
                <Link to="/footer" className="waves-effect">
                  <i className="bx bx-grid-horizontal"></i>
                  <span>{props.t("Footer")}</span>
                </Link>
              </li>

              <li>
                <Link to="/users" className="waves-effect">
                  <i className="bx bx-user"></i>
                  <span>{props.t("Kullanıcılar")}</span>
                </Link>
              </li>
            </ul>
          </div>
        </SimpleBar>
      </React.Fragment>
    )
  } else if (userRole.role === "Super_Admin") {
    const activateParentDropdown = useCallback(item => {
      item.classList.add("active")
      const parent = item.parentElement
      const parent2El = parent.childNodes[1]

      if (parent2El && parent2El.id !== "side-menu") {
        parent2El.classList.add("mm-show")
      }

      if (parent) {
        parent.classList.add("mm-active")
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.add("mm-show") // ul tag

          const parent3 = parent2.parentElement // li tag

          if (parent3) {
            parent3.classList.add("mm-active") // li
            parent3.childNodes[0].classList.add("mm-active") //a
            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.add("mm-show") // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.add("mm-show") // li
                parent5.childNodes[0].classList.add("mm-active") // a tag
              }
            }
          }
        }
        // scrollElement(item)
        return false
      }
      // scrollElement(item)
      return false
    }, [])

    const removeActivation = items => {
      for (var i = 0; i < items.length; ++i) {
        var item = items[i]
        const parent = items[i].parentElement

        if (item && item.classList.contains("active")) {
          item.classList.remove("active")
        }
        if (parent) {
          const parent2El =
            parent.childNodes &&
            parent.childNodes.lenght &&
            parent.childNodes[1]
              ? parent.childNodes[1]
              : null
          if (parent2El && parent2El.id !== "side-menu") {
            parent2El.classList.remove("mm-show")
          }

          parent.classList.remove("mm-active")
          const parent2 = parent.parentElement

          if (parent2) {
            parent2.classList.remove("mm-show")

            const parent3 = parent2.parentElement
            if (parent3) {
              parent3.classList.remove("mm-active") // li
              parent3.childNodes[0].classList.remove("mm-active")

              const parent4 = parent3.parentElement // ul
              if (parent4) {
                parent4.classList.remove("mm-show") // ul
                const parent5 = parent4.parentElement
                if (parent5) {
                  parent5.classList.remove("mm-show") // li
                  parent5.childNodes[0].classList.remove("mm-active") // a tag
                }
              }
            }
          }
        }
      }
    }

    const path = useLocation()
    const activeMenu = useCallback(() => {
      const pathName = path.pathname
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      removeActivation(items)

      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }, [path.pathname, activateParentDropdown])

    useEffect(() => {
      ref.current.recalculate()
    }, [])

    useEffect(() => {
      new MetisMenu("#side-menu")
      activeMenu()
    }, [])

    // useEffect(() => {
    //   window.scrollTo({ top: 0, behavior: "smooth" })
    //   activeMenu()
    // }, [activeMenu])

    // function scrollElement(item) {
    //   if (item) {
    //     const currentPosition = item.offsetTop
    //     if (currentPosition > window.innerHeight) {
    //       ref.current.getScrollElement().scrollTop = currentPosition - 300
    //     }
    //   }
    // }
    return (
      <React.Fragment>
        <SimpleBar className="h-100" ref={ref}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{props.t("Safkar")}</li>

              <li>
                <Link to="/category" className="waves-effect">
                  <i className="bx bx-customize"></i>
                  <span>{props.t("Kategoriler")}</span>
                </Link>
              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-home-circle"></i>
                  <span>{props.t("Urunler")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/topproductcomponent">
                      {props.t("Urun Dil Ayari")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/products">{props.t("Urunler")}</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/popups" className="waves-effect">
                  <i className="bx bx-notification"></i>
                  <span>{props.t("Popuplar")}</span>
                </Link>
              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-question-mark"></i>
                  <span>{props.t("SSS")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/sss">{props.t("SSS Sayfası Ayarları")}</Link>
                  </li>
                  <li>
                    <Link to="/faq">{props.t("SSS")}</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-cog"></i>
                  <span>{props.t("Genel Site Ayarlari")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/page-settings">{props.t("Site Ayarlari")}</Link>
                  </li>
                  <li>
                    <Link to="/kvkk">{props.t("KVKK")}</Link>
                  </li>
                  <li>
                    <Link to="/text">{props.t("Aydinlatma Metni")}</Link>
                  </li>
                  <li>
                    <Link to="/arge">{props.t("Arge Yazisi")}</Link>
                  </li>
                  <li>
                    <Link to="/about">{props.t("Hakkimizda")}</Link>
                  </li>
                  <li>
                    <Link to="/whyus">{props.t("Neden Biz")}</Link>
                  </li>
                  <li>
                    <Link to="/smtp">{props.t("SMTP")}</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/social" className="waves-effect">
                  <i className="bx bx-user-voice"></i>
                  <span>{props.t("Sosyal")}</span>
                </Link>
              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bxs-user-detail"></i>
                  <span>{props.t("Ekip")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/teamlang">{props.t("Ekip Dil Ayari")}</Link>
                  </li>
                  <li>
                    <Link to="/team">{props.t("Ekip")}</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/certificate" className="waves-effect">
                  <i className="bx bx-certification"></i>
                  <span>{props.t("Sertifikalar")}</span>
                </Link>
              </li>

              <li>
                <Link to="/slider" className="waves-effect">
                  <i className="bx bx-code-block"></i>
                  <span>{props.t("Slider")}</span>
                </Link>
              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-detail"></i>
                  <span>{props.t("Blog")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/news">{props.t("Haber Dil Ayari")}</Link>
                  </li>
                  <li>
                    <Link to="/blog">{props.t("Blog")}</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/history" className="waves-effect">
                  <i className="bx bx-history"></i>
                  <span>{props.t("Gecmis")}</span>
                </Link>
              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-directions"></i>
                  <span>{props.t("Satis Servis")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/service-sales/page">
                      {props.t("Sayfa Dil Ayari")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/servicesales">{props.t("Noktalar")}</Link>
                  </li>
                  <li>
                    <Link to="/country">{props.t("Bolgeler")}</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bxs-contact"></i>
                  <span>{props.t("Iletisim")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/contact-form">
                      {props.t("Iletisim Dil Ayari")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact">{props.t("Iletisim")}</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bxs-file-plus"></i>
                  <span>{props.t("Yatirimci Iliskileri")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/infos">{props.t("Sirket Bilgileri")}</Link>
                  </li>
                  <li>
                    <Link to="/comps">{props.t("Icerikler")}</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/file" className="waves-effect">
                  <i className="bx bxs-file-pdf"></i>
                  <span>{props.t("Dosya")}</span>
                </Link>
              </li>

              <li>
                <Link to="/qr" className="waves-effect">
                  <i className="mdi mdi-qrcode"></i>
                  <span>{props.t("QR")}</span>
                </Link>
              </li>

              <li>
                <Link to="/qrcard" className="waves-effect">
                  <i className="mdi mdi-qrcode"></i>
                  <span>{props.t("QR Kartvizit")}</span>
                </Link>
              </li>

              <li>
                <Link to="/footer" className="waves-effect">
                  <i className="bx bx-grid-horizontal"></i>
                  <span>{props.t("Footer")}</span>
                </Link>
              </li>

              <li>
                <Link to="/users" className="waves-effect">
                  <i className="bx bx-user"></i>
                  <span>{props.t("Kullanıcılar")}</span>
                </Link>
              </li>

              <li>
                <Link to="/logs" className="waves-effect">
                  <i className="bx bx-history"></i>
                  <span>{props.t("Loglar")}</span>
                </Link>
              </li>

              <li>
                <Link to="/error-logs" className="waves-effect">
                  <i className="bx bx-error-alt"></i>
                  <span>{props.t("Hata Logları")}</span>
                </Link>
              </li>
            </ul>
          </div>
        </SimpleBar>
      </React.Fragment>
    )
  } else if (userRole.role === "Blog_Editor") {
    const activateParentDropdown = useCallback(item => {
      item.classList.add("active")
      const parent = item.parentElement
      const parent2El = parent.childNodes[1]

      if (parent2El && parent2El.id !== "side-menu") {
        parent2El.classList.add("mm-show")
      }

      if (parent) {
        parent.classList.add("mm-active")
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.add("mm-show") // ul tag

          const parent3 = parent2.parentElement // li tag

          if (parent3) {
            parent3.classList.add("mm-active") // li
            parent3.childNodes[0].classList.add("mm-active") //a
            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.add("mm-show") // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.add("mm-show") // li
                parent5.childNodes[0].classList.add("mm-active") // a tag
              }
            }
          }
        }
        // scrollElement(item)
        return false
      }
      // scrollElement(item)
      return false
    }, [])

    const removeActivation = items => {
      for (var i = 0; i < items.length; ++i) {
        var item = items[i]
        const parent = items[i].parentElement

        if (item && item.classList.contains("active")) {
          item.classList.remove("active")
        }
        if (parent) {
          const parent2El =
            parent.childNodes &&
            parent.childNodes.lenght &&
            parent.childNodes[1]
              ? parent.childNodes[1]
              : null
          if (parent2El && parent2El.id !== "side-menu") {
            parent2El.classList.remove("mm-show")
          }

          parent.classList.remove("mm-active")
          const parent2 = parent.parentElement

          if (parent2) {
            parent2.classList.remove("mm-show")

            const parent3 = parent2.parentElement
            if (parent3) {
              parent3.classList.remove("mm-active") // li
              parent3.childNodes[0].classList.remove("mm-active")

              const parent4 = parent3.parentElement // ul
              if (parent4) {
                parent4.classList.remove("mm-show") // ul
                const parent5 = parent4.parentElement
                if (parent5) {
                  parent5.classList.remove("mm-show") // li
                  parent5.childNodes[0].classList.remove("mm-active") // a tag
                }
              }
            }
          }
        }
      }
    }

    const path = useLocation()
    const activeMenu = useCallback(() => {
      const pathName = path.pathname
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      removeActivation(items)

      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }, [path.pathname, activateParentDropdown])

    useEffect(() => {
      ref.current.recalculate()
    }, [])

    useEffect(() => {
      new MetisMenu("#side-menu")
      activeMenu()
    }, [])

    // useEffect(() => {
    //   window.scrollTo({ top: 0, behavior: "smooth" })
    //   activeMenu()
    // }, [activeMenu])

    // function scrollElement(item) {
    //   if (item) {
    //     const currentPosition = item.offsetTop
    //     if (currentPosition > window.innerHeight) {
    //       ref.current.getScrollElement().scrollTop = currentPosition - 300
    //     }
    //   }
    // }
    return (
      <React.Fragment>
        <SimpleBar className="h-100" ref={ref}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{props.t("Safkar")}</li>
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-detail"></i>
                  <span>{props.t("Blog")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/news">{props.t("Haber Dil Ayari")}</Link>
                  </li>
                  <li>
                    <Link to="/blog">{props.t("Blog")}</Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </SimpleBar>
      </React.Fragment>
    )
  } else {
    return (
      <Navigate to="/login" state={{ from: props.location }} replace={true} />
    )
  }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
