import React from "react"
import {
  Container,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import { useParams, useNavigate } from "react-router-dom"

import classnames from "classnames"

import axios, { API_URL } from "lib/axios"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import { toast } from "react-toastify"

const Index = () => {
  document.title = "Safkar Panel - İletişim Bilgisi Ekle"
  const navigate = useNavigate()

  const [name, setName] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [email2, setEmail2] = React.useState("")
  const [phone, setPhone] = React.useState("")
  const [phone2, setPhone2] = React.useState("")
  const [map, setMap] = React.useState("")
  const [address, setAddress] = React.useState("")
  const [active, setActive] = React.useState(false)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="İletişim Bilgisi"
            breadcrumbItem="İletişim Bilgisi Ekle"
          />

          {/* Render Form */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form
                    onSubmit={e => {
                      e.preventDefault()
                      axios
                        .post("/contact", {
                          name,
                          email1: email,
                          email2,
                          phone1: phone,
                          phone2,
                          map,
                          address,
                          active,
                        })
                        .then(response => {
                          if (response.status == 200) {
                            toast.success("Ekleme Başarılı")
                          }
                        })
                        .catch(error => {
                          console.log(error)
                        })
                    }}
                  >
                    <h4 className="card-title">Kontak Ekle</h4>
                    <p className="card-title-desc">Kontak Ekle</p>
                    {/* back button */}
                    <button
                      type="button"
                      className="btn btn-danger w-md mx-3 mb-2"
                      style={{
                        position: "absolute",
                        right: "0",
                        top: "20px",
                      }}
                      onClick={() => {
                        navigate("/contact")
                      }}
                    >
                      Geri
                    </button>
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label htmlFor="simpleinput">Adı</label>
                        <input
                          type="text"
                          id="simpleinput"
                          className="form-control"
                          value={name}
                          onChange={e => {
                            setName(e.target.value)
                          }}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label htmlFor="simpleinput">Mail Adresi</label>
                        <input
                          type="text"
                          id="simpleinput"
                          className="form-control"
                          value={email}
                          onChange={e => {
                            setEmail(e.target.value)
                          }}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label htmlFor="simpleinput">Mail 2</label>
                        <input
                          type="text"
                          id="simpleinput"
                          className="form-control"
                          value={email2}
                          onChange={e => {
                            setEmail2(e.target.value)
                          }}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label htmlFor="simpleinput">Telefon Numarası</label>
                        <input
                          type="text"
                          id="simpleinput"
                          className="form-control"
                          value={phone}
                          onChange={e => {
                            setPhone(e.target.value)
                          }}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label htmlFor="simpleinput">Telefon 2</label>
                        <input
                          type="text"
                          id="simpleinput"
                          className="form-control"
                          value={phone2}
                          onChange={e => {
                            setPhone2(e.target.value)
                          }}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label htmlFor="simpleinput">Adres</label>
                        <input
                          type="text"
                          id="simpleinput"
                          className="form-control"
                          value={address}
                          onChange={e => {
                            setAddress(e.target.value)
                          }}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label htmlFor="simpleinput">Haritada Görüntüle</label>
                        <input
                          type="text"
                          id="simpleinput"
                          className="form-control"
                          value={map}
                          onChange={e => {
                            setMap(e.target.value)
                          }}
                          required
                        />
                      </div>
                    </div>
                    {active != null && (
                      <div className="form-check form-switch form-switch-lg">
                        <div className="form-group mb-3">
                          <label htmlFor="simpleinput">
                            {active ? "Aktif" : "Pasif"}
                          </label>
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customSwitchsizelg"
                            defaultChecked={active ? true : false}
                            onChange={e => {
                              setActive(e.target.checked)
                            }}
                          />
                        </div>
                      </div>
                    )}

                    {/*submit button right*/}
                    <div className="col-lg-12">
                      <div className="mt-4 d-flex justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-primary w-md  mb-2"
                        >
                          Ekle
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger w-md mx-3 mb-2"
                          onClick={() => {
                            navigate("/main-page")
                          }}
                        >
                          Geri
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Index
