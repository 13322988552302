import React from "react"
import {
  Container,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import { useParams, useNavigate } from "react-router-dom"

import classnames from "classnames"

import axios, { API_URL } from "lib/axios"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// Import Components

import { CKEditor } from "@ckeditor/ckeditor5-react"
import { Editor } from "ckeditor5-custom-build/build/ckeditor"

import { toast } from "react-toastify"

const MainPage = () => {
  const Navigate = useNavigate()
  // all variables
  const [file, setFile] = React.useState()
  const [active, setActive] = React.useState()
  const [display, setDisplay] = React.useState()

  const [fileC, setFileC] = React.useState(null)

  const [form, setForm] = React.useState()

  const params = useParams()

  //meta title
  document.title = "Safkar Panel - Kategori Duzenle"

  const [customActiveTab, setcustomActiveTab] = React.useState("0")

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  const [lang, setLang] = React.useState()

  React.useEffect(() => {
    axios
      .get("/language")
      .then(responseLang => {
        if (responseLang.data.languages) {
          setLang(responseLang.data.languages)

          axios
            .get(`/category?id=${params.id}`)
            .then(responseCategory => {
              if (responseCategory.data.category) {
                setData({ categories: responseCategory.data.category })
                setActive(responseCategory.data.category[0].active)
                setDisplay(responseCategory.data.category[0].display)
                setFile(responseCategory.data.category[0].image)
                setFileC(responseCategory.data.category[0].file)
              }

              const newForm = responseLang.data.languages.map(language => {
                var categoryLangRow =
                  responseCategory.data.category[0].categorylang.find(
                    item => item.languageId == language.id
                  )

                if (categoryLangRow) {
                  return {
                    ["title_" + language.code]: categoryLangRow.title,
                    ["content_" + language.code]: categoryLangRow.content,
                    ["table_" + language.code]: categoryLangRow.table,
                    id: responseCategory.data.category[0].id,
                  }
                } else {
                  return {
                    ["title_" + language.code]: "",
                    ["content_" + language.code]: "",
                    ["table_" + language.code]: "",
                    id: responseCategory.data.category[0].id,
                  }
                }
              })
              setForm(newForm)
            })
            .catch(error => {
              console.log(error)
            })
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const [data, setData] = React.useState()

  const isCategoryForm = obj => {
    // Gerekli özelliklerin varlığını kontrol ederek
    // obj'nin CategoryForm tipinde olduğunu doğrulayabilirsiniz
    // Örnek olarak: return 'property1' in obj && 'property2' in obj;
    return true
  }

  const setFormValueByIndex = (index, key, value) => {
    const newForm = [...form]
    if (isCategoryForm(newForm[index])) {
      newForm[index][key] = value.toString()
    }
    setForm(newForm)
  }

  const getFormValueByIndex = (index, key) => {
    const newForm = [...form]
    if (isCategoryForm(newForm[index])) {
      //if newform has key
      if (newForm[index]) {
        return newForm[index][key]
      } else {
        return ""
      }
    }
    return ""
  }

  return (
    <React.Fragment>
      {data && (
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Kategory" breadcrumbItem="Kategori Duzenle" />

            {/* Render Form */}
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <form
                      onSubmit={e => {
                        e.preventDefault()
                        var arr = {}
                        form.forEach(item => {
                          Object.keys(item).forEach(key => {
                            arr[key] = item[key]
                          })
                        })
                        arr["active"] = active
                        arr["display"] = display
                        arr["file"] = fileC

                        if (fileC && typeof fileC === "object") {
                          if (fileC.type != "application/pdf") {
                            toast.error("Dosya PDF olmalidir")
                            return
                          }

                          if (file && typeof file === "object") {
                            if (
                              file.type != "image/png" &&
                              file.type != "image/jpeg" &&
                              file.type != "image/jpg"
                            ) {
                              toast.error("Resim PNG, JPEG veya JPG olmalidir")
                              return
                            }
                          }

                          var formData1 = new FormData()
                          formData1.append("files", fileC)
                          axios
                            .post("/category/pdf", formData1)
                            .then(responseF => {
                              if (responseF.status == 200) {
                                arr["file"] = responseF.data.files[0].filename

                                axios
                                  .put("/category", arr)
                                  .then(response => {
                                    if (response.data.success) {
                                      toast.success("Guncelleme Basarili")
                                    }
                                    if (typeof file === "object") {
                                      var formData = new FormData()
                                      formData.append("images", file)
                                      formData.append(
                                        "categoryId",
                                        response.data.category.id
                                      )
                                      axios
                                        .post("/category/image", formData)
                                        .then(response => {})
                                    }
                                  })
                                  .catch(error => {
                                    console.log(error)
                                  })
                              }
                            })
                        } else {
                          axios
                            .put("/category", arr)
                            .then(response => {
                              if (response.data.success) {
                                toast.success("Guncelleme Basarili")
                              }
                              if (typeof file === "object") {
                                var formData = new FormData()
                                formData.append("images", file)
                                formData.append(
                                  "categoryId",
                                  response.data.category.id
                                )
                                axios
                                  .post("/category/image", formData)
                                  .then(response => {})
                              }
                            })
                            .catch(error => {
                              console.log(error)
                            })
                        }
                      }}
                    >
                      <h4 className="card-title">Kategori Duzenle</h4>
                      <p className="card-title-desc">Kategori Duzenle</p>
                      {/* back button */}
                      <button
                        type="button"
                        className="btn btn-danger w-md mx-3 mb-2"
                        style={{
                          position: "absolute",
                          right: "0",
                          top: "20px",
                        }}
                        onClick={() => {
                          //rout Edit category
                          Navigate("/category")
                        }}
                      >
                        Geri
                      </button>
                      <div className="row">
                        <div className="col-lg-8">
                          {/* two colum*/}
                          <div className="row">
                            <div className="col-lg-8">
                              {/*category name*/}
                              <div className="mb-3">
                                <label
                                  htmlFor="categoryname"
                                  className="form-label"
                                >
                                  Kategori Resmi
                                </label>
                                <input
                                  id="categoryname"
                                  name="categoryname"
                                  type="file"
                                  className="form-control"
                                  placeholder="Kategori Resmi"
                                  accept=".png, .jpeg, .jpg"
                                  onChange={e => {
                                    setFile(e.target.files[0])
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-2">
                              {/*category name*/}
                              <div className="mb-3">
                                <label
                                  htmlFor="categoryname"
                                  className="form-label"
                                >
                                  Aktiflik
                                </label>
                                <div className="form-check form-switch form-switch-lg mb-3">
                                  {active != null && (
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="customSwitchsizelg"
                                      defaultChecked={active ? true : false}
                                      onChange={e => {
                                        setActive(e.target.checked)
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-2">
                              {/*category name*/}
                              <div className="mb-3">
                                <label
                                  htmlFor="categoryname"
                                  className="form-label"
                                >
                                  {display ? "Resim" : "Isim"}
                                </label>
                                <div className="form-check form-switch form-switch-lg mb-3">
                                  {display != null && (
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="customSwitchsizelg"
                                      defaultChecked={display ? true : false}
                                      onChange={e => {
                                        setDisplay(e.target.checked)
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          {/*category image*/}
                          <div className="mb-3 border ">
                            <img
                              src={
                                typeof file === "string"
                                  ? `${API_URL}/api/image?image=${file.replaceAll(
                                      " ",
                                      "%20"
                                    )}&path=category`
                                  : URL.createObjectURL(file)
                              }
                              alt="category image"
                              style={{ width: "100%" }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-8">
                          {/*category name*/}
                          <div className="mb-3">
                            <label
                              htmlFor="categoryname"
                              className="form-label"
                            >
                              Dosya
                            </label>
                            <input
                              id="categoryname"
                              name="categoryname"
                              type="file"
                              className="form-control"
                              accept="application/pdf"
                              placeholder="Dosya"
                              onChange={e => {
                                setFileC(e.target.files[0])
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <Nav tabs className="nav-tabs-custom nav-justified">
                            {lang &&
                              lang.map((item, index) => (
                                <NavItem key={index}>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      active:
                                        customActiveTab === index.toString(),
                                    })}
                                    onClick={() => {
                                      toggleCustom(index.toString())
                                    }}
                                  >
                                    <span className="d-block d-sm-none">
                                      {item.displayname}
                                    </span>
                                    <span className="d-none d-sm-block">
                                      {item.displayname}
                                    </span>
                                  </NavLink>
                                </NavItem>
                              ))}
                          </Nav>

                          <TabContent
                            activeTab={customActiveTab}
                            className="p-3 text-muted"
                          >
                            {form &&
                              lang &&
                              lang.map((item, index) => (
                                <TabPane tabId={index.toString()} key={index}>
                                  <Row>
                                    <Col sm="6">
                                      <div className="mt-4">
                                        <label
                                          htmlFor="categoryname"
                                          className="form-label"
                                        >
                                          Kategori Adi
                                        </label>

                                        <input
                                          id="categoryname"
                                          name="categoryname"
                                          type="text"
                                          className="form-control"
                                          placeholder="Kategori Adi"
                                          defaultValue={getFormValueByIndex(
                                            index,
                                            "title_" + item.code
                                          )}
                                          onChange={e =>
                                            setFormValueByIndex(
                                              index,
                                              "title_" + item.code,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                    </Col>
                                    <Col sm="6">
                                      <div className="mt-4">
                                        <label
                                          htmlFor="categoryname"
                                          className="form-label"
                                        >
                                          Kategori Icerik
                                        </label>

                                        <input
                                          id="categoryname"
                                          name="categoryname"
                                          type="text"
                                          className="form-control"
                                          placeholder="Kategori Adi"
                                          defaultValue={getFormValueByIndex(
                                            index,
                                            "content_" + item.code
                                          )}
                                          onChange={e =>
                                            setFormValueByIndex(
                                              index,
                                              "content_" + item.code,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                    </Col>
                                    <Col sm="12">
                                      <div className="mt-4">
                                        <label
                                          htmlFor="categoryTable"
                                          className="form-label"
                                        >
                                          Kategori Tablo
                                        </label>
                                        <CKEditor
                                          editor={Editor}
                                          data={getFormValueByIndex(
                                            index,
                                            "table_" + item.code
                                          )}
                                          onChange={(event, editor) => {
                                            const data = editor.getData()
                                            setFormValueByIndex(
                                              index,
                                              "table_" + item.code,
                                              data
                                            )
                                          }}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </TabPane>
                              ))}
                          </TabContent>
                        </div>
                        {/*submit button right*/}
                        <div className="col-lg-12">
                          <div className="mt-4 d-flex justify-content-end">
                            <button
                              type="submit"
                              className="btn btn-primary w-md  mb-2"
                            >
                              Guncelle
                            </button>
                            <button
                              type="button"
                              className="btn btn-danger w-md mx-3 mb-2"
                              onClick={() => {
                                //rout Edit category
                                Navigate("/category")
                              }}
                            >
                              Geri
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      )}
    </React.Fragment>
  )
}

export default MainPage
