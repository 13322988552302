import React from "react"
import {
  Container,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Input,
} from "reactstrap"

import { useNavigate } from "react-router-dom"

import classnames from "classnames"

import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import { CKEditor } from "@ckeditor/ckeditor5-react"
import { Editor } from "ckeditor5-custom-build/build/ckeditor"

import axios from "lib/axios"

import { YearPicker } from "@mantine/dates"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import moment from "moment"

// Import Components

const MainPage = () => {
  const Navigate = useNavigate()
  // all variables
  const [file, setFile] = React.useState()
  const [active, setActive] = React.useState(true)

  const [form, setForm] = React.useState()

  const [content, setContent] = React.useState()
  const [startingDate, setStartingDate] = React.useState(new Date())

  //meta title
  document.title = "Safkar Panel - Geçmiş Ekle"

  const [customActiveTab, setcustomActiveTab] = React.useState("0")

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  const [lang, setLang] = React.useState()

  React.useEffect(() => {
    axios
      .get("/language")
      .then(responseLang => {
        if (responseLang.data.languages) {
          setLang(responseLang.data.languages)

          const newForm = responseLang.data.languages.map(language => {
            return {
              ["title_" + language.code]: "",
              ["content_" + language.code]: "",
            }
          })

          setForm(newForm)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const isCategoryForm = obj => {
    // Gerekli özelliklerin varlığını kontrol ederek
    // obj'nin CategoryForm tipinde olduğunu doğrulayabilirsiniz
    // Örnek olarak: return 'property1' in obj && 'property2' in obj;
    return true
  }

  const setFormValueByIndex = (index, key, value) => {
    const newForm = [...form]
    if (isCategoryForm(newForm[index])) {
      newForm[index][key] = value.toString()
    }
    setForm(newForm)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Gemiş" breadcrumbItem="Geçmiş Ekle" />

          {/* Render Form */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form
                    onSubmit={e => {
                      e.preventDefault()
                      var arr = {}
                      form.forEach(item => {
                        Object.keys(item).forEach(key => {
                          arr[key] = item[key]
                        })
                      })
                      arr["active"] = active
                      arr["date"] = startingDate.toString()

                      if (file) {
                        if (
                          file.type != "image/png" &&
                          file.type != "image/jpeg" &&
                          file.type != "image/jpg"
                        ) {
                          toast.error("Resim PNG, JPEG veya JPG olmalidir")
                          return
                        }
                      }

                      axios
                        .post("/history", arr)
                        .then(response => {
                          toast.success("Geçmiş Eklendi")
                          if (file) {
                            var formData = new FormData()
                            formData.append("images", file)
                            formData.append(
                              "historyId",
                              response.data.history.id
                            )
                            axios
                              .post("/history/image", formData)
                              .then(response => {})
                          }
                          Navigate("/history")
                        })
                        .catch(error => {
                          console.log(error)
                          toast("Popup Eklenemedi")
                        })
                    }}
                  >
                    <h4 className="card-title">Geçmiş</h4>
                    <p className="card-title-desc">Geçmiş Ekle</p>
                    {/* back button */}
                    <button
                      type="button"
                      className="btn btn-danger w-md mx-3 mb-2"
                      style={{
                        position: "absolute",
                        right: "0",
                        top: "20px",
                      }}
                      onClick={() => {
                        //rout Edit category
                        Navigate("/history")
                      }}
                    >
                      Geri
                    </button>
                    <div className="row">
                      <div className="col-lg-6">
                        {/*category name*/}
                        <div className="mb-3">
                          <label htmlFor="categoryname" className="form-label">
                            Gecmis Resmi
                          </label>
                          <input
                            id="categoryname"
                            name="categoryname"
                            type="file"
                            accept=".png, .jpeg, .jpg"
                            className="form-control"
                            onChange={e => {
                              setFile(e.target.files[0])
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Tarihi
                          </label>
                          <YearPicker
                            value={startingDate.toString()}
                            onChange={e => {
                              // change format to YYYY
                              let date = new Date(e)
                              date = date.getFullYear()

                              setStartingDate(date)
                            }}
                          />
                          {/* <input
                            className="form-control"
                            type="date"
                            defaultValue={startingDate.toString()}
                            id="example-date-input"
                            onChange={e => {
                              setStartingDate(e.target.value)
                            }}
                          /> */}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <label htmlFor="categoryname" className="form-label">
                          Aktiflik
                        </label>
                        <div className="form-check form-switch form-switch-lg">
                          {active != null && (
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customSwitchsizelg"
                              defaultChecked={active ? true : false}
                              onChange={e => {
                                setActive(e.target.checked)
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <Nav tabs className="nav-tabs-custom nav-justified">
                          {lang &&
                            lang.map((item, index) => (
                              <NavItem key={index}>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active:
                                      customActiveTab === index.toString(),
                                  })}
                                  onClick={() => {
                                    toggleCustom(index.toString())
                                  }}
                                >
                                  <span className="d-block d-sm-none">
                                    {item.displayname}
                                  </span>
                                  <span className="d-none d-sm-block">
                                    {item.displayname}
                                  </span>
                                </NavLink>
                              </NavItem>
                            ))}
                        </Nav>

                        <TabContent
                          activeTab={customActiveTab}
                          className="p-3 text-muted"
                        >
                          {form &&
                            lang &&
                            lang.map((item, index) => (
                              <TabPane tabId={index.toString()} key={index}>
                                <Row>
                                  <Col sm="12">
                                    <div className="mt-4">
                                      <label
                                        htmlFor="categoryname"
                                        className="form-label"
                                      >
                                        Geçmiş Başlığı
                                      </label>

                                      <input
                                        id="categoryname"
                                        name="categoryname"
                                        type="text"
                                        className="form-control"
                                        placeholder="Geçmiş Başlığı"
                                        onChange={e =>
                                          setFormValueByIndex(
                                            index,
                                            "title_" + item.code,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </Col>
                                  <Col sm="12">
                                    <div className="mt-4">
                                      <label
                                        htmlFor="categoryname"
                                        className="form-label"
                                      >
                                        Geçmiş İçeriği
                                      </label>
                                      <CKEditor
                                        editor={Editor}
                                        data={content}
                                        onChange={(e, editor) => {
                                          const data = editor.getData()
                                          setFormValueByIndex(
                                            index,
                                            "content_" + item.code,
                                            data
                                          )
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </TabPane>
                            ))}
                        </TabContent>
                      </div>
                      {/*submit button right*/}
                      <div className="col-lg-12">
                        <div className="mt-4 d-flex justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-primary w-md  mb-2"
                          >
                            Ekle
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger w-md mx-3 mb-2"
                            onClick={() => {
                              //rout Edit category
                              Navigate("/history")
                            }}
                          >
                            Geri
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MainPage
