import React from "react"
import { Button, Container } from "reactstrap"

import { Text, Group, ActionIcon, BackgroundImage } from "@mantine/core"
import { IconEdit, IconTrash, IconMenu2 } from "@tabler/icons-react"

import axios from "lib/axios"

import { useParams, useNavigate } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

// Import Components
import DataTableMantine from "components/Added/datatable"
import DeleteModal from "components/Common/DeleteModal"

import { toast } from "react-toastify"

const Subcategory = () => {
  const Navigate = useNavigate()
  const params = useParams()

  const [data, setData] = React.useState()
  const [category, setCategory] = React.useState()

  React.useEffect(() => {
    axios
      .get("/subcategory")
      .then(response => {
        if (response.data.subcategory) {
          //filter subcategory
          let data = response.data.subcategory.filter(
            item => item.categoryId === parseInt(params.id)
          )
          setData({ subcategory: data })
        }
      })
      .catch(error => {
        console.log(error)
      })
    axios
      .get(`/category?id=${params.id}`)
      .then(responseCategory => {
        if (responseCategory.data.category) {
          setCategory(responseCategory.data.category)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])
  //meta title
  document.title = "Safkar Panel - Alt Kategoriler"

  //delete order
  const [order, setOrder] = React.useState(null)
  const [deleteModal, setDeleteModal] = React.useState(false)

  const onClickDelete = order => {
    setOrder(order)
    setDeleteModal(true)
  }

  const handleDeleteOrder = () => {
    if (order && order.id) {
      let arr = {}
      arr["id"] = order.id
      axios
        .delete("/subcategory", {
          data: arr,
        })
        .then(res => {
          toast.success("Alt kategori başarıyla silindi.")
          setDeleteModal(false)
          setData({
            subcategory: data.subcategory.filter(item => item.id !== order.id),
          })
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
  const handleOrderClicks = () => {
    setOrderList("")
    setIsEdit(false)
    toggle()
  }

  const arkaplan = {
    backgroundColor: "#D3E0FD",
  }

  return (
    <React.Fragment>
      {order && (
        <DeleteModal
          show={deleteModal}
          text={
            order.subcategorylang[0].title +
            "alt kategorisini silmek istediğinize emin misiniz?"
          }
          onDeleteClick={handleDeleteOrder}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Panel" breadcrumbItem="Alt Kategoriler" />

          {/* Render Category name */}
          <div className="d-flex justify-content-between align-items-center">
            {category && (
              <h5 className="mt-3">
                {category[0].categorylang[0].title} Kategorisinin Alt
                Kategorileri
              </h5>
            )}
            {/* Render Add Button */}
            <Group position="right" spacing={4} mt="md">
              <button
                className="btn btn-primary mb-2"
                onClick={() => {
                  //rout Add category
                  Navigate("/subcategory/add/" + params.id)
                }}
              >
                Alt Kategori Ekle
              </button>
              <button
                className="btn btn-danger mb-2"
                onClick={() => {
                  //rout Add category
                  Navigate("/category")
                }}
              >
                Geri
              </button>
            </Group>
          </div>

          {/* Render DataTable */}
          {data && (
            <DataTableMantine
              columns={[
                {
                  name: "ID",
                  selector: row => row.id,
                  sortable: true,
                  width: "50px",
                  textAlign: "center", // Assuming 'textAlignment: "Center"' means text alignment
                },
                {
                  name: "Alt kategori Adı",
                  selector: row => row.subcategorylang[0].title,
                  sortable: true,
                },
                {
                  name: "Islemler",
                  width: "110px",
                  textAlign: "center",
                  cell: item => (
                    <Group spacing={4} position="center" noWrap>
                      <ActionIcon
                        color="blue"
                        onClick={() => Navigate("/subcategory/edit/" + item.id)}
                      >
                        <IconEdit size={16} />
                      </ActionIcon>
                      <ActionIcon
                        color="red"
                        onClick={() => onClickDelete(item)}
                      >
                        <IconTrash size={16} />
                      </ActionIcon>
                    </Group>
                  ),
                  ignoreRowClick: true,
                  allowOverflow: true,
                  button: true,
                },
              ]}
              data={data.subcategory}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Subcategory
