import axios from "axios"

export const API_URL = "https://webservis.safkar.com"
//export const API_URL = "http://localhost:3000"

var instance

instance = axios.create({
  baseURL: API_URL + "/api",
})

export default instance
