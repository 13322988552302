import React from "react"
import { Container } from "reactstrap"

import { useParams, useNavigate } from "react-router-dom"

import axios, { API_URL } from "lib/axios"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import { toast } from "react-toastify"
import DataTableMantine from "components/Added/datatable"
import { ActionIcon, Group, Text } from "@mantine/core"
import { IconEdit, IconTrash } from "@tabler/icons-react"
import DeleteModal from "components/Common/DeleteModal"

const Index = () => {
  const Navigate = useNavigate()
  const [data, setData] = React.useState()
  const [deleteModal, setdeleteModal] = React.useState(false)
  const [order, setOrder] = React.useState()
  //meta title
  document.title = "Safkar Panel - Servisler & Bakımlar"

  React.useEffect(() => {
    axios.get("/servicesales").then(res => {
      setData(res.data)
    })
  }, [])

  const handleDeleteOrder = () => {
    axios
      .delete("/servicesales", { data: { id: order.id } })
      .then(res => {
        toast.success("Satış & Servis Silindi")
        setdeleteModal(false)
        axios.get("/servicesales").then(res => {
          setData(res.data)
        })
      })
      .catch(err => {
        toast.error("Satış & Servis Silindi")
      })
  }

  const onClickDelete = order => {
    setOrder(order)
    setdeleteModal(true)
  }

  return (
    <React.Fragment>
      {order && (
        <DeleteModal
          show={deleteModal}
          text={
            order.title +
            " Satış & Servis bilgisini silmek istediğinize emin misiniz?"
          }
          onDeleteClick={handleDeleteOrder}
          onCloseClick={() => setdeleteModal(false)}
        />
      )}
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Servis & Bakım"
            breadcrumbItem="Servisler & Bakımlar"
          />
          <Group position="right" spacing={4} mt="md">
            <button
              className="btn btn-primary mb-2"
              onClick={() => {
                //rout Add category
                Navigate("/servicesales/add")
              }}
            >
              Satış & Servis Ekle
            </button>
          </Group>
          {/* Render Form */}
          {data && (
            <DataTableMantine
              filterField={"title"}
              columns={[
                {
                  name: "ID",
                  selector: row => row.id,
                  sortable: true,
                  width: 50,
                  textAlign: "center", // Removed duplicate textAlignment and kept "Center" as the value
                },
                {
                  name: "Bakım & Servis Adı",
                  selector: row => row.title,
                  sortable: true,
                },
                {
                  name: <Text mr="xs">Islemler</Text>,
                  width: 110,
                  textAlign: "center",
                  cell: item => (
                    <Group spacing={4} position="center" noWrap>
                      <ActionIcon
                        color="blue"
                        onClick={() =>
                          Navigate("/servicesales/edit/" + item.id)
                        }
                      >
                        <IconEdit size={16} />
                      </ActionIcon>
                      <ActionIcon
                        color="red"
                        onClick={() => onClickDelete(item)}
                      >
                        <IconTrash size={16} />
                      </ActionIcon>
                    </Group>
                  ),
                  ignoreRowClick: true,
                  allowOverflow: true,
                  button: true,
                },
              ]}
              data={data}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Index
