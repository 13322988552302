import React, { useState } from "react"
import { Container } from "reactstrap"

import { Text, Group, ActionIcon } from "@mantine/core"
import { IconEdit, IconEye, IconTrash } from "@tabler/icons-react"

import axios, { API_URL } from "lib/axios"

import { useNavigate } from "react-router-dom"

import html2canvas from "html2canvas"
import { saveAs } from "file-saver"

// Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

// Import Components
import DataTableMantine from "components/Added/datatable"
import DeleteModal from "components/Common/DeleteModal"

import QRCode from "react-qr-code"

import { toast } from "react-toastify"

const Files = () => {
  const Navigate = useNavigate()

  const [data, setData] = React.useState()

  const [qr, setQr] = React.useState([])

  React.useEffect(() => {
    axios
      .get("/file")
      .then(response => {
        if (response.data) {
          setQr(response.data)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])
  document.title = "Safkar Panel - Dosyalar"

  //delete faq
  const [faq, setFaq] = React.useState(null)
  const [deleteModal, setDeleteModal] = React.useState(false)

  const [qrRefs] = useState([])

  const onClickDelete = faq => {
    setFaq(faq)
    setDeleteModal(true)
  }

  const handleDeleteFaq = () => {
    if (faq && faq.id) {
      let arr = {}
      arr["id"] = faq.id
      axios
        .delete("/file?id=" + faq.id)
        .then(res => {
          toast.success("Dosya başarıyla silindi.")
          setDeleteModal(false)
          setQr(qr.filter(item => item.id !== faq.id))
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
  const handleFaqClicks = () => {
    setFaqList("")
    setIsEdit(false)
    toggle()
  }

  return (
    <React.Fragment>
      {faq && (
        <DeleteModal
          show={deleteModal}
          text={
            faq.name + " Adlı Dosyayı Kodunu silmek istediğinize emin misiniz?"
          }
          onDeleteClick={handleDeleteFaq}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Panel" breadcrumbItem="Dosya" />

          {/* Render Add Button */}
          <Group position="right" spacing={4} mt="md">
            <button
              className="btn btn-primary mb-2"
              onClick={() => {
                //rout Add faq
                Navigate("/file/add")
              }}
            >
              Dosya Ekle
            </button>
          </Group>

          {/* Render DataTable */}
          {qr && (
            <DataTableMantine
              filterField={"name"}
              columns={[
                {
                  name: "ID",
                  selector: row => row.id,
                  sortable: true,
                  width: 50,
                  textAlign: "center", // Removed duplicate textAlignment and kept "Center" as the value
                },
                {
                  name: "Dosya Adı",
                  selector: row => row.name,
                  sortable: true,
                },
                {
                  name: "QR Kodu",
                  sortable: false,
                  cell: (item, index) => {
                    const handleDownload = () => {
                      html2canvas(qrRefs[index].current, {
                        allowTaint: true,
                        useCORS: true,
                      }).then(canvas => {
                        canvas.toBlob(
                          blob => {
                            saveAs(blob, `QRCode_${index}.jpg`)
                          },
                          "image/jpeg",
                          1
                        )
                      })
                    }

                    if (!qrRefs[index]) {
                      qrRefs[index] = React.createRef()
                    }

                    return (
                      <div
                        style={{
                          height: "auto",
                          margin: "0 auto",
                          maxWidth: 128,
                          width: "100%",
                        }}
                      >
                        <div ref={qrRefs[index]}>
                          <QRCode
                            size={128}
                            style={{
                              height: "auto",
                              maxWidth: "100%",
                              width: "100%",
                            }}
                            value={
                              API_URL +
                              "/api/docs?docs=" +
                              item.qr.replaceAll(" ", "%20") +
                              "&path=pdf"
                            }
                            viewBox={`0 0 128 128`}
                          />
                        </div>
                        <button
                          className="btn btn-sm w-100 btn-primary mt-2"
                          onClick={handleDownload}
                        >
                          İndir
                        </button>
                      </div>
                    )
                  },
                },
                {
                  name: <Text mr="xs">İşlemler</Text>,
                  width: 110,
                  textAlign: "center",
                  cell: item => (
                    <Group spacing={4} position="center" noWrap>
                      <ActionIcon
                        color="blue"
                        onClick={() => {
                          //go to blank page
                          window.open(
                            API_URL +
                              "/api/docs?docs=" +
                              item.qr.replaceAll(" ", "%20") +
                              "&path=pdf",
                            "_blank"
                          )
                        }}
                      >
                        <IconEye size={16} />
                      </ActionIcon>
                      <ActionIcon
                        color="red"
                        onClick={() => onClickDelete(item)}
                      >
                        <IconTrash size={16} />
                      </ActionIcon>
                    </Group>
                  ),
                  ignoreRowClick: true,
                  allowOverflow: true,
                  button: true,
                },
              ]}
              data={qr}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Files
