import React from "react"
import { Navigate } from "react-router-dom"

// // Pages Component
// import Chat from "../pages/Chat/Chat"

// // File Manager
// import FileManager from "../pages/FileManager/index"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Pages Calendar
// import Calendar from "../pages/Calendar/index"

// // //Tasks
// import TasksList from "../pages/Tasks/tasks-list"
// import TasksCreate from "../pages/Tasks/tasks-create"

// // //Projects
// import ProjectsGrid from "../pages/Projects/projects-grid"
// import ProjectsList from "../pages/Projects/projects-list"
// import ProjectsOverview from "../pages/Projects/ProjectOverview/projects-overview"
// import ProjectsCreate from "../pages/Projects/projects-create"

// // //Ecommerce Pages
// import EcommerceProducts from "../pages/Ecommerce/EcommerceProducts/index"
// import EcommerceProductDetail from "../pages/Ecommerce/EcommerceProducts/EcommerceProductDetail"
// import EcommerceOrders from "../pages/Ecommerce/EcommerceOrders/index"
// import EcommerceCustomers from "../pages/Ecommerce/EcommerceCustomers/index"
// import EcommerceCart from "../pages/Ecommerce/EcommerceCart"
// import EcommerceCheckout from "../pages/Ecommerce/EcommerceCheckout"
// import EcommerceShops from "../pages/Ecommerce/EcommerceShops/index"
// import EcommerceAddProduct from "../pages/Ecommerce/EcommerceAddProduct"

// //Email
// import EmailInbox from "../pages/Email/email-inbox"
// import EmailRead from "../pages/Email/email-read"
// import EmailBasicTemplte from "../pages/Email/email-basic-templte"
// import EmailAlertTemplte from "../pages/Email/email-template-alert"
// import EmailTemplateBilling from "../pages/Email/email-template-billing"

// //Invoices
// import InvoicesList from "../pages/Invoices/invoices-list"
// import InvoiceDetail from "../pages/Invoices/invoices-detail"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
// import Register from "../pages/Authentication/Register"
// import ForgetPwd from "../pages/Authentication/ForgetPassword"

// //  // Inner Authentication
// import Login1 from "../pages/AuthenticationInner/Login"
// import Login2 from "../pages/AuthenticationInner/Login2"
// import Register1 from "../pages/AuthenticationInner/Register"
// import Register2 from "../pages/AuthenticationInner/Register2"
// import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
// import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2"
// import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword"
// import ForgetPwd2 from "../pages/AuthenticationInner/ForgetPassword2"
// import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"
// import LockScreen2 from "../pages/AuthenticationInner/auth-lock-screen-2"
// import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail"
// import ConfirmMail2 from "../pages/AuthenticationInner/page-confirm-mail-2"
// import EmailVerification from "../pages/AuthenticationInner/auth-email-verification"
// import EmailVerification2 from "../pages/AuthenticationInner/auth-email-verification-2"
// import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification"
// import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2"

// // Dashboard
// import Dashboard from "../pages/Dashboard/index"
// import DashboardSaas from "../pages/Dashboard-saas/index"
// import DashboardCrypto from "../pages/Dashboard-crypto/index"
// //import Blog from "../pages/Dashboard-Blog/index"
// import DashboardJob from "../pages/DashboardJob/index"

// //Crypto
// import CryptoWallet from "../pages/Crypto/CryptoWallet/crypto-wallet"
// import CryptoBuySell from "../pages/Crypto/crypto-buy-sell"
// import CryptoExchange from "../pages/Crypto/crypto-exchange"
// import CryptoLending from "../pages/Crypto/crypto-lending"
// import CryptoOrders from "../pages/Crypto/CryptoOrders/crypto-orders"
// import CryptoKYCApplication from "../pages/Crypto/crypto-kyc-application"
// import CryptoIcoLanding from "../pages/Crypto/CryptoIcoLanding/index"

// // Charts
// import ChartApex from "../pages/Charts/Apexcharts"
// import ChartistChart from "../pages/Charts/ChartistChart"
// import ChartjsChart from "../pages/Charts/ChartjsChart"
// import EChart from "../pages/Charts/EChart"
// import SparklineChart from "../pages/Charts/SparklineChart"
// import ChartsKnob from "../pages/Charts/charts-knob"
// import ReCharts from "../pages/Charts/ReCharts"

// // Maps
// import MapsGoogle from "../pages/Maps/MapsGoogle"
// import MapsVector from "../pages/Maps/MapsVector"
// import MapsLeaflet from "../pages/Maps/MapsLeaflet"

// //Icons
// import IconBoxicons from "../pages/Icons/IconBoxicons"
// import IconDripicons from "../pages/Icons/IconDripicons"
// import IconMaterialdesign from "../pages/Icons/IconMaterialdesign"
// import IconFontawesome from "../pages/Icons/IconFontawesome"

// //Tables
// import BasicTables from "../pages/Tables/BasicTables"
// import DatatableTables from "../pages/Tables/DatatableTables"
// import ResponsiveTables from "../pages/Tables/ResponsiveTables"
// import DragDropTables from "../pages/Tables/DragDropTables"

// //Blog
// import BlogList from "../pages/Blog/BlogList/index"
// import BlogGrid from "../pages/Blog/BlogGrid/index"
// import BlogDetails from "../pages/Blog/BlogDetails"

// //Job
// import JobGrid from "../pages/JobPages/JobGrid/index"
// import JobDetails from "../pages/JobPages/JobDetails"
// import JobCategories from "../pages/JobPages/JobCategories"
// import JobList from "../pages/JobPages/JobList"
// import ApplyJobs from "../pages/JobPages/ApplyJobs/index"
// import CandidateList from "../pages/JobPages/CandidateList"
// import CandidateOverview from "../pages/JobPages/CandidateOverview"

// Forms
// import FormElements from "../pages/Forms/FormElements"
// import FormLayouts from "../pages/Forms/FormLayouts"
// import FormAdvanced from "../pages/Forms/FormAdvanced"
// import FormEditors from "../pages/Forms/FormEditors"
// import FormValidations from "../pages/Forms/FormValidations"
// import FormMask from "../pages/Forms/FormMask"
// import FormRepeater from "../pages/Forms/FormRepeater"
// import FormUpload from "../pages/Forms/FormUpload"
// import FormWizard from "../pages/Forms/FormWizard"

//Ui
// import UiAlert from "../pages/Ui/UiAlert"
// import UiButtons from "../pages/Ui/UiButtons"
// import UiCards from "../pages/Ui/UiCards"
// import UiCarousel from "../pages/Ui/UiCarousel"
// import UiColors from "../pages/Ui/UiColors"
// import UiDropdown from "../pages/Ui/UiDropdown"
// import UiGeneral from "../pages/Ui/UiGeneral"
// import UiGrid from "../pages/Ui/UiGrid"
// import UiImages from "../pages/Ui/UiImages"
// import UiLightbox from "../pages/Ui/UiLightbox"
// import UiModal from "../pages/Ui/UiModal"
// import UiProgressbar from "../pages/Ui/UiProgressbar"
// import UiTabsAccordions from "../pages/Ui/UiTabsAccordions"
// import UiTypography from "../pages/Ui/UiTypography"
// import UiVideo from "../pages/Ui/UiVideo"
// import UiSessionTimeout from "../pages/Ui/UiSessionTimeout"
// import UiRating from "../pages/Ui/UiRating"
// import UiRangeSlider from "../pages/Ui/UiRangeSlider"
// import UiNotifications from "../pages/Ui/ui-notifications"
// import UiOffCanvas from "pages/Ui/UiOffCanvas"
// import UiUtilitie from "../pages/Ui/UiUtilitie"
// import UiPlaceholders from "../pages/Ui/UiPlaceholders"
// import UiToasts from "../pages/Ui/UiToast"

//Pages
// import PagesStarter from "../pages/Utility/pages-starter"
// import PagesMaintenance from "../pages/Utility/pages-maintenance"
// import PagesComingsoon from "../pages/Utility/pages-comingsoon"
// import PagesTimeline from "../pages/Utility/pages-timeline"
// import PagesFaqs from "../pages/Utility/pages-faqs"
// import PagesPricing from "../pages/Utility/pages-pricing"
import Pages404 from "../pages/Utility/pages-404"
// import Pages500 from "../pages/Utility/pages-500"

//Contacts
// import ContactsGrid from "../pages/Contacts/contacts-grid"
// import ContactsList from "../pages/Contacts/ContactList/contacts-list"
// import ContactsProfile from "../pages/Contacts/ContactsProfile/contacts-profile"

//Safkar
import MainPage from "../pages/Main/main-page"
import Categories from "pages/Main/category/categories"
import CategoryEdit from "pages/Main/category/categoryEdit"
import CategoryAdd from "pages/Main/category/categoryAdd"
import SubCategories from "pages/Main/subcategory/subcategories"
import SubCategoryAdd from "pages/Main/subcategory/subcategoryAdd"
import SubCategoryEdit from "pages/Main/subcategory/subcategoryEdit"
import Products from "pages/Main/product/products"
import ProductAdd from "pages/Main/product/productAdd"
import ProductEdit from "pages/Main/product/productEdit"

import Faqs from "pages/Main/faq/faqs"
import FaqEdit from "pages/Main/faq/faqEdit"
import FaqAdd from "pages/Main/faq/faqAdd"

import KVKKPage from "pages/Main/kvkk/kvkkEdit"
import TextPage from "pages/Main/text/textEdit"
import ArgePage from "pages/Main/arge/argeEdit"
import Team from "pages/Main/team/team"
import TeamEdit from "pages/Main/team/teamEdit"
import TeamAdd from "pages/Main/team/teamAdd"
import TeamLang from "pages/Main/team/teamLang"
import Certificates from "pages/Main/certificate/certificate"
import CertificateEdit from "pages/Main/certificate/certificateEdit"
import CertificateAdd from "pages/Main/certificate/certificateAdd"

// ! Popups
import Popups from "pages/Main/popups"
import PopupsAdd from "pages/Main/popups/PopupsAdd"
import PopupsEdit from "pages/Main/popups/PopupsEdit"

import Settings from "pages/Main/settings"
import Smtp from "pages/Main/smtp"
import Social from "pages/Main/social"
import SocialAdd from "pages/Main/social/socialAdd"
import SocialEdit from "pages/Main/social/socialEdit"

import ContactForm from "pages/Main/contactform"

import Contact from "pages/Main/contact"
import ContactEdit from "pages/Main/contact/contactEdit"
import ContactAdd from "pages/Main/contact/contactAdd"

import ServiceSales from "pages/Main/ServiceSales"
import ServiceSalesEdit from "pages/Main/ServiceSales/serviceSalesEdit"
import ServiceSalesAdd from "pages/Main/ServiceSales/serviceSalesAdd"

import ServiceSalesPage from "pages/Main/serviceSalesPage"

import Country from "pages/Main/country"

import History from "pages/Main/history"
import HistoryEdit from "pages/Main/history/historyEdit"
import HistoryAdd from "pages/Main/history/historyAdd"

import AboutEdit from "pages/Main/about/aboutEdit"
import Slider from "pages/Main/slider/slider"
import SliderEdit from "pages/Main/slider/sliderEdit"
import SliderAdd from "pages/Main/slider/sliderAdd"
import TopProductComponentEdit from "pages/Main/topproductcomponent/topproductcomponentEdit"
import WhyUsEdit from "pages/Main/whyus/whyUsEdit"
import BlogAdd from "pages/Main/blog/blogAdd"
import BlogEdit from "pages/Main/blog/blogEdit"
import Blog from "pages/Main/blog/blog"
import News from "pages/Main/news/news"

// ! Infos
import Infos from "pages/Main/invest/info/infos"
import InfoAdd from "pages/Main/invest/info/infoAdd"
import InfoEdit from "pages/Main/invest/info/infoEdit"

// ! Comps
import Comps from "pages/Main/invest/comp/comps"
import CompAdd from "pages/Main/invest/comp/compAdd"
import CompEdit from "pages/Main/invest/comp/compEdit"
import QR from "pages/Main/qr/qr"
import QREdit from "pages/Main/qr/qrEdit"
import QRAdd from "pages/Main/qr/qrAdd"

// ! Users
import Users from "pages/Main/users"
import UsersEdit from "pages/Main/users/usersEdit"

// ! SSS
import SSS from "pages/Main/sss"

// ! Footer
import Footer from "pages/Main/footer/footer"
import FileAdd from "pages/Main/file/fileAdd"
import FileShow from "pages/Main/file/show"
import Files from "pages/Main/file/file"

// ! Logs
import Logs from "pages/Main/logs"
import ErrorLogs from "pages/Main/error-logs"
import QRCard from "pages/Main/qrcard/qr"
import QRCardEdit from "pages/Main/qrcard/qrEdit"
import QRCardAdd from "pages/Main/qrcard/qrAdd"

const authProtectedRoutes = [
  // Safkar
  {
    path: "/main-page",
    component: <MainPage />,
  },
  //category
  {
    path: "/category",
    component: <Categories />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  {
    path: "/category/edit/:id",
    component: <CategoryEdit />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  {
    path: "/category/add",
    component: <CategoryAdd />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  //subcategory
  {
    path: "/subcategory/:id",
    component: <SubCategories />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  {
    path: "/subcategory/add/:id",
    component: <SubCategoryAdd />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  {
    path: "/subcategory/edit/:id",
    component: <SubCategoryEdit />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  //products
  {
    path: "/products",
    component: <Products />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  {
    path: "/product/add",
    component: <ProductAdd />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  {
    path: "/product/edit/:id",
    component: <ProductEdit />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  //sss
  {
    path: "/faq",
    component: <Faqs />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  {
    path: "/faq/edit/:id",
    component: <FaqEdit />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  {
    path: "/faq/add",
    component: <FaqAdd />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  // QR
  {
    path: "/qr",
    component: <QR />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  {
    path: "/qr/edit/:id",
    component: <QREdit />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  {
    path: "/qr/add",
    component: <QRAdd />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },

    // QR Card
    {
      path: "/qrcard",
      component: <QRCard />,
      require: [
        {
          role: "Admin",
        },
        {
          role: "Super_Admin",
        },
      ],
    },
    {
      path: "/qrcard/edit/:id",
      component: <QRCardEdit />,
      require: [
        {
          role: "Admin",
        },
        {
          role: "Super_Admin",
        },
      ],
    },
    {
      path: "/qrcard/add",
      component: <QRCardAdd />,
      require: [
        {
          role: "Admin",
        },
        {
          role: "Super_Admin",
        },
      ],
    },

  {
    path: "/file",
    component: <Files />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  {
    path: "/file/add",
    component: <FileAdd />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },

  //simple pages

  {
    path: "/kvkk",
    component: <KVKKPage />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  {
    path: "/news",
    component: <News />,
  },
  {
    path: "/topproductcomponent",
    component: <TopProductComponentEdit />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  {
    path: "/text",
    component: <TextPage />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  {
    path: "/arge",
    component: <ArgePage />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  {
    path: "/page-settings",
    component: <Settings />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  {
    path: "/smtp",
    component: <Smtp />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  //about
  {
    path: "/about",
    component: <AboutEdit />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  //whyus
  {
    path: "/whyus",
    component: <WhyUsEdit />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  //social
  {
    path: "/social",
    component: <Social />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  {
    path: "/social/add",
    component: <SocialAdd />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  {
    path: "/social/edit/:id",
    component: <SocialEdit />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  //team
  {
    path: "/team",
    component: <Team />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  {
    path: "/team/edit/:id",
    component: <TeamEdit />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  {
    path: "/team/add",
    component: <TeamAdd />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  {
    path: "/teamlang",
    component: <TeamLang />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  // profile
  {
    path: "/profile",
    component: <UserProfile />,
  },

  // user
  {
    path: "/users",
    component: <Users />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  {
    path: "/users/edit/:id",
    component: <UsersEdit />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },

  {
    path: "/certificate",
    component: <Certificates />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  {
    path: "/certificate/edit/:id",
    component: <CertificateEdit />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  {
    path: "/certificate/add",
    component: <CertificateAdd />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },

  {
    path: "/slider",
    component: <Slider />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  {
    path: "/slider/edit/:id",
    component: <SliderEdit />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  {
    path: "/slider/add",
    component: <SliderAdd />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },

  // SSS page
  {
    path: "/sss",
    component: <SSS />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },

  //blog

  {
    path: "/about",
    component: <AboutEdit />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },

  {
    path: "/topproductcomponent",
    component: <TopProductComponentEdit />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },

  {
    path: "/whyus",
    component: <WhyUsEdit />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },

  { path: "/blog", component: <Blog /> },
  { path: "/blog/edit/:id", component: <BlogEdit /> },
  { path: "/blog/add", component: <BlogAdd /> },
  //popups
  {
    path: "/popups",
    component: <Popups />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  {
    path: "/popups/edit/:id",
    component: <PopupsEdit />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  {
    path: "/popups/add",
    component: <PopupsAdd />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },

  //history/
  {
    path: "/history",
    component: <History />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  {
    path: "/history/edit/:id",
    component: <HistoryEdit />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  {
    path: "/history/add",
    component: <HistoryAdd />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  //service-sales
  {
    path: "/servicesales",
    component: <ServiceSales />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  {
    path: "/servicesales/edit/:id",
    component: <ServiceSalesEdit />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  { path: "/servicesales/add", component: <ServiceSalesAdd /> },
  { path: "/country", component: <Country /> },
  //service-sales-page
  {
    path: "/service-sales/page",
    component: <ServiceSalesPage />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  //contact-form-page
  { path: "/contact-form", component: <ContactForm /> },
  //contact
  {
    path: "/contact",
    component: <Contact />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  {
    path: "/contact/edit/:id",
    component: <ContactEdit />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  {
    path: "/contact/add",
    component: <ContactAdd />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  //infos
  {
    path: "/infos",
    component: <Infos />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  { path: "/info/add", component: <InfoAdd /> },
  {
    path: "/info/edit/:id",
    component: <InfoEdit />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },

  //comps
  {
    path: "/comps",
    component: <Comps />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  {
    path: "/comp/add",
    component: <CompAdd />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },
  {
    path: "/comp/edit/:id",
    component: <CompEdit />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },

  // logs
  {
    path: "/logs",
    component: <Logs />,
    require: [
      {
        role: "Super_Admin",
      },
    ],
  },

  {
    path: "/error-logs",
    component: <ErrorLogs />,
    require: [
      {
        role: "Super_Admin",
      },
    ],
  },

  //footer
  {
    path: "/footer",
    component: <Footer />,
    require: [
      {
        role: "Admin",
      },
      {
        role: "Super_Admin",
      },
    ],
  },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/main-page" />,
  },
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },

  { path: "/pages-404", component: <Pages404 /> },

  // Authentication Inner
  // { path: "/pages-login", component: <Login1 /> },
  // { path: "/pages-login-2", component: <Login2 /> },
  // { path: "/pages-register", component: <Register1 /> },
  // { path: "/pages-register-2", component: <Register2 /> },
  // { path: "/page-recoverpw", component: <Recoverpw /> },
  // { path: "/page-recoverpw-2", component: <Recoverpw2 /> },
  // { path: "/pages-forgot-pwd", component: <ForgetPwd1 /> },
  // { path: "/auth-recoverpw-2", component: <ForgetPwd2 /> },
  // { path: "/auth-lock-screen", component: <LockScreen /> },
  // { path: "/auth-lock-screen-2", component: <LockScreen2 /> },
  // { path: "/page-confirm-mail", component: <ConfirmMail /> },
  // { path: "/page-confirm-mail-2", component: <ConfirmMail2 /> },
  // { path: "/auth-email-verification", component: <EmailVerification /> },
  // { path: "/auth-email-verification-2", component: <EmailVerification2 /> },
  // { path: "/auth-two-step-verification", component: <TwostepVerification /> },
  // {
  //   path: "/auth-two-step-verification-2",
  //   component: <TwostepVerification2 />,
  // },
]

export { authProtectedRoutes, publicRoutes }
