import React from "react"
import {
  Container,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import { useParams, useNavigate } from "react-router-dom"

import classnames from "classnames"

import axios, { API_URL } from "lib/axios"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// Import Components

import { CKEditor } from "@ckeditor/ckeditor5-react"
import { Editor } from "ckeditor5-custom-build/build/ckeditor"

import { toast } from "react-toastify"

const ArgePage = () => {
  const Navigate = useNavigate()
  const [active, setActive] = React.useState()

  const [form, setForm] = React.useState()

  const params = useParams()

  //meta title
  document.title = "Safkar Panel - Arge Düzenle"

  const [customActiveTab, setCustomActiveTab] = React.useState("0")

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab)
    }
  }

  const [lang, setLang] = React.useState()

  React.useEffect(() => {
    axios
      .get("/language")
      .then(responseLang => {
        if (responseLang.data.languages) {
          setLang(responseLang.data.languages)

          axios
            .get(`/arge`)
            .then(responseKVKK => {
              if (responseKVKK.data) {
                const newForm = responseLang.data.languages.map(language => {
                  var kvkkLangRow = responseKVKK.data.find(
                    item => item.languageId == language.id
                  )

                  if (kvkkLangRow) {
                    return {
                      ["title_" + language.code]: kvkkLangRow.title,
                      ["subtitle_" + language.code]: kvkkLangRow.subtitle,
                      ["content_" + language.code]: kvkkLangRow.content,
                      id: kvkkLangRow.id,
                    }
                  } else {
                    return {
                      ["title_" + language.code]: "",
                      ["subtitle_" + language.code]: "",
                      ["content_" + language.code]: "",
                      id: "",
                    }
                  }
                })
                setForm(newForm)
              }
            })
            .catch(error => {
              console.log(error)
            })
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const isKVKKForm = obj => {
    return true
  }

  const setFormValueByIndex = (index, key, value) => {
    const newForm = [...form]
    if (isKVKKForm(newForm[index])) {
      newForm[index][key] = value.toString()
    }
    setForm(newForm)
  }

  const getFormValueByIndex = (index, key) => {
    const newForm = [...form]
    if (isKVKKForm(newForm[index])) {
      if (newForm[index]) {
        return newForm[index][key]
      } else {
        return ""
      }
    }
    return ""
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Arge" breadcrumbItem="Arge Düzenle" />

          {/* Render Form */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form
                    onSubmit={e => {
                      e.preventDefault()
                      var arr = {}
                      form.forEach(item => {
                        Object.keys(item).forEach(key => {
                          arr[key] = item[key]
                        })
                      })
                      arr["active"] = active
                      axios
                        .put("/arge", arr)
                        .then(response => {
                          if (response.data.success) {
                            toast.success("Güncelleme Başarılı")
                          }
                        })
                        .catch(error => {
                          console.log(error)
                        })
                    }}
                  >
                    <h4 className="card-title">Arge Düzenle</h4>
                    <p className="card-title-desc">Arge Düzenle</p>
                    {/* back button */}
                    <button
                      type="button"
                      className="btn btn-danger w-md mx-3 mb-2"
                      style={{
                        position: "absolute",
                        right: "0",
                        top: "20px",
                      }}
                      onClick={() => {
                        Navigate("/main-page")
                      }}
                    >
                      Geri
                    </button>
                    <div className="col-lg-12">
                      <Nav tabs className="nav-tabs-custom nav-justified">
                        {lang &&
                          lang.map((item, index) => (
                            <NavItem key={index}>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: customActiveTab === index.toString(),
                                })}
                                onClick={() => {
                                  toggleCustom(index.toString())
                                }}
                              >
                                <span className="d-block d-sm-none">
                                  {item.displayname}
                                </span>
                                <span className="d-none d-sm-block">
                                  {item.displayname}
                                </span>
                              </NavLink>
                            </NavItem>
                          ))}
                      </Nav>

                      <TabContent
                        activeTab={customActiveTab}
                        className="p-3 text-muted"
                      >
                        {form &&
                          lang &&
                          lang.map((item, index) => (
                            <TabPane tabId={index.toString()} key={index}>
                              <Row>
                                <Col sm="6">
                                  <div className="mt-4">
                                    <label
                                      htmlFor="kvkkTitle"
                                      className="form-label"
                                    >
                                      Arge Başlık
                                    </label>

                                    <input
                                      id="kvkkTitle"
                                      name="kvkkTitle"
                                      type="text"
                                      className="form-control"
                                      placeholder="Arge Başlık"
                                      defaultValue={getFormValueByIndex(
                                        index,
                                        "title_" + item.code
                                      )}
                                      onChange={e =>
                                        setFormValueByIndex(
                                          index,
                                          "title_" + item.code,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col sm="6">
                                  <div className="mt-4">
                                    <label
                                      htmlFor="kvkkSubtitle"
                                      className="form-label"
                                    >
                                      Arge Alt Başlık
                                    </label>

                                    <input
                                      id="kvkkSubtitle"
                                      name="kvkkSubtitle"
                                      type="text"
                                      className="form-control"
                                      placeholder="Arge Alt Başlık"
                                      defaultValue={getFormValueByIndex(
                                        index,
                                        "subtitle_" + item.code
                                      )}
                                      onChange={e =>
                                        setFormValueByIndex(
                                          index,
                                          "subtitle_" + item.code,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col sm="12">
                                  <div className="mt-4">
                                    <label
                                      htmlFor="kvkkContent"
                                      className="form-label"
                                    >
                                      Arge İçerik
                                    </label>

                                    <textarea
                                      id="kvkkContent"
                                      name="kvkkContent"
                                      className="form-control"
                                      placeholder="Arge İçerik"
                                      rows="5"
                                      defaultValue={getFormValueByIndex(
                                        index,
                                        "content_" + item.code
                                      )}
                                      onChange={e =>
                                        setFormValueByIndex(
                                          index,
                                          "content_" + item.code,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </TabPane>
                          ))}
                      </TabContent>
                    </div>
                    {/*submit button right*/}
                    <div className="col-lg-12">
                      <div className="mt-4 d-flex justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-primary w-md  mb-2"
                        >
                          Güncelle
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger w-md mx-3 mb-2"
                          onClick={() => {
                            Navigate("/main-page")
                          }}
                        >
                          Geri
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ArgePage
