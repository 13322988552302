import React from "react"
import {
  Container,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import { useParams, useNavigate } from "react-router-dom"

import classnames from "classnames"

import axios from "lib/axios"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// Import Components

import { toast } from "react-toastify"

const MainPage = () => {
  const Navigate = useNavigate()
  const params = useParams()
  // all variables
  const [file, setFile] = React.useState()
  const [active, setActive] = React.useState(true)

  const [form, setForm] = React.useState()

  const [content, setContent] = React.useState()

  //meta title
  document.title = "Safkar Panel - Kategori Ekle"

  const [customActiveTab, setcustomActiveTab] = React.useState("0")

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  const [lang, setLang] = React.useState()

  React.useEffect(() => {
    axios
      .get("/language")
      .then(responseLang => {
        if (responseLang.data.languages) {
          setLang(responseLang.data.languages)

          const newForm = responseLang.data.languages.map(language => {
            return {
              ["title_" + language.code]: "",
              ["content_" + language.code]: "",
              ["table_" + language.code]: "",
              active: "1",
            }
          })

          setForm(newForm)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const [data, setData] = React.useState()

  const issubcategoryForm = obj => {
    // Gerekli özelliklerin varlığını kontrol ederek
    // obj'nin subcategoryForm tipinde olduğunu doğrulayabilirsiniz
    // Örnek olarak: return 'property1' in obj && 'property2' in obj;
    return true
  }

  const setFormValueByIndex = (index, key, value) => {
    const newForm = [...form]
    if (issubcategoryForm(newForm[index])) {
      newForm[index][key] = value.toString()
    }
    setForm(newForm)
  }

  const getFormValueByIndex = (index, key) => {
    const newForm = [...form]
    if (issubcategoryForm(newForm[index])) {
      //if newform has key
      if (newForm[index]) {
        return newForm[index][key]
      } else {
        return ""
      }
    }
    return ""
  }

  const parseTableContent = data => {
    const tempDiv = document.createElement("div")
    tempDiv.innerHTML = data

    const tableElements = tempDiv.querySelectorAll("table")
    const tables = []

    tableElements.forEach(tableElement => {
      const rows = []

      const rowElements = tableElement.querySelectorAll("tr")
      rowElements.forEach(rowElement => {
        const cells = []

        const cellElements = rowElement.querySelectorAll("td")
        cellElements.forEach(cellElement => {
          cells.push(cellElement.innerHTML)
        })

        rows.push(cells)
      })

      tables.push(rows)
    })

    return tables
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Kategori / Alt Kategori"
            breadcrumbItem="Alt Kategori Ekle"
          />

          {/* Render Form */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form
                    onSubmit={e => {
                      e.preventDefault()
                      var arr = {}
                      form.forEach(item => {
                        Object.keys(item).forEach(key => {
                          arr[key] = item[key]
                        })
                      })
                      arr["active"] = active
                      arr["categoryId"] = params.id
                      axios
                        .post("/subcategory", arr)
                        .then(response => {
                          if (response.status == 200) {
                            toast.success("Kategori Eklendi")
                          }
                          Navigate("/subcategory/" + params.id)
                        })
                        .catch(error => {
                          console.log(error)
                          toast("Kategori Eklenemedi")
                        })
                    }}
                  >
                    <h4 className="card-title">Alt Kategori</h4>
                    <p className="card-title-desc">Alt Kategori Ekle</p>
                    {/* back button */}
                    <button
                      type="button"
                      className="btn btn-danger w-md mx-3 mb-2"
                      style={{
                        position: "absolute",
                        right: "0",
                        top: "20px",
                      }}
                      onClick={() => {
                        //rout Edit subcategory
                        Navigate("/subcategory/" + params.id)
                      }}
                    >
                      Geri
                    </button>
                    <div className="row">
                      <div className="col-lg-8">
                        {/* two colum*/}
                        <div className="row">
                          <div className="col-lg-4">
                            {/*subcategory name*/}
                            <div className="mb-3">
                              <label
                                htmlFor="subcategoryname"
                                className="form-label"
                              >
                                Aktiflik
                              </label>
                              <div className="form-check form-switch form-switch-lg mb-3">
                                {active != null && (
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="customSwitchsizelg"
                                    defaultChecked={active ? true : false}
                                    onChange={e => {
                                      setActive(e.target.checked)
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4"></div>
                      <div className="col-lg-12">
                        <Nav tabs className="nav-tabs-custom nav-justified">
                          {lang &&
                            lang.map((item, index) => (
                              <NavItem key={index}>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active:
                                      customActiveTab === index.toString(),
                                  })}
                                  onClick={() => {
                                    toggleCustom(index.toString())
                                  }}
                                >
                                  <span className="d-block d-sm-none">
                                    {item.displayname}
                                  </span>
                                  <span className="d-none d-sm-block">
                                    {item.displayname}
                                  </span>
                                </NavLink>
                              </NavItem>
                            ))}
                        </Nav>

                        <TabContent
                          activeTab={customActiveTab}
                          className="p-3 text-muted"
                        >
                          {form &&
                            lang &&
                            lang.map((item, index) => (
                              <TabPane tabId={index.toString()} key={index}>
                                <Row>
                                  <Col sm="12" lg="6">
                                    <div className="mt-4">
                                      <label
                                        htmlFor="subcategoryname"
                                        className="form-label"
                                      >
                                        Alt Kategori Adi
                                      </label>

                                      <input
                                        id="subcategoryname"
                                        name="subcategoryname"
                                        type="text"
                                        className="form-control"
                                        placeholder="Alt Kategori Adi"
                                        onChange={e =>
                                          setFormValueByIndex(
                                            index,
                                            "title_" + item.code,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </Col>
                                  <Col sm="12" lg="6">
                                    <div className="mt-4">
                                      <label
                                        htmlFor="subcategoryname"
                                        className="form-label"
                                      >
                                        Alt Kategori Icerik
                                      </label>

                                      <input
                                        id="subcategoryname"
                                        name="subcategoryname"
                                        type="text"
                                        className="form-control"
                                        placeholder="Alt Kategori İçeriği"
                                        onChange={e =>
                                          setFormValueByIndex(
                                            index,
                                            "content_" + item.code,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </TabPane>
                            ))}
                        </TabContent>
                      </div>
                      {/*submit button right*/}
                      <div className="col-lg-12">
                        <div className="mt-4 d-flex justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-primary w-md  mb-2"
                          >
                            Ekle
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger w-md mx-3 mb-2"
                            onClick={() => {
                              //rout Edit subcategory
                              Navigate("/subcategory/" + params.id)
                            }}
                          >
                            Geri
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MainPage
