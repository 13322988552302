import React from "react"
import {
  Container,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import { useParams, useNavigate } from "react-router-dom"

import classnames from "classnames"

import axios, { API_URL } from "lib/axios"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// Import Components

import { CKEditor } from "@ckeditor/ckeditor5-react"
import { Editor } from "ckeditor5-custom-build/build/ckeditor"

import { toast } from "react-toastify"

const Index = () => {
  const Navigate = useNavigate()

  const [form, setForm] = React.useState()
  const [footerLogo, setFooterLogo] = React.useState()
  const [logo, setLogo] = React.useState()
  const [favicon, setFavicon] = React.useState()
  const [invest, setInvest] = React.useState()

  //meta title
  document.title = "Safkar Panel - Websitesi Ayarları"

  const [customActiveTab, setCustomActiveTab] = React.useState("0")

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab)
    }
  }

  const [lang, setLang] = React.useState()

  React.useEffect(() => {
    axios
      .get("/language")
      .then(responseLang => {
        if (responseLang.data.languages) {
          setLang(responseLang.data.languages)
          axios
            .get(`/setting`)
            .then(res => {
              if (res.data) {
                setFooterLogo(res.data.settings.footerlogo)
                setLogo(res.data.settings.logo)
                setFavicon(res.data.settings.favicon)
                setInvest(res.data.settings.invest)

                const newForm = responseLang.data.languages.map(language => {
                  var kvkkLangRow = res.data.settingContext.find(
                    item => item.languageId == language.id
                  )
                  if (kvkkLangRow) {
                    return {
                      ["title_" + language.code]: kvkkLangRow.title,
                      ["description_" + language.code]: kvkkLangRow.description,
                      ["keywords_" + language.code]: kvkkLangRow.keywords,
                      ["author_" + language.code]: kvkkLangRow.author,
                      id: kvkkLangRow.id,
                    }
                  } else {
                    return {
                      ["title_" + language.code]: "",
                      ["description_" + language.code]: "",
                      ["keywords_" + language.code]: "",
                      ["author_" + language.code]: "",
                      id: "",
                    }
                  }
                })
                setForm(newForm)
              }
            })
            .catch(error => {
              console.log(error)
            })
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const isKVKKForm = obj => {
    return true
  }

  const setFormValueByIndex = (index, key, value) => {
    const newForm = [...form]
    if (isKVKKForm(newForm[index])) {
      newForm[index][key] = value.toString()
    }
    setForm(newForm)
  }

  const getFormValueByIndex = (index, key) => {
    const newForm = [...form]
    if (isKVKKForm(newForm[index])) {
      if (newForm[index]) {
        return newForm[index][key]
      } else {
        return ""
      }
    }
    return ""
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Websitesi Ayarları"
            breadcrumbItem="Websitesi Ayarları Düzenle"
          />

          {/* Render Form */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form
                    onSubmit={e => {
                      e.preventDefault()
                      var arr = {}
                      form.forEach(item => {
                        Object.keys(item).forEach(key => {
                          arr[key] = item[key]
                        })
                      })

                      if (footerLogo && typeof footerLogo == "object") {
                        if (
                          footerLogo.type != "image/png" &&
                          footerLogo.type != "image/jpeg" &&
                          footerLogo.type != "image/jpg"
                        ) {
                          toast.error("Resim PNG, JPEG veya JPG olmalidir")
                          return
                        }
                      }

                      if (logo && typeof logo == "object") {
                        if (
                          logo.type != "image/png" &&
                          logo.type != "image/jpeg" &&
                          logo.type != "image/jpg"
                        ) {
                          toast.error("Resim PNG, JPEG veya JPG olmalidir")
                          return
                        }
                      }

                      if (favicon && typeof favicon == "object") {
                        if (
                          favicon.type != "image/png" &&
                          favicon.type != "image/jpeg" &&
                          favicon.type != "image/jpg"
                        ) {
                          toast.error("Resim PNG, JPEG veya JPG olmalidir")
                          return
                        }
                      }

                      if (invest && typeof invest == "object") {
                        if (
                          invest.type != "image/png" &&
                          invest.type != "image/jpeg" &&
                          invest.type != "image/jpg"
                        ) {
                          toast.error("Resim PNG, JPEG veya JPG olmalidir")
                          return
                        }
                      }

                      axios
                        .put("/setting", arr)
                        .then(response => {
                          if (response.status == 200) {
                            if (typeof footerLogo == "object") {
                              const formData = new FormData()
                              formData.append("images", footerLogo)
                              formData.append(
                                "settingId",
                                response.data.setting.id
                              )
                              axios
                                .post("/setting/footerlogo", formData)
                                .then(response => {})
                            }

                            if (typeof logo == "object") {
                              const formData = new FormData()
                              formData.append("images", logo)
                              formData.append(
                                "settingId",
                                response.data.setting.id
                              )
                              axios
                                .post("/setting/logo", formData)
                                .then(res => {})
                            }

                            if (typeof favicon == "object") {
                              const formData = new FormData()
                              formData.append("images", favicon)
                              formData.append(
                                "settingId",
                                response.data.setting.id
                              )
                              axios
                                .post("/setting/favicon", formData)
                                .then(response => {})
                            }

                            if (typeof invest == "object") {
                              const formData = new FormData()
                              formData.append("images", invest)
                              formData.append(
                                "settingId",
                                response.data.setting.id
                              )
                              axios
                                .post("/setting/invest", formData)
                                .then(response => {})
                            }

                            toast.success("Güncelleme Başarılı")
                          }
                        })
                        .catch(error => {
                          console.log(error)
                        })
                    }}
                  >
                    <h4 className="card-title">Sayfa Ayarlarını Düzenle</h4>
                    <p className="card-title-desc">Sayfa Ayarlarını Düzenle</p>
                    {/* back button */}
                    <button
                      type="button"
                      className="btn btn-danger w-md mx-3 mb-2"
                      style={{
                        position: "absolute",
                        right: "0",
                        top: "20px",
                      }}
                      onClick={() => {
                        Navigate("/main-page")
                      }}
                    >
                      Geri
                    </button>
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-lg-6">
                          {/* two colum*/}
                          <div className="row">
                            <div className="col-12">
                              <div className="mb-3">
                                <label
                                  htmlFor="productname"
                                  className="form-label"
                                >
                                  Logo Ekle
                                </label>
                                <input
                                  id="productname"
                                  name="productname"
                                  type="file"
                                  accept=".png, .jpeg, .jpg"
                                  className="form-control"
                                  onChange={e => {
                                    setLogo(e.target.files[0])
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="mb-3">
                                <img
                                  src={
                                    logo
                                      ? typeof logo == "string"
                                        ? `${API_URL}/api/image?image=${logo.replaceAll(
                                            " ",
                                            "%20"
                                          )}&path=logo`
                                        : URL.createObjectURL(logo)
                                      : "https://placehold.co/400"
                                  }
                                  alt=""
                                  width={"100%"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          {/* two colum*/}
                          <div className="row">
                            <div className="col-12">
                              <div className="mb-3">
                                <label
                                  htmlFor="productname"
                                  className="form-label"
                                >
                                  Websitesi Küçük Resmi
                                </label>
                                <input
                                  id="productname"
                                  name="productname"
                                  type="file"
                                  accept=".png, .jpeg, .jpg"
                                  className="form-control"
                                  onChange={e => {
                                    setFavicon(e.target.files[0])
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="mb-3">
                                <img
                                  src={
                                    favicon
                                      ? typeof favicon == "string"
                                        ? `${API_URL}/api/image?image=${favicon.replaceAll(
                                            " ",
                                            "%20"
                                          )}&path=favicon`
                                        : URL.createObjectURL(favicon)
                                      : "https://placehold.co/400"
                                  }
                                  alt=""
                                  width={"100%"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          {/* two colum*/}
                          <div className="row">
                            <div className="col-12">
                              <div className="mb-3">
                                <label
                                  htmlFor="productname"
                                  className="form-label"
                                >
                                  Footer Logo
                                </label>
                                <input
                                  id="productname"
                                  name="productname"
                                  type="file"
                                  accept=".png, .jpeg, .jpg"
                                  className="form-control"
                                  onChange={e => {
                                    setFooterLogo(e.target.files[0])
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="mb-3">
                                <img
                                  src={
                                    footerLogo
                                      ? typeof footerLogo == "string"
                                        ? `${API_URL}/api/image?image=${footerLogo.replaceAll(
                                            " ",
                                            "%20"
                                          )}&path=footerlogo`
                                        : URL.createObjectURL(footerLogo)
                                      : "https://placehold.co/400"
                                  }
                                  alt=""
                                  width={"100%"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          {/* two colum*/}
                          <div className="row">
                            <div className="col-12">
                              <div className="mb-3">
                                <label
                                  htmlFor="productname"
                                  className="form-label"
                                >
                                  Yatırımcılar Breadcrumb
                                </label>
                                <input
                                  id="productname"
                                  name="productname"
                                  type="file"
                                  accept=".png, .jpeg, .jpg"
                                  className="form-control"
                                  onChange={e => {
                                    setInvest(e.target.files[0])
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="mb-3">
                                <img
                                  src={
                                    invest
                                      ? typeof invest == "string"
                                        ? `${API_URL}/api/image?image=${invest.replaceAll(
                                            " ",
                                            "%20"
                                          )}&path=investbread`
                                        : URL.createObjectURL(invest)
                                      : "https://placehold.co/400"
                                  }
                                  alt=""
                                  width={"100%"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Nav tabs className="nav-tabs-custom nav-justified">
                        {lang &&
                          lang.map((item, index) => (
                            <NavItem key={index}>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: customActiveTab === index.toString(),
                                })}
                                onClick={() => {
                                  toggleCustom(index.toString())
                                }}
                              >
                                <span className="d-block d-sm-none">
                                  {item.displayname}
                                </span>
                                <span className="d-none d-sm-block">
                                  {item.displayname}
                                </span>
                              </NavLink>
                            </NavItem>
                          ))}
                      </Nav>

                      <TabContent
                        activeTab={customActiveTab}
                        className="p-3 text-muted"
                      >
                        {form &&
                          lang &&
                          lang.map((item, index) => (
                            <TabPane tabId={index.toString()} key={index}>
                              <Row>
                                <Col sm="6">
                                  <div className="mt-4">
                                    <label
                                      htmlFor="Title"
                                      className="form-label"
                                    >
                                      Başlık
                                    </label>
                                    <input
                                      id="Title"
                                      name="Title"
                                      type="text"
                                      className="form-control"
                                      placeholder=" Başlık"
                                      defaultValue={getFormValueByIndex(
                                        index,
                                        "title_" + item.code
                                      )}
                                      onChange={e =>
                                        setFormValueByIndex(
                                          index,
                                          "title_" + item.code,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col sm="6">
                                  <div className="mt-4">
                                    <label
                                      htmlFor="keyword"
                                      className="form-label"
                                    >
                                      Anahtar kelime
                                    </label>

                                    <input
                                      id="keyword"
                                      name="keyword"
                                      type="text"
                                      className="form-control"
                                      placeholder=" Alt Başlık"
                                      defaultValue={getFormValueByIndex(
                                        index,
                                        "keywords_" + item.code
                                      )}
                                      onChange={e =>
                                        setFormValueByIndex(
                                          index,
                                          "keywords_" + item.code,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col sm="12">
                                  <div className="mt-4">
                                    <label
                                      htmlFor="Content"
                                      className="form-label"
                                    >
                                      Açıklama
                                    </label>

                                    <textarea
                                      id="Content"
                                      name="Content"
                                      className="form-control"
                                      placeholder="Açıklama"
                                      rows="5"
                                      defaultValue={getFormValueByIndex(
                                        index,
                                        "description_" + item.code
                                      )}
                                      onChange={e =>
                                        setFormValueByIndex(
                                          index,
                                          "description_" + item.code,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col sm="12">
                                  <div className="mt-4">
                                    <label
                                      htmlFor="Content"
                                      className="form-label"
                                    >
                                      Yazar
                                    </label>

                                    <textarea
                                      id="Content"
                                      name="Content"
                                      className="form-control"
                                      placeholder="Yazar"
                                      rows="5"
                                      defaultValue={getFormValueByIndex(
                                        index,
                                        "author_" + item.code
                                      )}
                                      onChange={e =>
                                        setFormValueByIndex(
                                          index,
                                          "author_" + item.code,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </TabPane>
                          ))}
                      </TabContent>
                    </div>
                    {/*submit button right*/}
                    <div className="col-lg-12">
                      <div className="mt-4 d-flex justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-primary w-md  mb-2"
                        >
                          Güncelle
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger w-md mx-3 mb-2"
                          onClick={() => {
                            Navigate("/main-page")
                          }}
                        >
                          Geri
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Index
